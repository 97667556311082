<template>
    <div v-if="!loadingLists">
        <div>
            <div class="row">
                <label class="col-4 text-left" for="notes">
                    Notes
                </label>
                <input id="notes" class="form-control col-8 text-left" type="text" v-model="order.Notes">
            </div>

            <div class="row">
                <label class="col-4 text-left" for="remarks">
                    Remarks
                </label>
                <input id="remarks" class="form-control col-8 text-left" type="text" v-model="order.Description">
            </div>


            <div class="row">
                <label class="col-4 text-left" for="reservation-type">
                    Reservation Type
                </label>
                <select id="reservation-type" class="form-control col-8 text-left" v-model="order.OrderTypeId">
                    <option v-for="orderType in OrderTypeList" :key="orderType.Id" :value="orderType.Id">
                        {{ orderType.Name }}
                    </option>
                </select>
            </div>

            <div class="row">
                <label class="col-4 text-left" for="status">
                    Status
                </label>
                <select id="status" class="form-control col-8 text-left" v-model="order.OrderStatusId">
                    <option v-for="orderStatus in OrderStatusList" :key="orderStatus.Id" :value="orderStatus.Id">
                        {{ orderStatus.Name }}
                    </option>
                </select>
            </div>

            <div class="row">
                <label class="col-4 text-left" for="payment-type">
                    Payment Type
                </label>
                <select id="payment-type" class="form-control col-8 text-left" v-model="order.PaymentTypeId">
                    <option v-for="paymentType in PaymentTypeList" :key="paymentType.Id" :value="paymentType.Id">
                        {{ paymentType.Name }}
                    </option>
                </select>
            </div>

            <div class="row" v-if="order.OrderStatusId === 2">
                <label class="col-4 text-left text-success" for="offer-expires">
                    Offer expires on *
                </label>

                <input id="offer-expires" class="form-control col-8 text-left" type="date"
                       v-model="order.OptionExpireDate"/>
            </div>

            <div class="row">
                <label class="col-4 text-left" for="status">
                    Send invoice to customer?
                </label>
                <div class="col-8 text-left">
                    <input id="set-custom-price" type="checkbox" v-model="order.IsInvoiceRequested">
                </div>
            </div>

            <div v-if="order.IsInvoiceRequested">

                <div class="row">
                    <label class="col-4 text-left" for="invoice_customer-reference">
                        Customer Reference (kenmerk)
                    </label>

                    <input id="invoice_customer-reference" class="form-control col-8 text-left" type="text"
                           v-model="order.InvoiceRequest.CostLocation"/>
                </div>

                <div class="row">
                    <label class="col-4 text-left" for="invoice_contact">
                        Invoice Contact (T.A.V.)
                    </label>

                    <input id="invoice_contact" class="form-control col-8 text-left" type="text"
                           v-model="order.InvoiceRequest.InvoiceContact"/>
                </div>

                <div class="row">
                    <label class="col-4 text-left" for="invoice_company-name">
                        Company Name*
                    </label>

                    <input id="invoice_company-name" class="form-control col-8 text-left" type="text"
                           v-model="order.InvoiceRequest.CompanyName"/>
                </div>

                <div class="row">
                    <label class="col-4 text-left" for="invoice_company-name">
                        Company Email
                    </label>

                    <input id="invoice_company-email" class="form-control col-8 text-left" type="text"
                           v-model="order.InvoiceRequest.CompanyEmail"/>
                </div>

                <div class="row">
                    <label class="col-4 text-left" for="invoice_address">
                        Address*
                    </label>

                    <input id="invoice_address" class="form-control col-8 text-left" type="text"
                           v-model="order.InvoiceRequest.Address"/>
                </div>

                <div class="row">
                    <label class="col-4 text-left" for="invoice_postal-code">
                        PostalCode*
                    </label>

                    <input id="invoice_postal-code" class="form-control col-8 text-left" type="text"
                           v-model="order.InvoiceRequest.PostalCode"/>
                </div>


                <div class="row">
                    <label class="col-4 text-left" for="invoice_city">
                        City*
                    </label>

                    <input id="invoice_city" class="form-control col-8 text-left" type="text"
                           v-model="order.InvoiceRequest.City"/>
                </div>


                <div class="row">
                    <label class="col-4 text-left" for="invoice_country">
                        Country*
                    </label>

                    <select id="invoice_country" class="form-control col-8 text-left" v-model="order.InvoiceRequest.Country">
                        <option v-for="country in countries" :value="country.value" :key="country.value">
                            {{ country.label }}
                        </option>
                    </select>
                </div>

            </div>

        </div>
    </div>

    <div v-else>
        Loading ...
    </div>

</template>

<script>

import _ from "lodash";
import bookingApi from "@/services/booking-service";
import moment from "moment";
import countries from "i18n-iso-countries";

import i18n_iso_countries from "i18n-iso-countries/langs/en.json";
countries.registerLocale(i18n_iso_countries)

export default {
    name: "edit-order-component",
    props: ["order"],
    data: () => {
        return {
            loadingLists: true,
            OrderStatusList: [],
            PaymentTypeList: [],
            OrderTypeList: [],
        }
    },
    computed: {
        countries () {
            const list = countries.getNames('en', { select: 'official' })
            return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
        }
    },
    created() {
        this.order.OptionExpireDate = moment(this.order.OptionExpireDate).format("YYYY-MM-DD")

        Promise.all([
            bookingApi.GetAsync(`/api/v1/booking/payment-types/list`),
            bookingApi.GetAsync(`/api/v1/booking/order-status/list`),
            bookingApi.GetAsync(`/api/v1/booking/order-types/list`),
        ])
            .then(res => {
                this.PaymentTypeList = _.sortBy(res[0], "SeqNr");
                this.OrderStatusList = _.sortBy(res[1], "SeqNr");
                this.OrderTypeList = _.sortBy(res[2], "SeqNr");

                if (!this.order.PaymentTypeId || this.order.PaymentTypeId === 0) {
                    this.order.PaymentTypeId = this.PaymentTypeList[0].Id;
                    this.order.OrderTypeId = this.OrderTypeList[0].Id;
                    this.order.OrderStatusId = this.OrderStatusList[0].Id;
                }

                this.loadingLists = false;
            })

        if(!this.order.IsInvoiceRequested) {
            this.order.InvoiceRequest = {
                InvoiceContact: "",
                CostLocation: "",
                CompanyName: "",
                CompanyEmail: "",
                Address: "",
                PostalCode: "",
                City: "",
                Country: "NL",
            }
        }

    }
}
</script>

<style scoped lang="scss">

</style>
