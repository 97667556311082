<template>
    <div class="rooms">
        <h2>
            Room
        </h2>

        <div class="mt-4" v-if="!Loading">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import AutoTable from "@/components/Shared/Table";
    import Pagination from "@/components/Shared/Pagination";
    import TableView from "@/mixins/TableView";

    export default {
        name: "rooms",
        mixins: [TableView],
        components: {Pagination, AutoTable},
        data() {
            return {
                Url: `/api/v1/booking/locations/${this.$route.params.id}/rooms`,
                Pagination: null,
                Columns: [
                    {
                        Title: "Name",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "Name"
                    },
                    // {
                    //     Title: "MonthBookings",
                    //     Sortable: false,
                    //     Type: "TEXT",
                    //     Attribute: "MonthBookings"
                    // },
                    // {
                    //     Title: "WeekBookings",
                    //     Sortable: false,
                    //     Type: "TEXT",
                    //     Attribute: "WeekBookings"
                    // },
                    // {
                    //     Title: "DayBookings",
                    //     Sortable: false,
                    //     Type: "TEXT",
                    //     Attribute: "DayBookings"
                    // },
                    {
                        Title: "PlayedToday",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "BookingsToday"
                    },
                    {
                        Title: "Bookings From",
                        Sortable: false,
                        Type: "DATE",
                        Attribute: "BookingsFrom"
                    },
                    {
                        Title: "Bookings To",
                        Sortable: false,
                        Type: "DATE",
                        Attribute: "BookingsTo"
                    },
                    {
                        Title: "+Price",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "PriceAddition"
                    },
                    {
                        Title: "Published",
                        Sortable: false,
                        Type: "BADGE",
                        T: "Yes",
                        F: "No",
                        Attribute: "Published"
                    },
                    {
                        Title: "Enabled",
                        Sortable: false,
                        Type: "BADGE",
                        T: "Yes",
                        F: "No",
                        Attribute: "IsEnabled"
                    }
                ]
            }
        },
        created() {
            this.GetPage();
            this.emitter.on("reload-location-rooms", this.GetPage);
        },
        methods: {
            SelectItem(item) {
                this.emitter.emit("open-modal", {type: "EditRoomModal", data: item})
            }
        }
    }
</script>

<style scoped>

</style>
