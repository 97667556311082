<template>
    <div class="container">
        You have been logged out.

        <button class="btn btn-primary" @click="login">Login</button>
    </div>
</template>

<script>
export default {
    name: "logout",
    created() {
        if (this.$auth0.isAuthenticated) {
            this.$router.push("/")
        }
    },
    methods: {
        login() {
            this.$auth0.loginWithRedirect()
        }
    }
}
</script>

<style scoped lang="scss">

</style>
