<template>
    <div class="row">
        <div class="col-2">

            <button class="material-icons btn btn-primary" @click="PrevDay()" data-test-id="previous-day">
                chevron_left
            </button>
        </div>

        <div class="col-8 row">
            <div class="col-4">
                {{useFormatDay(TempDate)}}
            </div>

            <div class="col-8">
                <input class="form-control" type="date" @change="SetDate()" v-model="TempDate">
            </div>
        </div>

        <div class="col-2">
            <button class="material-icons btn btn-primary" @click="NextDay()" data-test-id="next-day">
                chevron_right
            </button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import Filters from "@/mixins/Filters";

    export default {
        name: "calendar",
        mixins: [Filters],
        props: ['initialDate'],
        data() {
            return {
                Date: moment(),
                TempDate: ""
            }
        },
        created() {
            if (this.initialDate) {
                this.Date = moment(this.initialDate)
            }
            this.TempDate = this.Date.format("YYYY-MM-DD");
        },
        methods: {
            PrevDay() {
                this.Date = this.Date.subtract(1, "days");
                this.TempDate = this.Date.format("YYYY-MM-DD");
                this.$emit("load", this.Date.format("YYYY-MM-DD"));
            },
            NextDay() {
                this.Date = this.Date.add(1, "days");
                this.TempDate = this.Date.format("YYYY-MM-DD");
                this.$emit("load", this.Date.format("YYYY-MM-DD"));
            },
            SetDate() {
                this.Date = moment(this.TempDate, "YYYY-MM-DD");
                this.$emit("load", this.Date.format("YYYY-MM-DD"));
            }
        }
    }
</script>

<style scoped>

</style>
