<template>
    <div v-if="!Loading">
        <h2>
            Locations
        </h2>
        <div class="mt-4">
            <auto-table @table-click="SelectItem" :pagination="Pagination" :columns="Columns" :loading="Loading"/>
            <pagination :pagination="Pagination" @get-page="Paginate"/>
        </div>
    </div>
</template>

<script>
    import Pagination from "../components/Shared/Pagination";
    import TableView from "../mixins/TableView";
    import AutoTable from "@/components/Shared/Table";

    export default {
        name: "location",
        mixins: [TableView],
        components: {AutoTable, Pagination},
        data() {
            return {
                Loading: true,
                Pagination: null,
                Columns: [
                    {
                        Title: "Name",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "DisplayName"
                    },
                    {
                        Title: "# Month bookings",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "MonthOrders"
                    },
                    {
                        Title: "# Week bookings",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "WeekOrders"
                    },
                    {
                        Title: "# Day bookings",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "DayOrders"
                    },
                    {
                        Title: "# Bookings Today",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "OrdersToday"
                    },
                    {
                        Title: "Published",
                        Sortable: true,
                        Type: "BADGE",
                        T: "Yes",
                        F: "No",
                        Attribute: "Published"
                    },
                ],
                Url: "/api/v1/booking/locations",
            }
        },
        created() {
            this.GetPage()
        },
        methods: {
            SelectItem(item) {
                this.$router.push(`/locations/${item.Id}`)
            }
        }
    }
</script>

<style scoped>

</style>
