<template>
    <div class="toast-container">
        <div aria-live="polite" aria-atomic="true" style="position: relative; min-height: 1px;">
            <div style="position: absolute; top: 0; right: 0;">
                <div v-for="toast in toasts" :key="toast.name" class="toast fade show" role="alert">
                    <div class="toast-header">
                        <strong class="mr-auto">
                            {{toast.title}}
                        </strong>
                        <small class="text-muted">{{toast.timestamp}}</small>
                        <button type="button" class="ml-2 mb-1 close" @click="close(toast)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="toast-body">
                        {{toast.body}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "notification-component",
        data() {
            return {
                toasts: []
            }
        },
        created() {
            this.emitter.on("notification", this.addToast)
        },
        methods: {
            addToast(notification) {
                this.toasts.push(notification);

                setTimeout(() => {
                    this.toasts.pop();
                }, 20000)
            },
            close(toast) {
                let delIdx = -1;
                this.toasts.forEach((t, idx) => {
                    if (t.name === toast.name) {
                        delIdx = idx
                    }
                });

                if (delIdx > -1) {
                    this.toasts.splice(delIdx, 1);
                }
            }
        }
    }
</script>

<style lang="scss">
    .toast-container {
        position: fixed;
        width: 100vw;
        height: 1px;
        z-index: 26;
    }

    .toast {
        min-width: 250px;
        z-index: 27;
    }
</style>
