<template>

    <div class="container-fluid p-4">

        <div class="row">
            <button class="col-1 material-icons btn btn-primary" @click="PrevWeek()">
                chevron_left
            </button>

            <div class="col-4">
                <select class="form-control" @change="ChangeLocation(SelectedLocation)" v-model="SelectedLocation">
                    <option v-for="l in Locations" :key="l.Id" :value="l.Id">
                        {{ l.DisplayName }}
                    </option>
                </select>
            </div>

            <div class="offset-2 col-2">
                <input type="number" class="form-control" v-model="DividedBy"/>
            </div>

            <button class="offset-2 col-1 material-icons btn btn-primary" @click="NextWeek()">
                chevron_right
            </button>
        </div>

        <div class="row mt-4">
            <table class="table table-bordered" v-if="!Loading">
                <thead>
                <tr>
                    <th class="text-info text-left">
                        Week
                    </th>
                    <th class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr" :title="period.Period">
                        {{ period.WeekNr }}
                    </th>
                </tr>

                </thead>
                <tbody class="table-small">

                <tr>
                    <td class="text-left">
                        Hoog
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + HighScore">
                        {{ DivideByRooms(HighScore, DividedBy) }}
                    </td>
                </tr>

                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td colspan="6">
                        &nbsp;
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        Totaal
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.Total">
                        {{ DivideByRooms(period.Total, DividedBy) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        Online
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.Online">
                        {{ DivideByRooms(period.Online, DividedBy) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        % Online
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr">
                        {{ usePercent(period.OnlinePercentage) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        % Online 12w
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr">
                        {{ usePercent(period.OnlinePercentage12w) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        Gemiddelde
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.AverageTotal">
                        {{ period.AverageTotal | DivideByRooms(DividedBy) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        Gemiddelde 12w
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.Average12w">
                        {{ DivideByRooms(period.Average12w, DividedBy) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        Gemiddelde 4w
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.Average4w">
                        {{ DivideByRooms(period.Average4w, DividedBy) }}
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        Totaal gespeeld
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.TotalPlayed">
                        {{ DivideByRooms(period.TotalPlayed, DividedBy) }}
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        Gemiddelde Omzet (per kamer)
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr">
                        {{ period.WeekProfit }}
                    </td>
                </tr>

                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td colspan="6">
                        &nbsp;
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        Geblokkeerd
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.Blocked">
                        {{ DivideByRooms(period.Blocked, DividedBy) }}
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        Gemiddelde 4w
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr">
                        {{ DivideByRooms(period.AverageBlocked4w, DividedBy) }}
                    </td>

                </tr>

                <tr>
                    <td class="text-left">
                        Gemiddelde 12w
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr">
                        {{ DivideByRooms(period.AverageBlocked12w, DividedBy) }}
                    </td>
                </tr>

                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td colspan="6">
                        &nbsp;
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        Zelfde dag
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.SameDayBooking">
                        {{ DivideByRooms(period.SameDayBooking, DividedBy) }}
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        Zelfde dag online
                    </td>
                    <td class="text-right" v-for="period in OrderedWeeks" :key="period.WeekNr"
                        :title="'Totaal: ' + period.SameDayBookingOnline">
                        {{ DivideByRooms(period.SameDayBookingOnline ,DividedBy) }}
                    </td>

                </tr>

                </tbody>
            </table>
            <table v-else class="table">
                <thead>
                <tr>
                    <th class="text-info text-left">
                        Week
                    </th>
                    <th class="text-center" colspan="6">
                        ...
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td colspan="6" class="text-center">
                        Loading ...
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import api from "../services/booking-service"
import moment from "moment"
import _ from "lodash"
import Filters from "../mixins/Filters";

export default {
    name: "finance",
    mixins: [Filters],
    data() {
        return {
            Loading: true,
            SelectedLocation: null,
            DividedBy: 6,
            HighScore: 0,
            Locations: [],
            CurrentWeeks: [],
            Weeks: [],
            CurrentWeek: moment().startOf('isoWeek')
        }
    },
    created() {
        api.GetAsync("/api/v1/booking/locations/list")
            .then(res => {
                this.Locations = res;
                this.SelectedLocation = this.Locations[0].Id;
                this.GetWeeksFrom(moment());
                this.ChangeLocation(this.SelectedLocation.Id);
            })
    },
    computed: {
        OrderedWeeks() {
            // this.Weeks.forEach(w => {
            //     if (w.WeekNr === 1) {
            //         w.Year++;
            //     }
            // });

            return _.sortBy(this.Weeks, ["Year", "WeekNr"], ["desc", "asc"])
        }
    },
    methods: {
        DivideByRooms(value, divide) {
            if (!value) {
                return 0;
            }
            return (Math.round((value / divide) * 10) / 10).toFixed(1);
        },
        GetMax() {
            api.GetAsync(`/api/v1/booking/finance/location/${this.SelectedLocation}/max`)
                .then(res => {
                    this.HighScore = res.Maximum;
                });

        },
        ChangeLocation(selectedLocation) {
            switch (selectedLocation) {
                case 1:
                    this.DividedBy = 6;
                    break;
                case 2:
                    this.DividedBy = 4;
                    break;
                case 3:
                    this.DividedBy = 4;
                    break;
                case 4:
                    this.DividedBy = 5;
                    break;
                case 5:
                    this.DividedBy = 1;
                    break;
                case 6:
                    this.DividedBy = 3;
                    break;
                case 10:
                    this.DividedBy = 2;
                    break;
            }

            this.LoadData();
        },
        LoadData() {
            this.Loading = true;
            this.Weeks = [];
            const promiseList = [];

            this.GetMax();

            this.CurrentWeeks.forEach(w => {
                promiseList.push(api.GetAsync(`/api/v1/booking/finance/location/${this.SelectedLocation}?date=${w}`))
            });

            Promise.all(promiseList)
                .then(res => {
                    res.forEach(r => {
                        this.Weeks.push(r)
                    });

                    this.Loading = false;
                })
        },

        GetWeeksFrom() {
            this.CurrentWeeks = [
                this.CurrentWeek.clone().subtract(35, 'days').format("MM-DD-YYYY"),
                this.CurrentWeek.clone().subtract(28, 'days').format("MM-DD-YYYY"),
                this.CurrentWeek.clone().subtract(21, 'days').format("MM-DD-YYYY"),
                this.CurrentWeek.clone().subtract(14, 'days').format("MM-DD-YYYY"),
                this.CurrentWeek.clone().subtract(7, 'days').format("MM-DD-YYYY"),
                this.CurrentWeek.clone().format("MM-DD-YYYY")];
        },

        NextWeek() {
            this.CurrentWeek.add(7, "days");
            this.GetWeeksFrom();
            this.LoadData();
        },

        PrevWeek() {
            this.CurrentWeek.subtract(7, "days");
            this.GetWeeksFrom();
            this.LoadData();
        }
    }
}
</script>

<style scoped lang="scss">
.table-small {
    tr {
        td {
            padding-bottom: 2px;
            padding-top: 2px;
        }
    }
}
</style>
