<template>
    <section>
        <div class="row">
            <div class="col-10">
                <h2>
                    Gifts
                </h2>
            </div>
        </div>

        <div class="mt-4" v-if="Pagination">
            <auto-table @table-click="SelectItem" :pagination="Pagination" :columns="Columns" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination" :allow500="true"/>
        </div>
    </section>
</template>

<script>
    import AutoTable from "../components/Shared/Table";
    import Pagination from "../components/Shared/Pagination";
    import TableView from "../mixins/TableView";

    export default {
        name: "gifts",
        mixins: [TableView],
        components: {Pagination, AutoTable},
        data() {
            return {
                Pagination: null,
                Url: `/api/v1/booking/gifts`,
                OrderBy: 'CreatedAt',
                Columns: [
                    {
                        Title: "Created At",
                        Sortable: false,
                        Type: "COMBINED_DATE",
                        Class: "VoucherBatch",
                        Attribute: "CreatedAt"
                    },
                    {
                        Title: "Name",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Name"
                    },
                    {
                        Title: "Email",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Email"
                    },
                    {
                        Title: "IsPaid",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "IsPaid"
                    },
                    {
                        Title: "Amount",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Amount"
                    },
                    {
                        Title: "VoucherCode",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "VoucherBatch",
                        Attribute: "Code"
                    }
                ]
            }
        },
        async created() {
            this.GetPage();
        },
        methods: {
            SelectItem(item) {
                this.$router.push(`/gifts/${item.Id}`);
            }
        }
    }
</script>

<style scoped>

</style>
