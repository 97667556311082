<template>
    <div>
        <reservation-navigation/>

        <div class="row">
            <div class="col-8">
                <router-view/>
            </div>

            <div class="col">
                <reservation-information :reservation="Reservation"/>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/services/booking-service'
    import ReservationNavigation from "@/components/Reservations/Navigation";
    import ReservationInformation from "@/components/Reservations/Information";

    export default {
        name: "reservation-details",
        components: {ReservationInformation, ReservationNavigation},
        data() {
            return {
                Reservation: null
            }
        },
        async created() {
            this.Reservation = await api.GetAsync(`/api/v1/booking/orders/0/order-rows/${this.$route.params.id}`)
        }
    }
</script>

<style scoped lang="scss">
    .tabs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;

        a {
            color: black;
        }
    }

</style>
