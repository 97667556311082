import moment from 'moment';

export default {
    methods: {
        useFloor(value: number) {
            if (!value) {
                return 0;
            }
            return Math.floor(value)
        },
        useValuta(value: any) {
            if (value) {
                return `€ ${value.toFixed(2).replace('.', ',')}`;
            }
            return `€ 0,00`;
        },
        usePercent(amount: any) {
            if (!amount) {
                amount = 0;
            }
            return amount + " %";
        },
        usePadding(num: number) {
            const date = new Date(0);
            date.setSeconds(num);
            return date.toISOString().slice(17, 19);
        },
        useFormatLongTime(time: string) {
            if (!moment(time).isValid()) {
                return "-"
            }
            return moment(time).format("DD-MM-YYYY HH:mm");
        },
        useFormatOnlyTime(time: string) {
            return moment(time).format("HH:mm");
        },
        useFormatTime(time: string) {
            if (!moment(time).isValid()) {
                return "-"
            }
            return moment(time).format("DD-MM-YYYY HH:mm:ss");
        },
        useFormatDate(time: string) {
            if (!moment(time).isValid()) {
                return "-"
            }
            return moment(time).format("DD-MM-YYYY");
        },
        useFormatDay(time: string): string {
            if (!moment(time).isValid()) {
                return "-"
            }
            const day = moment(time).format("dddd");

            const dayMap = {
                "Monday": "Maandag",
                "Tuesday": "Dinsdag",
                "Wednesday": "Woensdag",
                "Thursday": "Donderdag",
                "Friday": "Vrijdag",
                "Saturday": "Zaterdag",
                "Sunday": "Zondag"
            };

            // @ts-ignore
            return dayMap[day];
        },
        useTranslateDay(day: string): string {
            const dayMap = {
                "Monday": "Maandag",
                "Tuesday": "Dinsdag",
                "Wednesday": "Woensdag",
                "Thursday": "Donderdag",
                "Friday": "Vrijdag",
                "Saturday": "Zaterdag",
                "Sunday": "Zondag"
            };

            // @ts-ignore
            return dayMap[day];
        },
    }
}
