<template>
    <div class="information" v-if="reservation">
        <h3>
            Id - {{reservation.Id}}
        </h3>

        <div class="information__list">
            <dl class="grid-data-list data-list">
                <dt>
                    Locatie
                </dt>
                <dd>
                    {{reservation.Order.Location.DisplayName}}
                </dd>

                <dt>
                    Prijs
                </dt>
                <dd>
                    {{reservation.Price.Amount}}
                </dd>
                <dt>
                    Personen
                </dt>
                <dd>
                    {{reservation.Price.People}}
                </dd>
                <dt>
                    Kamer
                </dt>
                <dd>
                    {{reservation.Room.Name}}
                </dd>
                <dt>
                    Datum / Tijd
                </dt>
                <dd>
                    {{useFormatDate(reservation.BookingDate)}} {{usePadding(reservation.Slot.StartHour)}}:{{usePadding(reservation.Slot.StartMinute)}}
                </dd>
                <template v-if="reservation.DeviatingStartTime">
                    <dt>
                        Awijkende start tijd
                    </dt>
                    <dd class="text-danger">
                        {{reservation.DeviatingStartTime}}
                    </dd>
                </template>

                <dt>
                    Order
                </dt>
                <dd>
                    <a :href="'/orders/' + reservation.Order.Id">
                        {{reservation.Order.Code}}
                    </a>
                </dd>

                <dt>
                    Order Betaald
                </dt>
                <dd>
                    {{reservation.Order.TotalAmount === reservation.Order.PaidAmount ? 'Ja' : 'Neen' }}
                </dd>

                <dt>
                    Besteller
                </dt>
                <dd>
                    {{reservation.Customer.FirstName}} {{reservation.Customer.LastName}}
                </dd>
                <dt>
                    Besteller Email
                </dt>
                <dd>
                    {{reservation.Customer.Email}}
                </dd>
                <dt>
                    Besteller Tel
                </dt>
                <dd>
                    {{reservation.Customer.TelephoneNumber}}
                </dd>

            </dl>
        </div>
    </div>
</template>

<script>
    import Filters from "../../mixins/Filters";

    export default {
        name: "reservation-information",
        props: ["reservation"],
        mixins: [Filters],
        methods: {}
    }
</script>

<style scoped lang="scss">
    .information {
        background-color: #c4c4c4;
        border-radius: 3px;
        padding: 20px;
    }
</style>
