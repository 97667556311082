<template>
    <div class="container">
        <h2>
            Settings
        </h2>

        <div>
            <select class="form-control" v-model="DaySelected" @change="SelectDay">
                <option :value="null">Choose a day</option>
                <option :value="1">Monday</option>
                <option :value="2">Tuesday</option>
                <option :value="3">Wednesday</option>
                <option :value="4">Thursday</option>
                <option :value="5">Friday</option>
                <option :value="6">Saturday</option>
                <option :value="0">Sunday</option>
            </select>
        </div>

        <div v-if="locationSettings" class="mt-4 row">
            <div class="col-12 row">
                <div class="col-8 text-left">
                    <label for="CutoffTimeInHours">
                        1. Time in hours (after this hour on the <strong class="text-info">selected day</strong>, user will see the planning for tomorrow as 'today')
                    </label>
                </div>
                <div class="col">
                    <input id="CutoffTimeInHours" class="form-control" type="text"
                           v-model="locationSettings.CutoffTimeInHours">
                </div>
            </div>
            <div class="col-12 row">
                <div class="col-8 text-left">
                    <label for="UseRoomCombination">
                        2. Use maximum of slots in a row <span class="text-danger">(Leave unchecked if you are using room combinations, checkmark (aanvinken) this for Tilburg/Hoofddorp)</span>
                    </label>
                    <span class="material-icons"  title="Are room combination used? If yes --> check this">info</span>
                </div>
                <div class="col">
                    <input id="UseRoomCombination" class="form-control" type="checkbox" v-model="UseRoomCombination">
                </div>
            </div>

            <div class="col-12 row" v-if="!UseRoomCombination">
                <div class="col-8 text-left">
                    <label for="MaximumRoomCombination">
                        3. Maximum allowed rooms to be booked on a row. (Room combinations will be used!) <span class="text-danger">Number of gamemasters x 2</span>
                    </label>
                </div>
                <div class="col">
                    <input id="MaximumRoomCombination" class="form-control" type="text"
                           v-model="locationSettings.MaximumRoomCombination">
                </div>
            </div>

            <div class="col-12 row">
                <div class="col-8 text-left">
                    <label for="MaximumAllowedRoomsPerOrderForShortPeriodBookings">
                        4. Max amount of rooms or allowed roombinations per booking (0 => allow all). <span class="text-danger">Fill in the maximum amount of rooms a customer can book (gameMasters x2)</span>
                    </label>
                </div>
                <div class="col">
                    <input id="MaximumAllowedRoomsPerOrderForShortPeriodBookings" class="form-control" type="text"
                           v-model="locationSettings.MaximumAllowedRoomsPerOrderForShortPeriodBookings">
                </div>
            </div>

            <div class="col-12 row">
                <div class="col-8 text-left">
                    <label for="MaximumAllowedRoomsPerOrderForShortPeriodBookings">
                        5. Maximum bookable slots a customer can order on 1 row (0 => ignore). <span class="text-danger">Please fill in the same number as question {{UseRoomCombination ? '3' : '4'}}.</span>
                    </label>
                </div>
                <div class="col">
                    <input id="MaximumAllowedRoomsPerOrderForShortPeriodBookings" class="form-control" type="text"
                           v-model="locationSettings.MaximumBookableSlotsOnRow">
                </div>
            </div>

            <div class="col-12 row">
                <div class="col-8 text-left">
                    <label for="IsBookingOnSameDayAllowed">
                        6. Allow bookings on the same day
                    </label>
                </div>
                <div class="col">
                    <input id="IsBookingOnSameDayAllowed" class="form-control" type="checkbox"
                           v-model="locationSettings.IsBookingOnSameDayAllowed">
                </div>
            </div>

            <div class="col-12 row" v-if="locationSettings.IsBookingOnSameDayAllowed">
                <div class="col-8 text-left">
                    <label for="MinimumTimeBeforeBooking">
                        6-a. [Today] Minimum Time before booking in minutes
                    </label>
                </div>
                <div class="col">
                    <input id="MinimumTimeBeforeBooking" class="form-control" type="text"
                           v-model="locationSettings.MinimumTimeBeforeBooking">
                </div>
            </div>

            <div class="col-12 row" v-if="locationSettings.IsBookingOnSameDayAllowed">
                <div class="col-8 text-left">
                    <label for="IsSlotRowDefaultBlocked">
                        6-b. [Today] Row is default blocked (if there are no slots booked on a row, the will not be bookable)
                    </label>
                </div>
                <div class="col">
                    <input id="IsSlotRowDefaultBlocked" class="form-control" type="checkbox"
                           v-model="locationSettings.IsSlotRowDefaultBlocked">
                </div>
            </div>

            <div class="col-12 text-info">
                Everything will be closed due to above rules.<br>
                Some slots will be automatically available due to above rules. These slots are marked in yellow.<br>
                Everything you would like to open manually is set in green. This slots will never close automically.<br>
                When you open something manually, you also need to close it manually.
            </div>

            <div class="mt-4 offset-10 col-2">
                <button class="btn btn-danger" @click="Save" :class="{'disabled': this.saving}">
                    Save changes
                </button>
            </div>

        </div>
    </div>
</template>

<script>

import BookingService from "@/services/booking-service";
import moment from "moment";

export default {
    name: "settings",
    components: {},
    data() {
        return {
            locationSettings: null,
            DaySelected: null,
            UseRoomCombination: false,
            loading: true,
            saving: false
        }
    },
    methods: {
        async SelectDay() {
            this.locationSettings = null;
            this.locationSettings = await BookingService.GetAsync(`/api/v1/booking/locations/${this.$route.params.id}/settings?day-of-week=${this.DaySelected}`);

            if (this.locationSettings.MaximumRoomCombination == null) {
                this.UseRoomCombination = true;
            }
            this.loading = false;
        },
        async Save() {
            this.loading = true;
            this.saving = true;
            if (this.UseRoomCombination) {
                this.locationSettings.MaximumRoomCombination = null;
            }
            try {
                await BookingService.PutAsync(`/api/v1/booking/locations/${this.$route.params.id}/settings/${this.locationSettings.Id}`, this.locationSettings);
                this.emitter.emit("notification", {
                    title: "success",
                    timestamp: moment().format("hh:mm:ss"),
                    body: "Settings have been saved!"
                });
                await this.SelectDay();
            } catch (e) {
                this.emitter.emit("notification", {
                    title: "error",
                    timestamp: moment().format("hh:mm:ss"),
                    body: "Could not save settings due to a backend error. Please try again later"
                });
                await this.SelectDay();
            }

            this.saving = false;
        }
    }
}
</script>

<style scoped>

</style>
