<template>
    <section v-if="company">
        <div class="row">
            <div class="col-10">
                <h2 class="text-left">
                    {{company.Name}}
                </h2>
            </div>
            <div class="col">
                <button @click="EditCompanyModal" class="btn btn-secondary material-icons">
                    edit
                </button>
            </div>
        </div>

        <div class="information">
            <dl class="grid-data-list data-list">
                <dt>
                    Name
                </dt>
                <dd>
                    {{company.Name}}
                </dd>
                <dt>
                    Email
                </dt>
                <dd>
                    {{company.Email}}
                </dd>
                <dt>
                    Tel
                </dt>
                <dd>
                    {{company.TelephoneNumber}}
                </dd>
                <dt>
                    Street
                </dt>
                <dd>
                    {{company.Street}}
                </dd>
                <dt>
                    HouseNumber
                </dt>
                <dd>
                    {{company.HouseNumber}}
                </dd>
                <dt>
                    PostCode
                </dt>
                <dd>
                    {{company.ZipCode}}
                </dd>
                <dt>
                    Province
                </dt>
                <dd>
                    {{company.State}}
                </dd>
            </dl>
        </div>
    </section>
</template>

<script>
    import Filters from "../../mixins/Filters";

    export default {
        name: "company-information",
        props: ["company"],
        mixins: [Filters],
        methods: {
            EditCompanyModal() {
                this.emitter.emit("open-modal", {type: "EditCompanyModal", data: this.company})
            }
        }
    }
</script>

<style scoped lang="scss">
    .information {
        background-color: #c4c4c4;
        border-radius: 3px;
        padding: 20px;
    }
</style>
