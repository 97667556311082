import ErrorHelpers from '@/helpers/Error';
import api from "@/services/booking-service"

export default {
    data() {
        return {
            Pagination: {
                OrderBy: "CreatedAt",
                OrderByDesc: false,
                Offset: 0,
                Limit: 10,
                Results: [],
                Total: 0
            },
            Url: "",
            Loading: true,
        }
    },
    methods: {
        OrderBy(orderBy: string, orderByDesc: boolean) {
            // @ts-ignore
            this.Pagination.OrderBy = orderBy;
            // @ts-ignore
            this.Pagination.OrderByDesc = orderByDesc;

            this.GetPage();
        },
        Paginate(limit: number, offset: number) {
            // @ts-ignore
            this.Pagination.Offset = offset;
            // @ts-ignore
            this.Pagination.Limit = limit;

            this.GetPage();
        },
        GetPage() {
            // @ts-ignore
            if (!this.Url) {
                return;
            }

            // @ts-ignore
            this.Loading = true;
            // @ts-ignore

            api.GetAsync(this.Url, this.Pagination)
                .then((response) => {
                    if (!response) {
                        // @ts-ignore
                        this.Pagination = {
                            OrderBy: "CreatedAt",
                            OrderByDesc: false,
                            Offset: 0,
                            Limit: 10,
                            Results: [],
                            Total: 0
                        };
                        // @ts-ignore

                        this.Loading = false;
                        return;
                    }
                    // @ts-ignore
                    if (!this.Pagination) {
                        // @ts-ignore

                        this.Pagination = response;
                    } else {
                        // @ts-ignore
                        this.Pagination.Results = response.Results;
                        // @ts-ignore
                        this.Pagination.Limit = response.Limit;
                        // @ts-ignore
                        this.Pagination.Offset = response.Offset;
                        // @ts-ignore
                        this.Pagination.Total = response.Total;
                        // @ts-ignore
                    }

                    // @ts-ignore
                    this.Loading = false;
                }, err => ErrorHelpers.HandleError(err, "Er ging iets mis bij het ophalen van de gegevens."))
        }

    }
}
