<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Edit room
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="name">
                            Name
                        </label>
                    </div>
                    <div class="col">
                        <input id="name" class="form-control" type="text" v-model="room.Name">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Order">
                            Display Sequence
                        </label>
                    </div>
                    <div class="col">
                        <input id="Order" class="form-control" type="number" v-model="room.Order">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="MinimumParticipants">
                            Minimum participants
                        </label>
                    </div>
                    <div class="col">
                        <input id="MinimumParticipants" class="form-control" type="number"
                               v-model="room.MinimumParticipants">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="MaximumParticipants">
                            Maximum participants
                        </label>
                    </div>
                    <div class="col">
                        <input id="MaximumParticipants" class="form-control" type="number"
                               v-model="room.MaximumParticipants">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="PriceAddition">
                            Price Addition
                        </label>
                    </div>
                    <div class="col">
                        <input id="PriceAddition" class="form-control" type="number" v-model="room.PriceAddition">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="published">
                            Published (Shown to customer)
                        </label>
                    </div>
                    <div class="col">
                        <input id="published" type="checkbox" v-model="room.Published">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="enabled">
                            Enabled (Shown on overview)
                        </label>
                    </div>
                    <div class="col">
                        <input id="enabled" type="checkbox" v-model="room.IsEnabled">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="bookings-from">
                            Bookings From (from what date the room is available) (only on website)
                        </label>
                    </div>
                    <div class="col">
                        <input id="bookings-from" class="form-control" type="date" v-model="room.BookingsFrom">
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="bookings-end-date">
                            Room has end date?
                        </label>
                    </div>
                    <div class="col">
                        <input id="bookings-end-date" type="checkbox" v-model="RoomHasEndDate">
                    </div>
                </div>

                <div class="col-12 row" v-if="RoomHasEndDate">
                    <div class="col-4 text-left">
                        <label for="bookings-to">
                            Bookings To (until what date the room is available) (only on website)
                        </label>
                    </div>
                    <div class="col">
                        <input id="bookings-to" class="form-control" type="date" v-model="room.BookingsTo">
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Filters from "../mixins/Filters";
import Modal from "../mixins/Modal";
import BookingService from "@/services/booking-service";

export default {
    name: "edit-room-modal",
    components: {},
    mixins: [Filters, Modal],
    props: ["data"],
    data() {
        return {
            room: null,
            RoomHasEndDate: false,
        }
    },
    created() {
        this.room = JSON.parse(JSON.stringify(this.data))
        this.room.BookingsFrom = moment(this.room.BookingsFrom).format("YYYY-MM-DD");
        if (this.room.BookingsTo) {
            this.RoomHasEndDate = true;
            this.room.BookingsTo = moment(this.room.BookingsTo).format("YYYY-MM-DD");
        }
    },
    methods: {
        async Save() {
            const data = this.room;

            data.BookingsFrom = moment(this.room.BookingsFrom).format("YYYY-MM-DD");
            data.BookingsTo = this.RoomHasEndDate ? moment(this.room.BookingsTo).format("YYYY-MM-DD") : null;

            await BookingService.PutAsync(`/api/v1/booking/locations/${this.room.LocationId}/rooms/${this.room.Id}`, data);
            this.emitter.emit("reload-location-rooms");
            this.CloseModal();
        }
    }
}
</script>

<style scoped lang="scss">

</style>
