<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Package Deal
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="SeqNr">
                            Display Sequence
                        </label>
                    </div>
                    <div class="col">
                        <input id="SeqNr" class="form-control" type="number" v-model="packageDeal.SeqNr">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Name">
                            Name
                        </label>
                    </div>
                    <div class="col">
                        <input id="Name" class="form-control" type="text" v-model="packageDeal.Name">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Description">
                            Description (not shown)
                        </label>
                    </div>
                    <div class="col">
                        <input id="Description" class="form-control" type="text" v-model="packageDeal.Description">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="PublicBookingText">
                            Public Booking Text
                        </label>
                    </div>
                    <div class="col">
                        <input id="PublicBookingText" class="form-control" type="text"
                               v-model="packageDeal.PublicBookingText">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Description">
                            Email Text
                        </label>
                    </div>
                    <div class="col">
                        <input id="EmailText" class="form-control" type="text" v-model="packageDeal.EmailText">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="duration">
                            Duration In Minutes
                        </label>
                    </div>
                    <div class="col">
                        <input id="duration" class="form-control" type="number" v-model="packageDeal.DurationMinutes">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="AllowTime">
                            Start time required
                        </label>
                    </div>
                    <div class="col">
                        <input id="AllowTime" type="checkbox" v-model="packageDeal.AllowTime">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="SendBookingRequest">
                            Agreement needed
                        </label>
                    </div>
                    <div class="col">
                        <input id="SendBookingRequest" type="checkbox" v-model="packageDeal.SendBookingRequest">
                    </div>
                </div>
                <div class="col-12 row" v-if="packageDeal.SendBookingRequest">
                    <div class="col-4 text-left">
                        <label for="RequestEmail">
                            Agreement by
                        </label>
                    </div>
                    <div class="col">
                        <input id="RequestEmail" class="form-control" type="email" v-model="packageDeal.RequestEmail">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="NotifyEmail">
                            Notify Email
                        </label>
                    </div>
                    <div class="col">
                        <input id="NotifyEmail" class="form-control" type="email" v-model="packageDeal.NotifyEmail">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="published">
                            Published (shown to client)
                        </label>
                    </div>
                    <div class="col">
                        <input id="published" type="checkbox" v-model="packageDeal.Published">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="default">
                            Default
                        </label>
                    </div>
                    <div class="col">
                        <input id="default" type="checkbox" v-model="packageDeal.Default">
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="price-default">
                            Overiew Color
                        </label>
                    </div>
                    <div class="col">
                        <select class="form-control" v-model="packageDeal.OverviewColor">
                            <option value="red">Red</option>
                            <option value="purple">Purple</option>
                            <option value="blue">Blue</option>
                            <option value="orange">Orange</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Filters from "../mixins/Filters";
import Modal from "../mixins/Modal";
import BookingService from "@/services/booking-service";
import moment from "moment";

export default {
    name: "edit-package-deal-modal",
    components: {},
    mixins: [Filters, Modal],
    props: ["data"],
    data() {
        return {
            packageDeal: null
        }
    },
    created() {
        if (!this.data.Id) {
            this.createNew = true;
            this.packageDeal = {
                SeqNr: 1,
                Name: "",
                Description: "",
                EmailText: "",
                PublicBookingText: "",
                DurationMinutes: 60,
                OverviewColor: 'red',
                LocationId: this.$route.params.id,
                AllowTime: false,
                Published: false,
                Default: false,
                SendBookingRequest: false,
                RequestEmail: "",
                NotifyEmail: "",
            };
            return;
        }
        this.packageDeal = JSON.parse(JSON.stringify(this.data));

    },
    methods: {
        async Save() {
            if (this.createNew) {
                await this.CreatePackageDeal();
                return;
            }
            await this.EditPackageDeal();
        },
        async CreatePackageDeal() {
            await BookingService.PostAsync(`/api/v1/booking/locations/${this.$route.params.id}/price-types`, this.packageDeal);
            this.emitter.emit("reload-location-package-deals");
            this.CloseModal();
        },
        async EditPackageDeal() {
            try {
                await BookingService.PutAsync(`/api/v1/booking/locations/${this.$route.params.id}/price-types/${this.packageDeal.Id}`, this.packageDeal);
                this.emitter.emit("reload-location-package-deals");
                this.CloseModal();
            } catch (error) {
                this.emitter.emit("notification", {
                    title: "error",
                    timestamp: moment().format("hh:mm:ss"),
                    body: `Could not update package deal: ${error}`
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
