<template>
    <div>
        <h2>Feedback</h2>

        <div class="mt-4">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>

    </div>
</template>

<script>
    import AutoTable from "../../components/Shared/Table";
    import TableView from "../../mixins/TableView";
    import Pagination from "../../components/Shared/Pagination";

    export default {
        name: "",
        components: {Pagination, AutoTable},
        mixins: [TableView],
        data() {
            return {
                Loading: true,
                Pagination: null,
                Url: `/api/v1/booking/locations/${this.$route.params.id}/feedback`,
                Columns: [
                    {
                        Title: "Datum",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "CreatedAt"
                    },
                    {
                        Title: "Score (op 5)",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Score"
                    },
                    {
                        Title: "Kamer",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "RoomFeedback"
                    },
                    {
                        Title: "Gamemaster",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "GameMasterFeedback"
                    },
                    {
                        Title: "Ontvangst",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "WelcomeFeedback"
                    },
                    {
                        Title: "Verbeter punten",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "ImprovementText"
                    },
                    {
                        Title: "Verwachtingen",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "ExpectationFeedback"
                    },
                    {
                        Title: "Order",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "Order",
                        Attribute: "Code"
                    }
                ]
            }
        },
        created() {
            this.GetPage();
        },
        methods: {
            SelectItem(feedback) {
                this.$router.push(`/orders/${feedback.OrderId}`)
            },
        }
    }
</script>

<style scoped>

</style>
