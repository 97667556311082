<template class="dashboard">
    <li class="list-group-item"
        :class="getColorForSlot(item)">

        <div class="row">
            <div class="p-0 m-0 col-3">
                <span class="text-danger" v-if="item.Order && item.Order.DeviatingStartTime">
                    {{ item.Order.DeviatingStartTime }}
                </span>
                <span v-else class="inline-block">
                    {{ usePadding(item.StartHour) }}:{{ usePadding(item.StartMinute) }}
                </span>
                <i class="inline-block text-warning"
                   v-if="item.Order && item.Order.PreferredCombinationTime">&nbsp;({{ item.Order.PreferredCombinationTime }})</i>
            </div>

            <div class="p-0 m-0 col" v-if="item.IsBooked">
                <span class="badge badge-success float-right" v-if="item.Order.TotalAmount === item.Order.PaidAmount">
                    {{ item.Order.PaymentType }}
                </span>
                <span class="badge badge-danger float-right" v-else>
                   {{ item.Order.PaymentType }} {{ useValuta(item.Order.TotalAmount - item.Order.PaidAmount) }}
                </span>
            </div>
        </div>

        <div class="row">
            <div class="p-0 m-0 col clickable">
                <action-row :item="item" @edit-order="EditOrder"
                            @remove-state="RemoveState"
                            @publish-slot="PublishSlot"
                            @open-slot="OpenSlot"
                            @block-slot="BlockSlot"/>
            </div>
        </div>

        <div v-if="item.IsBooked" class="row"
             :title="item.Order.Customer.Email + ' - ' + item.Order.Customer.TelephoneNumber">
            <p class="m-0 p-0 col-2">
                <span class="badge badge-info">
                    {{ item.Order.People }}
                </span>
            </p>

            <p class="m-0 p-0 col text-ellipsis">
                {{ item.Order.Customer.FirstName }} {{ item.Order.Customer.LastName }}
            </p>
        </div>

        <div v-else class="row">
            <p class="m-0 p-0 col-12">
                -
            </p>
            <p v-if="!item.IsBooked && !item.SlotStatus" class="m-0 p-0 col-12">
                -
            </p>
        </div>

        <div v-if="item.IsBooked" class="row">
            <p class="m-0 p-0 col-12" v-if="item.Order.OrderStatus">
                {{ item.Order.OrderStatus }}
            </p>

            <p class="m-0 p-0 col-12" v-if="item.Order.PriceType">
                <strong class="text-ellipsis" :title="item.Order.PriceType">
                    {{ item.Order.PriceType }}
                </strong>
            </p>
        </div>

        <div class="row">
            <div class="col">
                <strong :title="item.RuleDescription">
                    {{ GetSlotTitle(item) }}
                </strong>
            </div>

        </div>

        <div class="row" v-if="!item.IsBooked">
            <div class="col" v-if="item.IsManuallyBlocked">
                <small v-if="item.SlotStatus" class="slot-status-overflow" :title="item.BlockedReason">
                    ({{ item.SlotStatus }}) <span class="material-icons small" v-if="item.BlockedReason"
                                                  @click="showBlockedReason = !showBlockedReason">info</span>
                </small>
                <div v-if="showBlockedReason" class="blocked-reason">
                    {{ item.BlockedReason }}
                </div>
            </div>
        </div>

    </li>
</template>

<script>
import ActionRow from "./ActionRow";
import Filters from "@/mixins/Filters";

export default {
    name: "item-card",
    components: {ActionRow},
    props: ['item', 'date'],
    mixins: [Filters],
    data() {
        return {
            showBlockedReason: false
        }
    },
    methods: {
        GetSlotTitle(item) {
            if (item.IsBooked) {
                return ''
            }

            if (item.IsManuallyOpened || item.IsManuallyOpened) {
                return 'Online available (Man)'
            }

            if (!item.IsPublished) {
                return 'Disabled'
            }

            if (item.IsManuallyBlocked) {
                return 'Blocked'
            }

            if (item.IsAvailable) {
                return 'Online available (Auto)'
            }

            return "Not bookable";
        },
        getColorForSlot(item) {

            if (item.IsBooked && item.Order.OverviewColor) {
                const cls = {};
                cls[`list-group-item-${item.Order.OverviewColor}`] = true;
                return cls
            }

            if (item.IsBooked) {
                return {
                    'list-group-item-danger': true
                }
            }


            if (item.IsManuallyOpened || item.IsManuallyOpened) {
                return {
                    'list-group-item-success': true
                }
            }

            if (!item.IsPublished) {
                return {
                    'list-group-item-info': true
                }
            }

            if (item.IsManuallyBlocked) {
                return {
                    'list-group-item-manual': true
                }
            }

            if (item.IsAvailable) {
                return {
                    'list-group-item-warning': true
                }
            }

            return {};
        },
        EditOrder() {
            this.$emit("edit-order", this.item)
        },
        SetState(item, stateId) {
            this.$emit("set-state", {Item: item, State: stateId})
        },
        BlockSlot(item) {
            this.$emit("block-slot", item)
        },
        RemoveState(item) {
            this.$emit("remove-state", item)
        },
        OpenSlot(item) {
            this.$emit("open-slot", item)
        },
        PublishSlot(item) {
            this.$emit("publish-slot", item)
        },
    }
}
</script>

<style scoped lang="scss">

.blocked-reason {
    display: block;
    position: absolute;
    top: 20px;
    background-color: white;
    color: black;
    padding: 10px;
    z-index: 100;
    border: black 1px solid;
}

.list-group-item-manual {
    background-color: #d72525;
    color: white;
}

.list-group-item-purple {
    background-color: #9c53e1;
    color: white;
}

.list-group-item-blue {
    background-color: #447eff;
    color: white;
}

.list-group-item-orange {
    background-color: #e1b453;
    color: white;
}

.list-group-item-red {
    color: #721c24;
    background-color: #f5c6cb;
}

.inline-block {
    display: inline;
}

.slot-status-overflow {
    text-overflow: ellipsis;
}
</style>
