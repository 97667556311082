<template>
    <div v-if="!Loading">
        <h2>
            Forms
        </h2>

        <div class="row p-4">
            <div class="col-4">
                Location
                <select class="form-control" v-model="SelectedLocation">
                    <option :value="null">
                        All Locations
                    </option>
                    <option v-for="loc in Locations" :key="loc.Id" :value="loc.Id">
                        {{loc.DisplayName}}
                    </option>
                </select>
            </div>
            <div class="col-3">
                From <small class="text-info">(mandatory from 01-07-2019)</small>
                <input class="form-control" type="date" v-model="Gte">
            </div>
            <div class="col-3">
                To
                <input class="form-control" type="date" v-model="Lte">
            </div>
            <div class="col-2">
                &nbsp;<br/>
                <button class="btn btn-primary" @click="Search">
                    Search
                </button>
            </div>
        </div>

        <div class="row" v-if="FormInformation">
            <div class="col">
                <h5>
                    # Played
                </h5>
                <table class="table">
                    <tr v-for="type in TypeCount" :key="type.TypeExperience">
                        <td>
                            # {{type.TypeExperience}}
                        </td>
                        <td>
                            {{type.Count}}
                        </td>
                        <td>
                            {{useFloor((type.Count / FormInformation.Total) * 100)}} %
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total
                        </td>
                        <td>
                            {{FormInformation.Total}}
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col">
                <h5>
                    Newsletter
                </h5>
                <table class="table">
                    <tr>
                        <td>
                            Yes
                        </td>
                        <td>
                            {{FormInformation.SubscribedTotal}}
                        </td>
                        <td>
                            {{useFloor((FormInformation.SubscribedTotal / FormInformation.Total) * 100)}} %
                        </td>
                    </tr>
                    <tr>
                        <td>
                            No
                        </td>
                        <td>
                            {{FormInformation.Total - FormInformation.SubscribedTotal}}
                        </td>
                        <td>
                            {{useFloor(((FormInformation.Total - FormInformation.SubscribedTotal) / FormInformation.Total) * 100)}} %
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total
                        </td>
                        <td>
                            {{FormInformation.Total}}
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col">
                <h5>
                    Type
                </h5>

                <table class="table">
                    <tr v-for="ref in FormInformation.FormTypeCountResponses" :key="ref.Type">
                        <td>
                            {{ref.Type || "Geen Informatie"}}
                        </td>
                        <td>
                            {{ref.Count}}
                        </td>
                        <td>
                            {{useFloor((ref.Count / FormInformation.Total) * 100)}} %
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col">
                <h5>
                    Reference
                </h5>

                <table class="table">
                    <tr v-for="ref in FormInformation.FormReferenceResponses" :key="ref.Reference">
                        <td>
                            {{ref.Reference || "Geen Informatie"}}
                        </td>
                        <td>
                            {{ref.Count}}
                        </td>
                        <td>
                            {{useFloor((ref.Count / FormInformation.Total) * 100)}} %
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="mt-4">
            <auto-table @table-click="SelectItem" :pagination="Pagination" :columns="Columns" :loading="Loading"/>
            <pagination :pagination="Pagination" @get-page="Paginate"/>
        </div>
    </div>
</template>

<script>
    import AutoTable from "../components/Shared/Table";
    import Pagination from "../components/Shared/Pagination";
    import TableView from "../mixins/TableView";
    import bookingApi from "../services/booking-service";
    import moment from "moment";
    import Filters from "@/mixins/Filters";

    export default {
        name: "companies",
        mixins: [Filters,TableView],
        components: {Pagination, AutoTable},
        data() {
            return {
                Pagination: {
                    Gte: moment().subtract(14, 'd').format("YYYY-MM-DD"),
                    Lte: moment().format("YYYY-MM-DD"),
                },
                FormInformation: null,
                Locations: [],
                Url: `/api/v1/booking/forms`,
                Gte: moment().subtract(14, 'd').format("YYYY-MM-DD"),
                Lte: moment().format("YYYY-MM-DD"),
                Columns: [
                    {
                        Title: "Aangemaakt",
                        Type: "DATE",
                        Sortable: true,
                        Attribute: "CreatedAt"
                    },
                    {
                        Title: "Nieuwsbrief",
                        Sortable: true,
                        Type: "BADGE",
                        T: "Yes",
                        F: "No",
                        Attribute: "AddToMailingList"
                    },
                    {
                        Title: "PostCode",
                        Type: "TEXT",
                        Attribute: "PostCode"
                    },
                    {
                        Title: "Type",
                        Type: "TEXT",
                        Attribute: "Type"
                    },
                    {
                        Title: "Referentie",
                        Type: "TEXT",
                        Attribute: "Reference"
                    },
                    {
                        Title: "# gespeeld",
                        Type: "TEXT",
                        Sortable: true,
                        Attribute: "Experience"
                    },
                    {
                        Title: "# Ew gespeeld",
                        Type: "TEXT",
                        Sortable: true,
                        Attribute: "TypeExperience"
                    },
                    {
                        Title: "Order Id",
                        Type: "BADGE_STATUS",
                        Attribute: "OrderId"
                    },

                ],
                SelectedLocation: null,
                Modal: {},
            }
        },
        created() {
            bookingApi.GetAsync("/api/v1/booking/locations/list")
                .then(res => {
                    this.Locations = res;
                });

            this.GetCount();
            this.GetPage();
        },
        computed: {
            TypeCount() {
                return _.sortBy(this.FormInformation.FormTypeExperienceCountResponses, "TypeExperience")
            }
        },
        methods: {
            SelectItem(form) {
                this.$router.push("/orders/" + form.OrderId + "/forms");
            },
            GetCount() {
                bookingApi.GetAsync(`/api/v1/booking/forms/count`, this.Pagination)
                    .then(res => {
                        this.FormInformation = res;
                    })
            },
            Search() {
                if (this.SelectedLocation) {
                    this.Pagination["LocationIds"] = [this.SelectedLocation];
                } else {
                    delete this.Pagination["LocationIds"]
                }

                if (this.Gte) {
                    this.Pagination["Gte"] = moment(this.Gte).format("YYYY-MM-DD");
                } else {
                    delete this.Pagination["Gte"]
                }

                if (this.Lte) {
                    this.Pagination["Lte"] = moment(this.Lte).format("YYYY-MM-DD");
                } else {
                    delete this.Pagination["Lte"]
                }

                this.GetPage();
                this.GetCount();
            }
        }
    }
</script>

<style scoped>

</style>
