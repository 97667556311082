<template>
    <div>
        <voucher-navigation/>

        <div class="row">
            <div class="col-8">
                <router-view/>
            </div>

            <div class="col">
                <voucher-information :voucher-batch="VoucherBatch"/>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/services/booking-service'
    import VoucherInformation from "@/components/Voucher/Information";
    import VoucherNavigation from "@/components/Voucher/Navigation";

    export default {
        name: "voucher-details",
        components: {VoucherNavigation, VoucherInformation},
        data() {
            return {
                VoucherBatch: null
            }
        },
        async created() {
            this.emitter.on("reload-voucher", this.LoadData);
            this.LoadData();
        },
        methods: {
            async LoadData() {
                this.VoucherBatch = await api.GetAsync(`/api/v1/booking/voucher-batches/${this.$route.params.id}`)
            }
        }
    }
</script>

<style scoped lang="scss">
    .tabs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;

        a {
            color: black;
        }
    }

</style>
