<template>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Block Slot
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body">
                <div>
                    SlotIds to be blocked:
                    <span v-for="slotID in data.slotIds" :key="slotID">
                        {{slotID}},
                    </span>
                </div>

                <section class="border-bottom">
                    <select class="form-control" v-model="SelectedReasonId">
                        <option :value="null">
                            Choose a reason
                        </option>
                        <option v-for="reason in BlockedReasons" :key="reason.Id" :value="reason.Id">
                            {{reason.Name}}
                        </option>
                    </select>
                </section>
               <div class="row">
                   <div class="col-4 text-left">
                       <label for="Description">
                           Extra Reason
                       </label>
                   </div>
                   <div class="col">
                       <input id="Description" class="form-control" type="text" v-model="blockedReason">
                   </div>
               </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";

    export default {
        name: "block-slot-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                Loading: false,
                SelectedReasonId: null,
                blockedReason: null,
                BlockedReasons: []
            }
        },
        async created() {
            this.BlockedReasons = await BookingService.GetAsync("/api/v1/booking/slot-status-reason-type/list");
        },
        methods: {
            async Save() {
                this.Loading = true;
                const request = {
                    SlotStatusReasonTypeId: this.SelectedReasonId,
                    BlockedReason: this.blockedReason,
                    Date: this.data.date,
                    StatusId: 2
                };

                for(const slotId of this.data.slotIds) {
                    await BookingService.PostAsync(`/api/v1/booking/slots/${slotId}/status`, request);
                }

                this.emitter.emit("reload-overview");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
