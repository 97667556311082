<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Create Room Combination
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body">
                <section class="border-bottom">
                    <select class="form-control" v-model="RoomId">
                        <option :value="null">
                            RoomId
                        </option>
                        <option v-for="room in RoomList" :key="room.Id" :value="room.Id">
                            {{room.Name}}
                        </option>
                    </select>
                </section>

                <section class="border-bottom">
                    <select class="form-control" v-model="CombinedRoomId">
                        <option :value="null">
                            Combined Room Id
                        </option>
                        <option v-for="room in RoomList" :key="room.Id" :value="room.Id">
                            {{room.Name}}
                        </option>
                    </select>
                </section>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";

    export default {
        name: "create-room-combination-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                RoomList: [],
                RoomId: null,
                CombinedRoomId: null,
                LocationId: this.data.LocationId
            }
        },
        async created() {
            await this.LoadData();
        },
        methods: {
            async LoadData() {
                this.RoomList = await BookingService.GetAsync(`/api/v1/booking/locations/${this.LocationId}/rooms/list`)
            },
            async Save() {
                if (this.RoomId === this.CombinedRoomId) {
                    return;
                }

                const url = `/api/v1/booking/locations/${this.LocationId}/rooms/${this.RoomId}/combinations/${this.CombinedRoomId}`;
                await BookingService.PostAsync(url);
                this.emitter.emit("reload-location-room-combinations");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
