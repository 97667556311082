<template>
    <div class="container">

        <home-pending-confirmations></home-pending-confirmations>

        <div class="row" v-if="LocationPagination">

            <div class="row col-12">

                <div class="col-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th># Last Week</th>
                            <th># This Week</th>
                            <th># Next Week</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="table-item-sm" v-for="(value, key) in LocationPagination.Locations" :key="key" @click="GoToLocationOverview(value.LocationId)">
                            <td>
                                {{key}}
                            </td>
                            <td>
                                {{value.BookingsLastWeek}} Kamers
                            </td>
                            <td>
                                {{value.BookingsThisWeek}} Kamers
                            </td>
                            <td>
                                {{value.BookingsNextWeek}} Kamers
                            </td>
                        </tr>
                        <tr class="table-item-sm" v-if="LocationPagination.Locations.length == 0">
                            <td colspan="4" class="text-center">
                                Geen kamers gevonden
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="offset-10 col-2">
                    <router-link class="btn btn-icon" to="/locations">
                        <i class="material-icons">chevron_right</i>
                    </router-link>
                </div>

            </div>

        </div>

        <div v-if="LocationDetailPagination">
            <div class="row" v-for="(value, key) in LocationDetailPagination.Locations" :key="key">


                <div class="col">
                    <h5 class="col-12 text-center">
                        Today ({{key}})
                    </h5>
                    <div class="col-12">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Tijd</th>
                                <th>Aangemaakt</th>
                                <th>Kamer</th>
                                <th>#</th>
                                <th>&euro;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="table-item-sm" v-for="orderRow in value.OrderRowsToday" :key="orderRow.Id" @click="goToOrder(orderRow.OrderId)">
                                <td>{{usePadding(orderRow.Slot.StartHour)}}:{{usePadding(orderRow.Slot.StartMinute)}}</td>
                                <td>{{useFormatDate(orderRow.CreatedAt)}}</td>
                                <td>{{orderRow.Room.Name}}</td>
                                <td>{{orderRow.Price.People}}</td>
                                <td>{{useValuta(orderRow.Price.Amount)}}</td>
                            </tr>
                            <tr class="table-item-sm" v-if="value.OrderRowsToday.length == 0">
                                <td colspan="4" class="text-center">
                                    No reservations today
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="offset-10 col-2">
                        <router-link class="btn btn-icon" to="/orders">
                            <i class="material-icons">chevron_right</i>
                        </router-link>
                    </div>
                </div>


                <div class="col">
                    <h5 class="col-12 text-center">
                        Tomorrow ({{key}})
                    </h5>
                    <div class="col-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Tijd</th>
                            <th>Aangemaakt</th>
                            <th>Kamer</th>
                            <th>#</th>
                            <th>&euro;</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="table-item-sm" v-for="orderRow in value.OrderRowsTomorrow" :key="orderRow.Id" @click="goToOrder(orderRow.OrderId)">
                            <td>{{usePadding(orderRow.Slot.StartHour)}}:{{usePadding(orderRow.Slot.StartMinute)}}</td>
                            <td>{{useFormatDate(orderRow.CreatedAt)}}</td>
                            <td>{{orderRow.Room.Name}}</td>
                            <td>{{orderRow.Price.People}}</td>
                            <td>{{useValuta(orderRow.Price.Amount)}}</td>
                        </tr>
                        <tr class="table-item-sm" v-if="value.OrderRowsTomorrow.length == 0">
                            <td colspan="4" class="text-center">
                                No reservations tomorrow
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>

                    <div class="offset-10 col-2">
                        <router-link class="btn btn-icon" to="/orders">
                            <i class="material-icons">chevron_right</i>
                        </router-link>
                    </div>
                </div>

                <div class="col row">
                    <h5 class="col-12 text-center">
                        New ({{key}})
                    </h5>

                    <div class="col-12">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Aangemaakt</th>
                                <th>Door</th>
                                <th>Status</th>
                                <th># kamers</th>
                                <th>Prijs</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="table-item-sm" v-for="order in value.OrdersNew" :key="order.Id" @click="goToOrder(order.Id)">
                                <td>{{useFormatDate(order.CreatedAt)}}</td>
                                <td>{{order.OrderType.Name}}</td>
                                <td>{{order.OrderStatus.Name}}</td>
                                <td>{{order.OrderRows.length}}</td>
                                <td>{{useValuta(order.Amount)}}</td>
                            </tr>
                            <tr class="table-item-sm" v-if="value.OrdersNew.length == 0">
                                <td colspan="4" class="text-center">
                                    No orders found
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="offset-10 col-2">
                        <router-link class="btn btn-icon" to="/orders">
                            <i class="material-icons">chevron_right</i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bookingApi from '../services/booking-service';
    import Filters from "@/mixins/Filters";
    import HomePendingConfirmations from "@/components/Home/PendingConfirmations";

    export default {
        components: {HomePendingConfirmations},
        data() {
            return {
                LocationPagination: null,
                LocationDetailPagination: null
            };
        },
        mixins: [Filters],
        async created() {
            this.LocationDetailPagination = await bookingApi.GetAsync("/api/v1/booking/dashboard");
            this.LocationPagination = await bookingApi.GetAsync("/api/v1/booking/dashboard/overview");
        },
        methods: {
            GoToLocationOverview(id) {
                this.$router.push(`/locations/${id}/dashboard`)
            },
            goToOrder(id) {
                this.$router.push(`/orders/${id}`)
            },
        }
    }
</script>

<style scoped lang="scss">
    .table-item-sm {
        td {
            padding-bottom: 5px;
            padding-top: 5px;
        }
    }

</style>
