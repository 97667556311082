<template>

    <div class="row">
        <location-navigation/>

        <div class="col-9">
            <router-view/>
        </div>

        <div class="col">
            <location-information :location="Location"/>
        </div>
    </div>

</template>

<script>
    import api from '@/services/booking-service'
    import LocationInformation from "@/components/Location/Information";
    import LocationNavigation from "@/components/Location/Navigation";

    export default {
        name: "location-details",
        components: {LocationNavigation, LocationInformation},
        data() {
            return {
                Location: null
            }
        },
        async created() {
            this.emitter.on("reload-location", this.LoadData);
            await this.LoadData();
        },
        methods: {
            async LoadData() {
                this.Location = await api.GetAsync(`/api/v1/booking/locations/${this.$route.params.id}`)
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
