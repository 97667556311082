import {createStore} from 'vuex'

export default createStore({
    state: {
        isAuthenticated: false,
        isLoading: false,
        user: null,
        authToken: null,
        isRoomSelectionEnabled: false,
        disabledSlots: [],
    },
    getters: {
        getAuthToken(state) {
            return state.authToken
        },
        getIsAuthenticated(state) {
            return state.isAuthenticated
        }
    },
    mutations: {
        setAuthToken(state, token) {
            state.authToken = token;
            state.isAuthenticated = true;
        },
        setUser(state, user) {
            state.user = user;
        },
        removeTokens(state) {
            localStorage.clear();
            sessionStorage.clear();

            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }

            state.authToken = null;
            state.isAuthenticated = false;
        },
        enableRoomSelection(state) {
            state.isRoomSelectionEnabled = !state.isRoomSelectionEnabled
        },
        resetRoomSelection(state) {
            state.isRoomSelectionEnabled = false
        },
        toggleBlockSlot(state, data) {
            // @ts-ignore
            const index = state.disabledSlots.indexOf(data.slotID);
            if (data.selected && index === -1) {
                // @ts-ignore
                state.disabledSlots.push(data.slotID)
            } else {
                state.disabledSlots.splice(index, 1)
            }
        }
    },
    actions: {
        checkLogin({commit}) {
            const existingToken = localStorage.getItem("auth-token");
            const user = localStorage.getItem("auth-user");

            if (existingToken && user) {
                const jwt = existingToken.split('.')[1]
                const parsedJwt = JSON.parse(atob(jwt))
                if (Date.now() >= parsedJwt.exp * 1000) {
                    console.log("token expired")
                    commit("removeTokens")
                    return;
                }
                commit('setAuthToken', existingToken);
                commit('setUser', JSON.parse(user));
                return;
            }
            commit("removeTokens")
        },
        setAuthToken({commit}, token) {
            commit('setAuthToken', token);
            localStorage.setItem("auth-token", token);
        },
        setUser({commit}, user) {
            commit('setUser', user);
            localStorage.setItem("auth-user", JSON.stringify(user));
        },
        logout({commit}) {
            commit('removeTokens');
        },
        resetRoomSelection({commit}) {
            commit('resetRoomSelection');
        },
        enableRoomSelection({commit}) {
            commit('enableRoomSelection');
        },
        toggleBlockSlot({commit}, data: any) {
            commit('toggleBlockSlot', data)
        }
    },
    modules: {}
})
