<template>
    <div v-if="!Loading">
        <h2>
            Reservation Queries
        </h2>

        <div class="row pl-4">
            <div class="col-2">
                Date Selection
                <select class="form-control" v-model="Pagination.SelectedDateQuery">
                    <option value="BookingDate">
                        Booking Date
                    </option>
                    <option value="CreatedAt">
                        Created At
                    </option>
                </select>
            </div>
            <div class="col-4">
                From (included)
                <input class="form-control" type="date" v-model="Gte">
            </div>
            <div class="col-4">
                To (included)
                <input class="form-control" type="date" v-model="Lte">
            </div>
        </div>

        <div class="row pl-4 mt-2">
            <div class="col-2">
                <select class="form-control" v-model="SelectedLocation" @change="GetRooms()">
                    <option :value="null">
                        All Locations
                    </option>
                    <option v-for="loc in Locations" :key="loc.Id" :value="loc.Id">
                        {{ loc.DisplayName }}
                    </option>
                </select>
            </div>
            <div class="col-2">
                <select class="form-control" v-model="SelectedRoom">
                    <option :value="null">
                        {{SelectedLocation ? "All Rooms" : "Select a Location"}}
                    </option>
                    <option v-for="room in Rooms" :key="room.Id" :value="room.Id">
                        {{ room.Name }}
                    </option>
                </select>
            </div>
            <div class="col-3">
                <select class="form-control" v-model="SelectedStatus">
                    <option :value="null">
                        All status
                    </option>
                    <option v-for="os in OrderRowStatuses" :key="os.Id" :value="os.Id">
                        {{ os.Name }}
                    </option>
                </select>

            </div>
            <div class="col-3">
                <select class="form-control" v-model="SelectedOrderType">
                    <option :value="null">
                        All types
                    </option>
                    <option v-for="ot in OrderTypes" :key="ot.Id" :value="ot.Id">
                        {{ ot.Name }}
                    </option>
                </select>
            </div>

            <div class="col-2">
                <button class="btn btn-primary" @click="Search">
                    Search
                </button>
            </div>
        </div>

        <div class="row" v-if="OrderRowInformation">
            <div class="col-6">
                <h5>
                    Number of people
                </h5>
                <table class="table">
                    <tr v-for="type in PeopleCount" :key="type.Participants">
                        <td>
                            # {{ type.Participants }}
                        </td>
                        <td>
                            <strong>{{ type.Count }}</strong>
                        </td>
                        <td>
                            {{ useFloor((type.Count / OrderRowInformation.Total) * 100) }} %
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total
                        </td>
                        <td>
                            <strong>{{ OrderRowInformation.Total }}</strong>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-6">
                <h5>
                    Rooms played
                </h5>

                <table class="table">
                    <tr v-for="type in OrderedRooms" :key="type.RoomName">
                        <td>
                            {{ type.LocationName }}
                        </td>
                        <td>
                            {{ type.RoomName }}
                        </td>
                        <td>
                            <strong>{{ type.Count }}</strong>
                        </td>
                        <td>
                            {{ useFloor((type.Count / OrderRowInformation.Total) * 100) }} %
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Total
                        </td>
                        <td>
                            <strong>{{ OrderRowInformation.Total }}</strong>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-6" v-if="TimeDifference">
                <h5>
                    Booking date – Play date
                </h5>

                <table class="table">
                    <tr v-for="(value, key) in TimeDifference.TimeDiff" :key="key">
                        <td>
                            {{ key }}
                        </td>
                        <td>
                            {{ value }}
                        </td>
                        <td v-if="value > 0">
                            {{ useFloor((value / TimeDifference.Total) * 100) }} %
                        </td>
                        <td v-else>
                            0 %
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Total
                        </td>
                        <td>
                            <strong>{{ TimeDifference.Total }}</strong>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-6" v-if="BookingDayOfWeeks">
                <h5>
                    Play day of week
                </h5>

                <table class="table">
                    <tr v-for="(value, key) in BookingDayOfWeeks.BookingDays" :key="key">
                        <td>
                            {{ key }}
                        </td>
                        <td>
                            {{ value }}
                        </td>
                        <td v-if="value > 0">
                            {{ useFloor((value / BookingDayOfWeeks.Total) * 100) }} %
                        </td>
                        <td v-else>
                            0 %
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total
                        </td>
                        <td>
                            <strong>{{ BookingDayOfWeeks.Total }}</strong>
                        </td>
                        <td>
                            100 %
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import bookingApi from "../services/booking-service"
import moment from "moment"
import _ from "lodash"
import TableView from "../mixins/TableView";
import Filters from "@/mixins/Filters";

export default {
    name: "reservation-query",
    mixins: [Filters, TableView],
    data() {
        return {
            SelectedLocation: null,
            SelectedRoom: null,
            SelectedOrderType: null,
            SelectedStatus: null,
            Gte: moment().add(-14, "d").format("YYYY-MM-DD"),
            Lte: moment().format("YYYY-MM-DD"),
            Locations: [],
            Rooms: [],
            OrderTypes: [],
            OrderRowStatuses: [],
            OrderRowInformation: [],
            TimeDifference: null,
            BookingDayOfWeeks: null,
            Pagination: {
                Gte: moment().add(-14, "d").format("YYYY-MM-DD"),
                Lte: moment().format("YYYY-MM-DD"),
                Limit: 10,
                Offset: 0,
                SelectedDateQuery: 'BookingDate',
                OrderBy: "CreatedAt",
                OrderByDec: true
            }
        }
    },
    computed: {
        PeopleCount() {
            return _.sortBy(this.OrderRowInformation.PeopleCount, "Participants")
        },
        OrderedRooms() {
            return _.orderBy(this.OrderRowInformation.RoomCount, 'Count', 'desc')
        }
    },
    created() {
        Promise.all([
            bookingApi.GetAsync("/api/v1/booking/locations/list"),
            bookingApi.GetAsync("/api/v1/booking/order-rows/status/list"),
            bookingApi.GetAsync("/api/v1/booking/order-types/list")
        ])
            .then(res => {
                this.Locations = res[0];
                this.OrderRowStatuses = res[1];
                this.OrderTypes = res[2];
            });
        this.LoadData();

    },
    methods: {
        Search() {
            this.LoadData();
        },
        GetRooms() {
            bookingApi.GetAsync(`/api/v1/booking/locations/${this.SelectedLocation}/rooms/list`)
                .then(res => {
                    this.Rooms = res;
                })
        },
        LoadData() {
            this.CreatePagination();
            this.OrderRowInformation = null;
            this.TimeDifference = null;
            this.BookingDayOfWeeks = null;

            Promise.all([
                bookingApi.GetAsync(`/api/v1/booking/queries/order-rows`, this.Pagination),
                bookingApi.GetAsync(`/api/v1/booking/queries/time-difference`, this.Pagination),
                bookingApi.GetAsync(`/api/v1/booking/queries/day-of-week`, this.Pagination)
            ])
                .then(res => {
                    this.OrderRowInformation = res[0];
                    this.TimeDifference = res[1];
                    this.BookingDayOfWeeks = res[2];
                    this.Loading = false;
                });
        },
        SelectItem(order) {
            this.$router.push(`/reservations/${order.Id}`);
        },

        CreatePagination() {
            if (this.SelectedLocation) {
                this.Pagination["LocationIds"] = [this.SelectedLocation];
            } else {
                delete this.Pagination["LocationIds"]
            }

            if (this.SelectedRoom) {
                this.Pagination["RoomIds"] = [this.SelectedRoom];
            } else {
                delete this.Pagination["RoomIds"]
            }

            if (this.SelectedOrderType) {
                this.Pagination["OrderTypeIds"] = [this.SelectedOrderType];
            } else {
                delete this.Pagination["OrderTypeIds"]
            }

            if (this.SelectedStatus) {
                this.Pagination["OrderRowStatusIds"] = [this.SelectedStatus];
            } else {
                delete this.Pagination["OrderRowStatusIds"]
            }

            if (this.Gte) {
                this.Pagination["Gte"] = moment(this.Gte).format("YYYY-MM-DD");
            } else {
                delete this.Pagination["Gte"]
            }

            if (this.Lte) {
                this.Pagination["Lte"] = moment(this.Lte).format("YYYY-MM-DD");
            } else {
                delete this.Pagination["Lte"]
            }
        },
    }
}
</script>

<style scoped>

</style>
