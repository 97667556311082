<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Voucher
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body" v-if="voucherBatch">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="BatchAmount">
                            # Vouchers
                        </label>
                    </div>
                    <div class="col">
                        <input id="BatchAmount" class="form-control" type="text" v-model="voucherBatch.BatchAmount">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Description">
                            Description
                        </label>
                    </div>
                    <div class="col">
                        <input id="Description" class="form-control" type="text" v-model="voucherBatch.Description">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Code">
                            Code
                        </label>
                    </div>
                    <div class="col">
                        <input id="Code" class="form-control" type="text" v-model="voucherBatch.Code">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="randomCode">
                            Generate Random Code
                        </label>
                    </div>
                    <div class="col">
                        <input id="randomCode" class="form-control" type="checkbox" v-model="voucherBatch.IsRandomCode">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Amount">
                            Discount
                        </label>
                    </div>
                    <div class="col">
                        <input id="Amount" class="form-control" type="number" v-model="voucherBatch.Amount">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="IsPercentage">
                            Is percentage
                        </label>
                    </div>
                    <div class="col">
                        <input id="IsPercentage" type="checkbox" v-model="voucherBatch.IsPercentage">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="IsSecondRoomsHalfPrice">
                            Is Second Room Half price
                        </label>
                    </div>
                    <div class="col">
                        <input id="IsSecondRoomsHalfPrice" type="checkbox" v-model="voucherBatch.IsSecondRoomsHalfPrice">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="client">
                            System
                        </label>
                    </div>
                    <div class="col">
                        <select class="form-control" v-model="voucherBatch.ClientId" id="client">
                            <option v-for="client in ClientList" :key="client.Id" :value="client.Id">
                                {{client.Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Location">
                            Location
                        </label>
                    </div>
                    <div class="col">
                        <select class="form-control" v-model="voucherBatch.LocationId" id="Location" @change="GetRooms">
                            <option :value="null">
                                All locations
                            </option>
                            <option v-for="location in LocationList" :key="location.Id" :value="location.Id">
                                {{location.DisplayName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12 row" v-if="voucherBatch.LocationId">
                    <div class="col-4 text-left">
                        <label for="room">
                            Room
                        </label>
                    </div>
                    <div class="col">
                        <select class="form-control" v-model="voucherBatch.RoomId" id="room">
                            <option :value="null">
                                All rooms
                            </option>
                            <option v-for="room in RoomList" :key="room.Id" :value="room.Id">
                                {{room.Name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="min-rooms">
                            Minimum Rooms
                        </label>
                    </div>
                    <div class="col">
                        <input id="min-rooms" class="form-control" type="number" v-model="voucherBatch.MinimumSelectedRooms">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col text-left">
                        <label for="from">
                            From - To
                        </label>
                    </div>
                    <div class="col">
                        <input id="from" type="date" class="form-control" v-model="voucherBatch.From">
                    </div>
                    <div class="col">
                        <input id="to" type="date" class="form-control" v-model="voucherBatch.To">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col text-left">
                        <label for="booking-from">
                            Bookings From - Bookings To
                        </label>
                    </div>
                    <div class="col">
                        <input id="booking-from" type="date" class="form-control" v-model="voucherBatch.BookingFrom">
                    </div>
                    <div class="col">
                        <input id="booking-to" type="date" class="form-control" v-model="voucherBatch.BookingTo">
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";
    import moment from "moment";

    export default {
        name: "create-voucher-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                voucherBatch: null,
                LocationList: null,
                ClientList: null,
                RoomList: [],
                Loading: true
            }
        },
        async created() {
            this.LocationList = await BookingService.GetAsync(`/api/v1/booking/locations/list`);
            this.ClientList = await BookingService.GetAsync(`/api/v1/booking/clients/list`);

            if (!this.data) {
                this.createNew = true;
                this.voucherBatch = {
                    BatchAmount: 100,
                    IsGeneric: true,
                    Amount: 10,
                    IsPercentage: false,
                    IsSecondRoomsHalfPrice: false,
                    Description: "",
                    Code: "",
                    IsRandomCode: false,
                    From: moment().format("YYYY-MM-DD"),
                    To: moment().add(3, 'M').format("YYYY-MM-DD"),
                    RoomDateFrom: null,
                    RoomDateTo: null,
                    LocationId: null,
                    RoomId: null,
                    ClientId: this.ClientList[0].Id,
                    MinimumSelectedRooms: 1,
                };
                return;
            }

            this.voucherBatch = JSON.parse(JSON.stringify(this.data));

        },
        methods: {
            async GetRooms() {
                this.voucherBatch.RoomId = null;

                if (!this.voucherBatch.LocationId) {
                    return;
                }
                this.RoomList = await BookingService.GetAsync(`/api/v1/booking/locations/${this.voucherBatch.LocationId}/rooms/list`);
            },
            async Save() {
                if (this.createNew) {
                    await this.CreateVoucherBatch();
                    return;
                }
                await this.EditVoucherBatch();
            },
            async CreateVoucherBatch() {
                const response = await BookingService.PostAsync(`/api/v1/booking/voucher-batches`, this.voucherBatch);
                this.CloseModal();
                await this.$router.push(`/vouchers/${response.Id}`);
            },
            async EditVoucherBatch() {
                await BookingService.PutAsync(`/api/v1/booking/voucher-batches/${this.voucherBatch.Id}`, this.voucherBatch);
                this.emitter.emit("reload-voucher-batch");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
