<template>
    <div>
        <div class="row">
            <label class="col-4 text-left" for="zip-code">
                Zip Code
            </label>
            <input id="zip-code" class="form-control col-8 text-left" type="text" v-model="form.PostCode">
        </div>

        <div class="row">
            <label class="col-4 text-left" for="played-type">
                # Played EscapeWorld
            </label>
            <input id="played-type" class="form-control col-8 text-left" type="text" v-model="form.TypeExperience">
        </div>

        <div class="row">
            <label class="col-4 text-left" for="played-er">
                # Played Escaperooms
            </label>
            <input id="played-er" class="form-control col-8 text-left" type="text" v-model="form.Experience">
        </div>

        <div class="row">
            <label class="col-4 text-left" for="group-type">
                Group Type
            </label>
            <select id="group-type" class="form-control col-8 text-left" v-model="form.Type">
                <option :value="''">
                    Not Given
                </option>
                <option :value="'company'">
                    Bedrijf / instelling
                </option>
                <option :value="'bachelor'">
                    Vrijgezellenfeest
                </option>
                <option :value="'friend'">
                    Vrienden
                </option>
                <option :value="'family'">
                    Gezin/familie
                </option>
                <option :value="'student'">
                    Studenten
                </option>
                <option :value="'toerist'">
                    Toeristen
                </option>
                <option :value="'child'">
                    Kinderfeestje
                </option>
                <option :value="'other'">
                    Anders
                </option>
            </select>
        </div>

        <div class="row" v-if="form.Type === 'other'">
            <label class="col-4 text-left" for="other-type">
                Other Group Type
            </label>
            <input id="other-type" placeholder="Group Type" class="form-control col-8 text-left" type="text" v-model="form.OtherGroupType">
        </div>

        <div class="row">
            <label class="col-4 text-left" for="ref">
                Reference
            </label>
            <select id="ref" class="form-control col-8 text-left" v-model="form.Reference">
                <option :value="''">
                    Not Given
                </option>
                <option :value="'via'">
                    Via via
                </option>
                <option :value="'google'">
                    Google
                </option>
                <option :value="'facebook'">
                    Facebook
                </option>
                <option :value="'poster'">
                    Poster / Flyer
                </option>
                <option :value="'other'">
                    Anders, namelijk...
                </option>
            </select>
        </div>

        <div class="row" v-if="form.Reference === 'other'">
            <label class="col-4 text-left" for="other-ref">
                Other Reference
            </label>
            <input id="other-ref"  placeholder="Reference" class="form-control col-8 text-left" type="text" v-model="form.OtherReference">
        </div>

    </div>
</template>

<script>

    export default {
        name: "edit-order-form-component",
        props: ["form"],
    }
</script>

<style scoped lang="scss">

</style>
