import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'
import Logout from '../views/Logout.vue'

import LocationHome from '../views/Locations.vue';
import LocationDetails from '../views/LocationDetails.vue';
import LocationRooms from '../views/Locations/Rooms.vue';
import LocationRoomCombinations from '../views/Locations/RoomCombinations.vue';
import LocationOrders from '../views/Locations/Orders.vue';
import LocationReservations from '../views/Locations/Reservations.vue';
import LocationPrices from '../views/Locations/Prices.vue';
import LocationPackageDeals from '../views/Locations/PackageDeals.vue';
import LocationFeedback from '../views/Locations/Feedback.vue';
import LocationForms from '../views/Locations/Forms.vue';
import LocationSettings from '../views/Locations/Settings.vue';

import LocationDashboard from '../views/LocationDashboard.vue';

import Orders from '../views/Orders.vue';
import OrderDetails from '../views/OrderDetails.vue';
import OrderReservations from '../views/Orders/Reservations.vue';
import OrderFeedback from '../views/Orders/Feedback.vue';
import OrderForms from '../views/Orders/Forms.vue';

import Reservations from '../views/Reservations.vue';
import ReservationQuery from '../views/Reservation-queries.vue';
import ReservationDetails from '../views/ReservationDetails.vue';

import Customers from '../views/Customers.vue';
import CustomerDetails from '../views/CustomerDetails.vue';
import CustomerOrders from '../views/Customers/Orders.vue';
import CustomerReservations from '../views/Customers/Reservations.vue';

import Companies from '../views/Companies.vue';
import CompanyDetails from '../views/CompanyDetails.vue';
import CompanyOrders from '../views/Companies/Orders.vue';

import Forms from '../views/Forms.vue';

import Vouchers from '../views/Vouchers.vue';
import VoucherDetails from '../views/VoucherDetails.vue';
import VoucherCodes from '../views/Vouchers/Codes.vue';

import Gifts from '../views/Gifts.vue';
import GiftsDetails from '../views/GiftsDetail.vue';

import Feedback from '../views/Feedback.vue';
import Financial from '../views/Financial.vue';
import NotFound from '../views/Shared/NotFound.vue';
import PackageDeals from '../views/PackageDeals.vue';
import {authenticationGuard} from "@/auth/authenticationGuard";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/locations',
        name: 'locations',
        component: LocationHome
    },
    {
        path: '/locations/:id',
        component: LocationDetails,
        children: [
            {
                path: '',
                redirect: {name: 'location-rooms'}
            },
            {
                path: 'rooms',
                name: 'location-rooms',
                component: LocationRooms
            },
            {
                path: 'room-combinations',
                name: 'location-room-combinations',
                component: LocationRoomCombinations
            },
            {
                path: 'orders',
                name: 'location-orders',
                component: LocationOrders
            },
            {
                path: 'reservations',
                name: 'location-reservations',
                component: LocationReservations
            },
            {
                path: 'prices',
                name: 'location-prices',
                component: LocationPrices
            },
            {
                path: 'package-deals',
                name: "location-package-deals",
                component: LocationPackageDeals
            },
            {
                path: 'feedback',
                name: "location-feedback",
                component: LocationFeedback
            },
            {
                path: 'forms',
                name: "location-forms",
                component: LocationForms
            },
            {
                path: 'settings',
                name: "location-settings",
                component: LocationSettings
            },
        ]
    },
    {
        path: '/locations/:id/dashboard',
        name: 'location-dashboard',
        component: LocationDashboard
    },
    {
        path: '/orders',
        name: 'orders',
        component: Orders
    },
    {
        path: '/orders/:id',
        component: OrderDetails,
        children: [
            {
                path: '',
                redirect: {name: 'order-reservations'}
            },
            {
                path: 'reservations',
                name: 'order-reservations',
                component: OrderReservations
            },
            {
                path: 'feedback',
                name: 'order-feedback',
                component: OrderFeedback
            },
            {
                path: 'forms',
                name: 'order-forms',
                component: OrderForms
            }
        ]
    },
    {
        path: '/reservations',
        name: 'reservations',
        component: Reservations
    },
    {
        path: '/reservation-query',
        name: 'reservation-query',
        component: ReservationQuery
    },
    {
        path: '/reservations/:id',
        name: 'reservation-details',
        component: ReservationDetails
    },
    {
        path: '/package-deals',
        name: 'package-deals',
        component: PackageDeals
    },
    {
        path: '/customers',
        name: 'customers',
        component: Customers
    },
    {
        path: '/customers/:id',
        component: CustomerDetails,
        children: [
            {
                path: '',
                redirect: {name: 'customer-orders'}
            },
            {
                path: 'orders',
                name: "customer-orders",
                component: CustomerOrders
            },
            {
                path: 'reservations',
                name: 'customer-reservations',
                component: CustomerReservations
            },
        ]
    },
    {
        path: '/companies',
        name: 'companies',
        component: Companies
    },
    {
        path: '/companies/:id',
        component: CompanyDetails,
        children: [
            {
                path: '',
                redirect: {name: 'company-orders'}
            },
            {
                path: 'orders',
                name: "company-orders",
                component: CompanyOrders
            },
        ]
    },
    {
        path: '/forms',
        name: 'forms',
        component: Forms
    },
    {
        path: '/vouchers',
        name: 'vouchers',
        component: Vouchers
    },
    {
        path: '/vouchers/:id',
        component: VoucherDetails,
        children: [
            {
                path: '',
                redirect: {name: 'voucher-codes'}
            },
            {
                path: 'codes',
                name: "voucher-codes",
                component: VoucherCodes
            }
        ]
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: Gifts
    },
    {
        path: '/gifts/:id',
        name: 'gifts-details',
        component: GiftsDetails
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: Feedback
    },
    {
        path: '/financial',
        name: 'financial',
        component: Financial
    },
    {
        path: '/*',
        name: '404',
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {
    if (to.path === '/logout') {
        return true;
    }

    return !!authenticationGuard(to, from, () => {});
})


export default router
