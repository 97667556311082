<template>
    <div class="modal-dialog modal-lg limit-height" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Create Location Price
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>
            <div class="modal-body" v-if="!loading">
                <div class="row">
                    <div class="col-12 row">
                        <div class="col-4 text-left">
                            <label for="package-deal">
                                Package Deal
                            </label>
                        </div>
                        <div class="col">
                            <select id="package-deal" class="form-control" v-model="locationPrice.priceTypeId">
                                <option v-for="pt in priceTypes" :value="pt.Id" :key="pt.Id">
                                    {{ pt.Name }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 row">
                        <div class="col-4 text-left">
                            <label for="published">
                                Published (shown to the customer)
                            </label>
                        </div>
                        <div class="col">
                            <input id="published" type="checkbox" v-model="locationPrice.published">
                        </div>
                    </div>

                </div>

                <div class="row" v-for="(locPrice, idx) in this.locationPrice.priceList"
                     v-bind:key="locPrice.price.people">
                    <div class="col-12 row">
                        <div class="col-4 text-left">
                            <label :for="'days-' + idx">
                                Days
                            </label>
                        </div>
                        <div class="col">
                            <Multiselect v-model="locPrice.selectedDays" mode="tags" valueProp="Id" label="Code" trackBy="Code"
                                         :options="dayTypeList"/>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="col-4 text-left">
                            <label for="participants">
                                # participants
                            </label>
                        </div>
                        <div class="col">
                            <input id="participants" class="form-control" type="text" v-model="locPrice.price.people">
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="col-4 text-left">
                            <label for="amount">
                                Price
                            </label>
                        </div>
                        <div class="col">
                            <input id="amount" class="form-control" type="text" v-model="locPrice.price.amount">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <button type="button" class="btn btn-primary" @click="addRow">
                        Add row
                    </button>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" :disabled="loading" @click="save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Filters from "../mixins/Filters";
import Modal from "../mixins/Modal";
import BookingService from "@/services/booking-service";
import Multiselect from '@vueform/multiselect'

export default {
    name: "create-location-price-modal",
    components: {Multiselect},
    mixins: [Filters, Modal],
    props: ["data"],
    data() {
        return {
            loading: true,
            locationPrice: {
                locationId: null,
                published: true,
                priceTypeId: null,
                priceList: [],
            },
            priceTypes: null,
            dayTypeList: []
        }
    },
    async created() {
        this.priceTypes = await BookingService.GetAsync(`/api/v1/booking/locations/${this.data.locationId}/price-types/list`);
        this.dayTypeList = await BookingService.GetAsync(`/api/v1/booking/day-type/list`);
        this.locationPrice.locationId = this.data.locationId;
        this.locationPrice.priceTypeId = this.priceTypes[0].Id;
        this.loading = false;
    },
    methods: {
        addRow() {
            const dayIds = []
            this.dayTypeList.forEach(day => {
                dayIds.push(day.Id);
            });

            this.locationPrice.priceList.push({
                selectedDays: dayIds,
                price: {
                    amount: 0,
                    people: 0,
                },
            })
        },
        async save() {
            this.loading = true;
            for (const locPrice of this.locationPrice.priceList) {
                const data = {
                    Price: {
                        Amount: parseInt(locPrice.price.amount),
                        People: parseInt(locPrice.price.people),
                    },
                    LocationId: parseInt(this.locationPrice.locationId),
                    Published: this.locationPrice.published,
                    PriceTypeId: this.locationPrice.priceTypeId,
                    DayIds: locPrice.selectedDays
                }

                await BookingService.PostAsync(`/api/v1/booking/locations/${this.locationPrice.locationId}/prices`, data);
            }

            this.emitter.emit("reload-location-prices");
            this.CloseModal();
        }
    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped lang="scss">
    .limit-height {
        max-height: 80%;
        overflow-y: scroll;
        overflow-x: hidden; /* Hide horizontal scrollbar */
    }
</style>
