<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Delete Room Combination
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body">
                <h2 class="text-danger">
                    Are you sure you want to delete this combination?
                </h2>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Delete
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";

    export default {
        name: "edit-room-combination-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                roomCombination: null
            }
        },
        created() {
            this.roomCombination = JSON.parse(JSON.stringify(this.data));
        },
        methods: {
            async Save() {
                const url = `/api/v1/booking/locations/${this.roomCombination.LocationId}/rooms/${this.roomCombination.Room.Id}/combinations/${this.roomCombination.CombinedRoom.Id}`;
                await BookingService.DeleteAsync(url);
                this.emitter.emit("reload-location-room-combinations");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
