<template>
    <div class="mt-4 row">

        <div class="col">
            Total: <strong>{{ pagination.Total }}</strong>
        </div>

        <div class="col">
            <select v-if="pagination.Total > 10" class="form-control" v-model="Limit" @change="GetPage(1)">
                <option :value="10">10</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
                <option v-if="allow500" :value="500">500</option>
            </select>
        </div>

        <div class="col-4">
            <nav v-if="pagination">
                <ul class="pagination justify-content-center" v-if="TotalPages > 1">
                    <li :class="{ disabled: CurrentPage === 1 }" :disabled="CurrentPage == 1" class="page-item" @click="GetPage(1)">
                        <a class="page-link" href="#" tabindex="-1">
                            <i class="material-icons">first_page</i>
                        </a>
                    </li>

                    <li :class="{ disabled: CurrentPage === 1 }" :disabled="CurrentPage == 1" class="page-item" @click="GetPreviousPage()">
                        <a class="page-link" href="#" tabindex="-1">
                            <i class="material-icons">chevron_left</i>
                        </a>
                    </li>

                    <li :class="{ disabled: CurrentPage === TotalPages }" :disabled="CurrentPage == TotalPages" class="page-item" @click="GetNextPage()">
                        <a class="page-link" href="#">
                            <i class="material-icons">chevron_right</i>
                        </a>
                    </li>

                    <li :class="{ disabled: CurrentPage === TotalPages }" :disabled="CurrentPage == TotalPages" class="page-item" @click="GetPage(TotalPages)">
                        <a class="page-link" href="#">
                            <i class="material-icons">last_page</i>
                        </a>
                    </li>

                </ul>
            </nav>
        </div>

        <div class="col">
            {{CurrentPage}} / {{TotalPages}}
        </div>

    </div>

</template>

<script>
    export default {
        name: "pagination",
        props: ['pagination', 'allow500'],
        data: function () {
            return {
                Limit: 10,
                Offset: 0
            }
        },
        computed: {
            TotalPages() {
                if (this.pagination.Total <= 0) {
                    return 1;
                }
                if (this.pagination.Limit === 0) {
                    return 1;
                }
                return Math.ceil(this.pagination.Total / this.pagination.Limit);
            },
            CurrentPage() {
                if (this.pagination.Offset <= 0) {
                    return 1;
                }
                return Math.ceil(this.pagination.Offset / this.pagination.Limit) + 1
            }
        },
        methods: {
            GetNextPage() {
                if (this.CurrentPage === this.TotalPages) {
                    return;
                }
                this.GetPage(this.CurrentPage + 1);
            },
            GetPreviousPage() {
                if (this.CurrentPage === 1) {
                    return;
                }
                this.GetPage(this.CurrentPage - 1);
            },
            GetPage(pageNumber) {
                this.Offset = (pageNumber - 1) * this.Limit;
                this.$emit("get-page", this.Limit, this.Offset);
            }
        }
    }
</script>

<style scoped>

</style>
