import store from '@/store';
import mitt from 'mitt';

const emitter = mitt();
import {NavigationGuard} from "vue-router";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const authenticationGuard: NavigationGuard = (_to, _from, _next): boolean => {
    if (store.state.isAuthenticated) {
        return true
    }

    emitter.emit("login", {
        redirect_uri: window.location.origin,
        audience: "https://booking-api.lenimal.nl",
    })
    return false;
};
