<template>
    <div id="app">
        <notification-component></notification-component>
        <modal-component/>

        <div id="nav">
            <navigation/>
        </div>
        <router-view/>
    </div>
</template>

<script>
import Navigation from "@/components/Shared/Navigation";
import ModalComponent from "@/components/Shared/ModalComponent";
import NotificationComponent from "@/components/Shared/Notification";

export default {
    components: {
        NotificationComponent,
        ModalComponent,
        Navigation
    },
    computed: {
        isAuthenticated() {
            return this.$auth0.isAuthenticated.value
        }
    },
    data() {
        return {
            ShowBackdrop: false
        }
    },
    watch: {
        async isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                const token = await this.$auth0.getAccessTokenSilently()
                this.$store.dispatch("setAuthToken", token);
                this.$store.dispatch("setUser", this.$auth0.user.value);
            }
        }
    },
    async created() {
        this.emitter.on("login", this.login)
        await this.$store.dispatch("checkLogin");
    },
    methods: {
        async login(params) {
            return await this.$auth0.loginWithRedirect(params);
        }
    }
}
</script>

<style lang="scss">
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
