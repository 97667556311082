<template>
    <div class="modal-open">
        <div class="row" v-if="linkedLocationOrders && linkedLocationOrders.length > 0">
            <div class="col-md-12 p-1 mb-2 badge-danger">
                The linked location has {{linkedLocationOrders.length}} orders on {{currentDate}}.
                <a :href="'/locations/' + location.LinkedLocationId + '/dashboard?date=' + currentDate">
                    Open location
                </a>
            </div>
        </div>
        <div class="row">
            <calendar v-if="initialDate != null" class="col-8" @load="LoadData" :initialDate="initialDate"></calendar>
            <div class="col-4 row" v-if="!isRoomSelectionEnabled">
                <button class="btn btn-info mr-2 col" @click="MultiRoomBlock">
                    Select Rooms
                </button>
                <button class="btn btn-secondary col" @click="ToggleRooms">
                    Toggle Rooms ({{ OtherRoomsBookings }})
                </button>
            </div>
            <div class="col-4 row" v-else>
                <button class="btn btn-danger mr-2 col" @click="MultiRoomBlock">
                    Cancel
                </button>
                <button class="btn btn-info mr-2 col" @click="BlockSelectedRooms">
                    Block selected rooms
                </button>
            </div>

        </div>

        <section class="container-fluid" v-if="!Loading">

            <div class="row">
                <div class="col" v-for="room in CurrentRooms" :key="room.Id">
                    {{ room.Name }}
                </div>
            </div>

            <div class="row">
                <div class="col p-0" v-for="room in CurrentRooms" :key="room.Id">
                    <ul class="list-group" style="width: 96%; margin: 0 auto;">
                        <slot-card v-for="slot in OrderedSlots(room.Slots)" :key="slot.Id" :item="slot"
                                   :date="currentDate"
                                   @reload="LoadData"
                                   @edit-order="OpenEditOrderModal"
                                   @remove-state="RemoveState"
                                   @open-slot="OpenSlot"
                                   @publish-slot="PublishSlot"
                                   @block-slot="BlockSlot"/>
                    </ul>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import _ from 'lodash';
import BookingService from '../services/booking-service';
import Filters from "@/mixins/Filters";
import Calendar from "@/components/Location/Dashboard/Calendar";
import moment from "moment";
import SlotCard from "@/components/Location/Dashboard/SlotCard";

export default {
    name: "location-dashboard",
    components: {SlotCard, Calendar},
    mixins: [Filters],
    data() {
        return {
            LocationOverview: null,
            initialDate: null,
            location: null,
            linkedLocationOrders: [],
            Date: moment().format("YYYY-MM-DD"),
            Loading: true,
            ShownRooms: null,
            ShowFrom: 0,
            ShowTo: 6,
            SelectedSlot: null,
            selectedState: null,
        };
    },
    async created() {
        if (this.$route.query.date != null) {
            this.initialDate = moment(this.$route.query.date).format("YYYY-MM-DD")
        } else {
            this.initialDate = moment().format("YYYY-MM-DD")
        }
        this.emitter.on('reload-overview', this.LoadData);

        const url = `/api/v1/booking/locations/${this.$route.params.id}`
        this.location = await BookingService.GetAsync(url)

        await this.LoadData(this.initialDate);
    },
    computed: {
        isRoomSelectionEnabled() {
            return this.$store.state.isRoomSelectionEnabled
        },
        CurrentRooms() {
            if (!this.LocationOverview) {
                return []
            }

            return (_.sortBy(this.LocationOverview.RoomOverview, "SequenceNumber")).slice(this.ShowFrom, this.ShowTo)
        },
        OtherRoomsBookings() {
            if (!this.LocationOverview) {
                return 0
            }
            let count = 0;

            let otherRooms = null;
            if (this.ShowFrom === 0) {
                otherRooms = this.LocationOverview.RoomOverview.slice(this.ShowTo, this.LocationOverview.RoomOverview.length);
            } else {
                otherRooms = this.LocationOverview.RoomOverview.slice(0, this.ShowFrom);
            }

            otherRooms.forEach(room => {
                room.Slots.forEach(slot => {
                    if (slot.IsBooked) {
                        count++;
                    }
                })
            });

            return count;
        },
        currentDate() {
            return moment(this.Date).format("YYYY-MM-DD")
        }
    },
    methods: {
        ToggleRooms() {
            if (this.ShowFrom === 0) {
                this.ShowFrom = this.ShowTo;
                this.ShowTo = this.LocationOverview.RoomOverview.length;
            } else {
                this.ShowFrom = 0;
                this.ShowTo = 6
            }
        },
        MultiRoomBlock() {
            this.$store.dispatch("enableRoomSelection")
        },
        OpenEditOrderModal(slot) {
            slot.LocationId = this.$route.params.id;
            slot.Date = this.currentDate;
            this.emitter.emit("open-modal", {type: "EditOrderModal", data: slot})
        },
        OrderedSlots(value) {
            return _.orderBy(value, ["StartHour", "StartMinute"])
        },
        async loadLinkedLocationData(date) {
            if (this.location.LinkedLocationId) {
                const url = `/api/v1/booking/orders/0/order-rows`
                const linkedOrders = await BookingService.GetAsync(url, {
                    LocationIds: [this.location.LinkedLocationId],
                    SelectedDateQuery: "BookingDate",
                    Gte: date,
                    Lte: date
                })

                this.linkedLocationOrders = linkedOrders.Results
            }
        },
        async LoadData(newDate) {
            this.linkedLocationOrders = []
            if (!newDate) {
                newDate = this.currentDate
            }
            const url = `/api/v1/booking/locations/${this.$route.params.id}/overview`;

            this.Loading = true;
            this.LocationOverview = await BookingService.GetAsync(url, {date: newDate})
            this.Date = newDate;
            this.Loading = false;

            this.$store.dispatch("resetRoomSelection")

            await this.loadLinkedLocationData(newDate)
        },
        BlockSelectedRooms() {
            const data = {
                date: this.currentDate,
                slotIds: this.$store.state.disabledSlots
            }
            this.emitter.emit("open-modal", {type: "BlockSlotModal", data})
        },
        BlockSlot(slot) {
            const data = {
                date: this.currentDate,
                slotIds: [slot.Id]
            }
            this.emitter.emit("open-modal", {type: "BlockSlotModal", data})
        },
        async RemoveState(slot) {
            await BookingService.DeleteAsync(`/api/v1/booking/slots/${slot.Id}/status/${slot.SlotStatusTypeId}`, {date: this.currentDate});
            await this.LoadData();
        },
        async OpenSlot(slot) {
            const data = {
                StatusId: 1,
                Date: this.currentDate
            };
            await BookingService.PostAsync(`/api/v1/booking/slots/${slot.Id}/status`, data);
            await this.LoadData();
        },
        async PublishSlot(slot) {
            const data = {
                StatusId: 4,
                Date: this.currentDate
            };
            await BookingService.PostAsync(`/api/v1/booking/slots/${slot.Id}/status`, data);
            await this.LoadData();
        }
    },
}
</script>

<style scoped lang="scss">
.tabs {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid;

    a {
        color: black;
    }
}
</style>
