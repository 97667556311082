<template>
    <div v-if="location">
        <div class="row">
            <div class="col-10">
                <h2 class="text-left">
                    {{location.DisplayName}}
                </h2>
            </div>
            <div class="col">
                <button @click="EditLocationModal" class="btn btn-secondary material-icons">
                    edit
                </button>
            </div>
        </div>

        <div class="information">
            <div class="information__list">
                <dl class="grid-data-list data-list">
                    <dt>
                        Code
                    </dt>
                    <dd>
                        {{location.Name}}
                    </dd>
                    <dt>
                        Bookings created this month
                    </dt>
                    <dd>
                        {{location.MonthOrders}}
                    </dd>
                    <dt>
                        Bookings created this week
                    </dt>
                    <dd>
                        {{location.WeekOrders}}
                    </dd>
                    <dt>
                        Bookings created today
                    </dt>
                    <dd>
                        {{location.DayOrders}}
                    </dd>
                    <dt>
                        Bookings today
                    </dt>
                    <dd>
                        {{location.OrdersToday}}
                    </dd>
                    <dt>
                        Published
                    </dt>
                    <dd>
                        <span class="badge" :class="{'badge-success' : location.Published, 'badge-danger' : !location.Published}">
                            {{location.Published ? "Ja" : "nee"}}
                        </span>
                    </dd>
                    <dt>
                        Allows Gifts
                    </dt>
                    <dd>
                        <span class="badge" :class="{'badge-success' : location.AllowGifts, 'badge-danger' : !location.AllowGifts}">
                            {{location.AllowGifts ? "Ja" : "nee"}}
                        </span>
                    </dd>
                </dl>
            </div>
        </div>

        <section>
            <div class="modal fade show" v-if="Modals.EditOrderModal" role="dialog">
                <edit-order-modal :selected-slot="SelectedSlot"/>
            </div>
            <div class="modal fade show" v-if="Modals.BlockSlotModal" role="dialog">
                <block-slot-modal :selected-state="selectedState"/>
            </div>
        </section>

    </div>
</template>

<script>
    export default {
        name: "location-information",
        props: ["location"],
        data() {
            return {
                Modals: {
                    EditLocationModal: false
                }
            }
        },
        methods: {
            EditLocationModal() {
                this.emitter.emit("open-modal", {type: "EditLocationModal", data: this.location})
            }
        }
    }
</script>

<style scoped lang="scss">
    .information {
        background-color: #c4c4c4;
        border-radius: 3px;
        padding: 20px;
    }
</style>
