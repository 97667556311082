<template>
    <div>
        <div class="row">
            <div class="col-4 text-left">
                Personal Voucher
            </div>
            <div class="col-8 text-left">
                <input type="checkbox" v-model="IsPersonalVoucher">
            </div>
        </div>
        <div class="row" v-if="!IsPersonalVoucher">
            <div class="col-4 text-left">
                <label for="select-voucher">
                    Voucher
                </label>
            </div>
            <div class="col-8">
                <select class="form-control" id="select-voucher" @change="voucherChanged($event)">
                    <option :value="null">No Voucher</option>
                    <option v-for="voucher in VoucherList" :key="voucher.Id" :value="voucher.Code">
                        {{ voucher.Code }} ({{ voucher.Amount }}
                        <span v-if="!voucher.IsPercentage">&euro;</span><span v-else>&percnt;</span>)
                    </option>
                </select>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-4 text-left">
                <label for="voucher-code">
                    Voucher
                </label>
            </div>
            <div class="col-6">
                <input id="voucher-code" type="text" class="form-control" v-model="VoucherCode">
            </div>
            <div class="col-2">
                <button class="btn btn-secondary" @click="CheckVoucher">
                    Check
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-4 text-left">
                Total Price
            </div>
            <div class="col-8 text-left">
                {{ TotalPrice }}.- &euro;
            </div>
        </div>
        <div class="row">
            <label for="set-custom-price" class="col-4 text-left">
                Set custom Price?
            </label>
            <div class="col-8 text-left">
                <input id="set-custom-price" type="checkbox" v-model="order.CustomPriceSet" @change="resetCustomPrice">
            </div>
        </div>
        <div class="row" v-if="order.CustomPriceSet">
            <label for="custom-price" class="col-4 text-left">
                New total price
            </label>
            <div class="col-8 text-left">
                <input class="form-control" id="custom-price" type="text" v-model="order.Amount">
            </div>
        </div>
        <div class="row">
            <div class="col-4 text-left">
                <label for="amount-paid">
                    Amount Paid
                </label>
            </div>
            <div class="col-8 text-left">
                <input type="text" class="form-control" id="amount-paid" v-model="order.AmountPaid">
            </div>
        </div>

        <div class="row">
            <div class="col-4 text-left">
                <label for="administration-amount">
                    Administration costs <i>[used in invoice]</i>
                </label>
            </div>
            <div class="col-8 text-left">
                <input type="text" class="form-control" id="administration-amount" v-model="order.AdministrationAmount">
            </div>
        </div>


    </div>
</template>

<script>
import bookingApi from '../../services/booking-service'
import Filters from "@/mixins/Filters";

export default {
    name: "edit-order-payment-component",
    props: ["order", "locationId"],
    mixins: [Filters],
    computed: {
        TotalPrice() {
            if (!this.order) {
                return "-";
            }

            let total = 0;

            this.order.OrderRows.forEach(row => {
                if (row.PriceId && row.PriceList) {
                    total += this.GetPriceForPriceId(row.PriceList, row.PriceId);
                }
            });

            if (this.order.Voucher) {
                let voucherAmount = 0

                if (!this.order.Voucher.VoucherBatch.IsPercentage) {
                    voucherAmount = this.order.Voucher.VoucherBatch.Amount
                } else {
                    voucherAmount = Math.round(total * (this.order.Voucher.VoucherBatch.Amount / 100));
                }
                total -= voucherAmount;
                }

            return total;
        }
    },
    data() {
        return {
            VoucherList: [],
            VoucherCode: "",
            IsPersonalVoucher: false,
            SetCustomPrice: false,
        }
    },
    async created() {
        await this.LoadVouchers();
    },
    methods: {
        GetPriceForPriceId(priceList, priceId) {
            let priceAmount = 0;
            priceList.forEach(price => {
                if (price.Price.Id === priceId) {
                    priceAmount = price.Price.Amount;
                }
            });
            return priceAmount;
        },
        async LoadVouchers() {
            if (this.order.Voucher) {
                this.order.VoucherCode = this.order.Voucher.Code;
            }
            this.VoucherList = await bookingApi.GetAsync("/api/v1/booking/voucher-batches/list");
        },
        voucherChanged(evt) {
            const foundVoucher = this.VoucherList.find(v => v.Code === evt.target.value);
            this.order.VoucherCode = null;
            this.order.Voucher = null;
            if (foundVoucher && !foundVoucher.IsGeneric) {
                this.emitter.emit("notification", {
                    title: "error",
                    timestamp: "now",
                    body: "Voucher is not Generic, please enter full code"
                });
                this.IsPersonalVoucher = true;
                return;
            }

            if (foundVoucher && foundVoucher.IsGeneric) {
                this.order.VoucherCode = foundVoucher.Code;
                this.order.Voucher = {
                    Code: foundVoucher.Code,
                    VoucherBatch: {
                        Amount: foundVoucher.Amount,
                        IsPercentage: foundVoucher.IsPercentage,
                        IsSecondRoomsHalfPrice: foundVoucher.IsSecondRoomsHalfPrice,
                    }
                }
            }

        },
        resetCustomPrice() {
            this.order.Amount = null;
        },
        async CheckVoucher() {
            this.VoucherCode = this.VoucherCode.trim();
            const dates = [];
            const roomIds = [];
            console.log(this.order);

            this.order.OrderRows.forEach(row => {
                dates.push(row.Date)
                roomIds.push(row.RoomId)
            })
            const request = {
                VoucherCode: this.VoucherCode,
                LocationId: this.locationId,
                BookingDates: dates,
                RoomIds: roomIds
            };
            const verifiedVoucher = await bookingApi.PostAsync("/api/v1/booking/vouchers/verify", request);

            if (!verifiedVoucher) {
                this.emitter.emit("notification", {
                    title: "error",
                    timestamp: "now",
                    body: "Voucher Not Verified"
                });
                return;
            }
            this.emitter.emit("notification", {
                title: "success",
                timestamp: "now",
                body: "Voucher Valid!"
            });

            this.order.VoucherCode = this.VoucherCode;
            this.order.Voucher = {
                Code: this.VoucherCode,
                VoucherBatch: {
                    Amount: verifiedVoucher.Amount,
                    IsPercentage: verifiedVoucher.IsPercentage,
                    IsSecondRoomsHalfPrice: verifiedVoucher.IsSecondRoomsHalfPrice,
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
