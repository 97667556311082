<template>
    <div>
        <h2>
            Orders
        </h2>

        <div class="row pl-4">
            <div class="col">
                From
                <input class="form-control" type="date" v-model="Gte">
            </div>

            <div class="col">
                To
                <input class="form-control" type="date" v-model="Lte">
            </div>
        </div>

        <div class="row p-4">
            <div class="col">
                <select class="form-control" v-model="SelectedLocation">
                    <option :value="null">
                        All Locations
                    </option>
                    <option v-for="loc in Locations" :key="loc.Id" :value="loc.Id">
                        {{loc.DisplayName}}
                    </option>

                </select>

            </div>
            <div class="col">
                <select class="form-control" v-model="SelectedStatus">
                    <option :value="null">
                        All status
                    </option>
                    <option v-for="os in OrderStatuses" :key="os.Id" :value="os.Id">
                        {{os.Name}}
                    </option>

                </select>
            </div>
            <div class="col">
                <select class="form-control" v-model="SelectedVoucherType">
                    <option :value="null">
                        All voucher types
                    </option>

                    <option v-for="vbt in VoucherBatchTypes" :key="vbt.Id" :value="vbt.Id">
                        {{vbt.Description}}
                    </option>

                </select>
            </div>
            <div class="col">
                <select class="form-control" v-model="SelectedVoucherBatch">
                    <option :value="null">
                        All vouchers
                    </option>

                    <option v-for="vb in VoucherBatches" :key="vb.Id" :value="vb.Id">
                        <span v-if="vb.Description">
                            {{vb.Description}}
                        </span>
                        <span v-else>
                            {{vb.Code}}
                        </span>
                        ({{vb.Amount}} <span v-if="vb.IsPercentage">%</span><span v-if="!vb.IsPercentage">&euro;</span>)
                    </option>

                </select>
            </div>

            <div class="col">
                <select class="form-control" v-model="isCompany">
                    <option :value="null">
                       All Companies
                    </option>

                    <option :value="true">
                       Only Companies
                    </option>
                    <option :value="false">
                       Exclude Companies
                    </option>

                </select>
            </div>

            <div class="col">
                <button class="btn btn-primary" @click="Search">
                    Search
                </button>
            </div>
        </div>

        <auto-table @table-click="SelectItem" :pagination="Pagination" :columns="Columns" :loading="Loading" :settings="Settings"/>
        <div v-if="Pagination.Results">
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import Pagination from "../components/Shared/Pagination";
    import TableView from "../mixins/TableView";
    import AutoTable from "../components/Shared/Table";
    import api from "../services/booking-service";
    import moment from "moment";

    export default {
        name: "location",
        mixins: [TableView],
        components: {AutoTable, Pagination},
        data() {
            return {
                Settings: {
                    Info: 'OrderStatusId',
                    InfoValues: [2],
                    Warning: 'OrderStatusId',
                    WarningValues: [5, 6]
                },
                Pagination: {
                    OrderBy: "CreatedAt",
                    Limit: 10,
                    Offset: 0,
                    OrderByDesc: false,
                },
                Url: `/api/v1/booking/orders`,
                SelectedLocation: null,
                SelectedVoucherType: null,
                SelectedVoucherBatch: null,
                isCompany: null,
                SelectedStatus: null,
                Gte: moment().add(-14, "d").format("YYYY-MM-DD"),
                Lte: moment().format("YYYY-MM-DD"),
                Locations: [],
                VoucherBatchTypes: [],
                OrderStatuses: [],
                VoucherBatches: [],
                Columns: [
                    {
                        Title: "Location",
                        Sortable: true,
                        Type: "COMBINED",
                        Class: "Location",
                        Attribute: "DisplayName",
                        SortValue: "LocationId"
                    },
                    {
                        Title: "Bookings Date",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "CreatedAt"
                    },

                    {
                        Title: "Price",
                        Sortable: true,
                        Type: "VALUTA",
                        Attribute: "Amount"
                    },
                    {
                        Title: "Paid",
                        Sortable: true,
                        Type: "VALUTA",
                        Attribute: "AmountPaid"
                    },
                    {
                        Title: "Remaining",
                        Sortable: true,
                        Type: "VALUTA",
                        Attribute: "AmountDue"
                    },
                    {
                        Title: "# Rooms",
                        Sortable: false,
                        Type: "COUNT",
                        Attribute: "OrderRows"
                    },
                    {
                        Title: "Status",
                        Sortable: true,
                        Type: "COMBINED",
                        Class: "OrderStatus",
                        Attribute: "Name",
                        SortValue: "OrderStatusId"
                    },
                    {
                        Title: "Voucher",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "Voucher",
                        Attribute: "Code"
                    },
                    {
                        Title: "Voucher Type",
                        Sortable: true,
                        Type: "FUNCTION",
                        Attribute: "Voucher",
                        Func(i) {
                            if (i && i.VoucherBatch && i.VoucherBatch.VoucherBatchType) {
                                return i.VoucherBatch.VoucherBatchType.Name;
                            }
                            return "";
                        }
                    }
                ],
                Modal: {
                    CreateOrderModal: false,
                },
            }
        },
        created() {
            Promise.all([
                api.GetAsync("/api/v1/booking/locations/list"),
                api.GetAsync("/api/v1/booking/voucher-batch-type/list"),
                api.GetAsync("/api/v1/booking/order-status/list"),
                api.GetAsync("/api/v1/booking/voucher-batches/list?voucherBatchType=-10"),
            ])
                .then(res => {
                    this.Locations = res[0];
                    this.VoucherBatchTypes = res[1];
                    this.OrderStatuses = res[2];
                    this.VoucherBatches = res[3];
                });

            this.Search();
        },
        methods: {
            Search() {
                if (this.SelectedLocation) {
                    this.Pagination["LocationIds"] = [this.SelectedLocation];
                } else {
                    delete this.Pagination["LocationIds"]
                }

                if (this.SelectedVoucherType) {
                    this.Pagination["VoucherTypeIds"] = [this.SelectedVoucherType];
                } else {
                    delete this.Pagination["VoucherTypeIds"]
                }

                if (this.SelectedStatus) {
                    this.Pagination["OrderStatusIds"] = [this.SelectedStatus];
                } else {
                    delete this.Pagination["OrderStatusIds"]
                }

                if (this.SelectedVoucherBatch) {
                    this.Pagination["VoucherBatchIds"] = [this.SelectedVoucherBatch];
                } else {
                    delete this.Pagination["VoucherBatchIds"]
                }

                if (this.Gte) {
                    this.Pagination["Gte"] = moment(this.Gte).format("YYYY-MM-DD");
                } else {
                    delete this.Pagination["Gte"]
                }

                if (this.Lte) {
                    this.Pagination["Lte"] = moment(this.Lte).format("YYYY-MM-DD");
                } else {
                    delete this.Pagination["Lte"]
                }

                if (this.isCompany != null) {
                    this.Pagination["IsCompany"] = this.isCompany
                } else {
                    delete this.Pagination["IsCompany"]
                }

                this.GetPage();
            },
            SelectItem(item) {
                this.$router.push(`/orders/${item.Id}/reservations`);
            }
        }
    }
</script>

<style scoped>

</style>
