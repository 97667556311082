<template>

    <section class="row" v-if="pendingConfirmationOrders.length > 0">
        <div class="col-12">
            <h5>Package deals pending confirmation</h5>
        </div>

        <div class="col-12">
            <table class="table table-borderless">
                <thead>
                <tr>
                    <th>Created</th>
                    <th>Location</th>
                    <th>Customer</th>
                    <th>Tel.</th>
                    <th>Package</th>
                    <th>Date</th>
                    <th>Package Start</th>
                    <th>Details</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="pendingOrder in pendingConfirmationOrders" v-bind:key="pendingOrder.OrderId">
                    <td>{{ useFormatLongTime(pendingOrder.CreatedAt) }}</td>
                    <td>{{ pendingOrder.LocationName }}</td>
                    <td>{{ pendingOrder.CustomerEmail }}</td>
                    <td>{{ pendingOrder.CustomerTel }}</td>
                    <td>{{ pendingOrder.PackageDealName }}</td>
                    <td>{{ useFormatDate(pendingOrder.BookingDate) }}</td>
                    <td>{{ pendingOrder.PackageDealStartTime }}</td>
                    <td>{{ transformRoomDetails(pendingOrder.RoomDetails) }}</td>
                    <td>
                        <button class="btn btn-success material-icons btn-margin"
                                @click="acceptPendingOrder(pendingOrder.OrderKey)">check
                        </button>
                        <button class="btn btn-danger material-icons btn-margin"
                                @click="declinePendingOrder(pendingOrder.OrderKey)">clear
                        </button>
                        <button class="btn btn-secondary material-icons btn-margin"
                                @click="goToOrder(pendingOrder.OrderId)">info
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
    </section>

</template>

<script>

import BookingService from "@/services/booking-service";
import moment from "moment";
import Filters from "@/mixins/Filters";

export default {
    name: "home-pending-confirmations",
    mixins: [Filters],
    data() {
        return {
            packageDeals: [],
            pendingConfirmationOrders: []
        }
    },
    async created() {
        await this.loadData()
    },
    methods: {
        async loadData() {
            this.pendingConfirmationOrders = await BookingService.GetAsync(`/api/v1/booking/dashboard/pending`)
        },
        async declinePendingOrder(orderKey) {
            return await this.makeCall(orderKey, 'decline')
        },
        async acceptPendingOrder(orderKey) {
            return await this.makeCall(orderKey, 'accept')
        },
        async makeCall(orderKey, state) {
            try {
                await BookingService.PostAsync(`/api/v1/booking/orders/${orderKey}/package-deals/${state}`, {})
            } catch (error) {
                this.emitter.emit("notification", {
                    title: "error",
                    timestamp: moment().format("hh:mm:ss"),
                    body: `Could not ${state} order at this time: ${error}`
                });
            }
            await this.loadData()
        },
        goToOrder(id) {
            this.$router.push(`/orders/${id}`)
        },
        transformRoomDetails(rows) {
            let detailRow = ''

            rows.forEach(row => {
                detailRow += `${row.RoomName} (${row.StartTime} / #${row.NumberOfParticipants})\n`
            })

            return detailRow
        }
    }
}
</script>

<style scoped lang="scss">
.btn-margin {
    margin: 0 2px;
}
</style>
