<template>
    <div>
        <h2>Forms</h2>
        <div class="mt-4">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import AutoTable from "../../components/Shared/Table";
    import TableView from "../../mixins/TableView";
    import Pagination from "../../components/Shared/Pagination";

    export default {
        name: "",
        components: {Pagination, AutoTable},
        mixins: [TableView],
        data() {
            return {
                Loading: true,
                Url: `/api/v1/booking/orders/${this.$route.params.id}/forms`,
                Columns: [
                    {
                        Title: "Groep type",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "Type"
                    },
                    {
                        Title: "Reference",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "Reference"
                    },
                    {
                        Title: "PostCode",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "PostCode"
                    },
                    {
                        Title: "x Escape Room",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "TypeExperience"
                    },
                    {
                        Title: "x Escape World",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "Experience"
                    },
                    {
                        Title: "AddToMailingList",
                        Sortable: false,
                        Type: "BADGE",
                        T: "Yes",
                        F: "No",
                        Attribute: "AddToMailingList"
                    },
                ]
            }
        },
        created() {
            this.GetPage();
        },
        methods: {
            SelectItem() {

            },
            OpenModal() {

            }
        }
    }
</script>

<style scoped>

</style>
