<template>
    <section v-if="voucherBatch">
        <div class="row">
            <div class="col-10">
                <h2 class="text-left">
                    {{voucherBatch.Code}}
                </h2>
            </div>
            <div class="col">
                <button @click="EditVoucherModal" class="btn btn-secondary material-icons">
                    edit
                </button>
            </div>
        </div>

        <div class="information">
            <div class="information__list">
                <dl class="grid-data-list data-list">
                    <dt>
                        Status
                    </dt>
                    <dd>
                        {{ voucherBatch.VoucherBatchStatus.Name }}
                    </dd>

                    <dt>
                        System
                    </dt>
                    <dd>
                        {{ voucherBatch.Client.Name }}
                    </dd>

                    <dt>
                        Code
                    </dt>
                    <dd>
                        {{ voucherBatch.Code }}
                    </dd>

                    <dt>
                        Discount
                    </dt>
                    <dd>
                        {{ voucherBatch.Amount }}
                        <span v-if="voucherBatch.IsPercentage">
                            %
                        </span>
                        <span v-else>
                            &euro;
                        </span>
                    </dd>
                    <dt>
                        From
                    </dt>
                    <dd>
                        {{ useFormatDate(voucherBatch.From) }}
                    </dd>
                    <dt>
                        To
                    </dt>
                    <dd>
                        {{ useFormatDate(voucherBatch.To) }}
                    </dd>
                    <dt>
                        Minimum Rooms
                    </dt>
                    <dd>
                        {{ voucherBatch.MinimumSelectedRooms}}
                    </dd>
                    <template v-if="voucherBatch.LocationId">
                        <dt>
                            LocationId
                        </dt>
                        <dd>
                            <a :href=" '/location-management/' + voucherBatch.LocationId">
                                {{ voucherBatch.LocationId }}
                            </a>
                        </dd>
                    </template>
                    <template v-if="voucherBatch.RoomId">
                        <dt>
                            Room
                        </dt>
                        <dd>
                            <a :href=" '/room-management/' + voucherBatch.RoomId ">
                                {{ voucherBatch.RoomId }}
                            </a>
                        </dd>
                    </template>

                </dl>
            </div>
        </div>
    </section>

</template>

<script>
    import Filters from "../../mixins/Filters";

    export default {
        name: "voucher-information",
        props: ["voucherBatch"],
        mixins: [Filters],
        methods: {
            EditVoucherModal() {
                this.emitter.emit("open-modal", {type: "SetVoucherStateModal", data: this.voucherBatch})
            }
        }
    }
</script>

<style scoped lang="scss">
    .information {
        background-color: #c4c4c4;
        border-radius: 3px;
        padding: 20px;
    }
</style>
