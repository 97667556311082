<template>
    <div v-if="!Loading">
        <h2>
            Feedback
        </h2>

        <div class="row pl-4">
            <div class="col-3">
                <label>
                    From
                </label>
                <input class="form-control" type="date" v-model="Gte">
            </div>
            <div class="col-3">
                <label>
                    To (included)
                </label>
                <input class="form-control" type="date" v-model="Lte">
            </div>
            <div class="col-2">
                <label>
                    Locations
                </label>
                <select class="form-control" v-model="SelectedLocation">
                    <option :value="null">
                        All Locations
                    </option>
                    <option v-for="loc in Locations" :key="loc.Id" :value="loc.Id">
                        {{ loc.DisplayName }}
                    </option>

                </select>
            </div>
            <div class="offset-1 col-2">
                <label style="display: block">
                    &nbsp;
                </label><br>
                <button class="btn btn-primary" @click="Search">
                    Search
                </button>
            </div>
        </div>

        <div class="row p-2" v-if="FeedbackState">
            <div class="col-6">
                <h5>
                    States
                </h5>

                <table class="table">
                    <tr v-for="(v, k) of FeedbackState.RateCounterDictionary" :key="k">
                        <td>
                            # {{ k }} ster feedback
                        </td>
                        <td>
                            <strong>{{ v }}</strong>
                        </td>
                        <td v-if="Pagination && Pagination.Total > 0">
                            {{ useFloor((v / Pagination.Total) * 100) }} %
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total
                        </td>
                        <td>
                            <strong>{{ Pagination.Total }}</strong>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="mt-4">
            <auto-table @table-click="SelectItem" :pagination="Pagination" :columns="Columns" :loading="Loading"/>
            <pagination :pagination="Pagination" @get-page="Paginate"/>
        </div>
    </div>
</template>

<script>
import AutoTable from "../components/Shared/Table";
import Pagination from "../components/Shared/Pagination";
import TableView from "../mixins/TableView";
import api from "../services/booking-service";
import moment from "moment";
import filters from "@/mixins/Filters";

export default {
    name: "feedback",
    mixins: [filters, TableView],
    components: {Pagination, AutoTable},
    data() {
        return {
            Pagination: {
                OrderBy: "CreatedAt",
                Limit: 50,
                Offset: 0,
                OrderByDesc: true
            },
            FeedbackState: null,
            Url: `/api/v1/booking/feedback`,
            SelectedLocation: null,
            Gte: moment().subtract(14, 'd').format("YYYY-MM-DD"),
            Lte: moment().format("YYYY-MM-DD"),
            Locations: [],
            Columns: [
                {
                    Title: "Datum",
                    Sortable: true,
                    Type: "DATE",
                    Attribute: "CreatedAt"
                },
                {
                    Title: "Rooms",
                    Sortable: false,
                    Type: "FUNCTION",
                    Attribute: "Order",
                    Func: (order) => {
                        console.log(order);
                        const val = [];
                        if (order.OrderRows && order.OrderRows.length > 0) {
                            order.OrderRows.forEach(row => {
                                val.push(row.Room.Name)
                            })
                        }
                        return val.join(", ");
                    }
                },
                {
                    Title: "Score (op 5)",
                    Sortable: true,
                    Type: "TEXT",
                    Attribute: "Score"
                },
                {
                    Title: "Kamer",
                    Sortable: true,
                    Type: "TEXT",
                    Attribute: "RoomFeedback"
                },
                {
                    Title: "Gamemaster",
                    Sortable: true,
                    Type: "TEXT",
                    Attribute: "GameMasterFeedback"
                },
                {
                    Title: "Ontvangst",
                    Sortable: true,
                    Type: "TEXT",
                    Attribute: "WelcomeFeedback"
                },
                {
                    Title: "Verbeter punten",
                    Sortable: true,
                    Type: "TEXT",
                    Attribute: "ImprovementText"
                },
                {
                    Title: "Verwachtingen",
                    Sortable: true,
                    Type: "TEXT",
                    Attribute: "ExpectationFeedback"
                },
                {
                    Title: "Order",
                    Sortable: false,
                    Type: "COMBINED",
                    Class: "Order",
                    Attribute: "Code"
                },
            ],
            Modal: {},
        }
    },
    created() {
        api.GetAsync("/api/v1/booking/locations/list")
            .then(res => {
                this.Locations = res;
            });

        this.Search();
    },

    methods: {
        SelectItem(feedback) {
            this.$router.push(`/orders/${feedback.OrderId}/feedback`)
        },
        LoadStates() {
            api.GetAsync("/api/v1/booking/feedback/states", this.Pagination)
                .then(res => {
                    this.FeedbackState = res;
                });
        },
        Search() {
            if (this.SelectedLocation) {
                this.Pagination["LocationIds"] = [this.SelectedLocation];
            } else {
                delete this.Pagination["LocationIds"]
            }

            if (this.Gte) {
                this.Pagination["Gte"] = moment(this.Gte).format("YYYY-MM-DD");
            } else {
                delete this.Pagination["Gte"]
            }

            if (this.Lte) {
                this.Pagination["Lte"] = moment(this.Lte).format("YYYY-MM-DD");
            } else {
                delete this.Pagination["Lte"]
            }

            this.GetPage();
            this.LoadStates();
        }
    }
}
</script>

<style scoped>

</style>
