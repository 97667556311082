<template>
    <div>

        <div class="row">
            <label class="col-4 text-left text-success" for="firstname">
                First Name*
            </label>
            <input v-if="createNew" id="firstname" class="form-control col-8 text-left" type="text" v-model="customer.FirstName">
            <p v-else class="col-8 text-left">{{customer.FirstName}}</p>
        </div>

        <div class="row">
            <label class="col-4 text-left text-success" for="lastName">
                Last Name*
            </label>
            <input v-if="createNew" id="lastName" class="form-control col-8 text-left" type="text" v-model="customer.LastName">
            <p v-else class="col-8 text-left">{{customer.LastName}}</p>
        </div>

        <div class="row">
            <label class="col-4 text-left text-success" for="email">
                Email*
            </label>
            <input v-if="createNew" id="email" class="form-control col-8 text-left" type="text" v-model="customer.Email">
            <p v-else class="col-8 text-left">{{customer.Email}}</p>
        </div>

        <div class="row">
            <label class="col-4 text-left text-success" for="tel">
                Telephone Number*
            </label>
            <input v-if="createNew" id="tel" class="form-control col-8 text-left" type="text" v-model="customer.TelephoneNumber">
            <p v-else class="col-8 text-left">{{customer.TelephoneNumber}}</p>
        </div>

        <div class="row" v-if="customer.Id">
            <label class="col-4 text-left" for="tel">
                Edit Customer
            </label>
            <router-link target="_blank" :to="'/customers/' + customer.Id" class="form-control col btn btn-secondary">
                Edit
            </router-link>
        </div>
    </div>
</template>

<script>

    export default {
        name: "edit-order-customer-component",
        props: ["customer", "createNew"],
    }
</script>

<style scoped lang="scss">

</style>
