<template>
    <div>
        <h2>
            Package Deals
        </h2>

        <div class="row mt-4">
            <div class="offset-10 col-2">
                <button class="btn btn-primary material-icons" @click="AddItem">
                    add
                </button>
            </div>
        </div>

        <div class="mt-4" v-if="!Loading">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import AutoTable from "../../components/Shared/Table";
    import TableView from "../../mixins/TableView";
    import Pagination from "../../components/Shared/Pagination";

    export default {
        name: "location-package-deals",
        components: {Pagination, AutoTable},
        mixins: [TableView],
        data() {
            return {
                Loading: true,
                Pagination: null,
                Url: `/api/v1/booking/locations/${this.$route.params.id}/price-types`,
                Columns: [
                    {
                        Title: "Name",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "Name"
                    },
                    {
                        Title: "Published",
                        Sortable: false,
                        Type: "BADGE",
                        Attribute: "Published",
                        T: "Yes",
                        F: "No"
                    },
                    {
                        Title: "Default",
                        Sortable: false,
                        Type: "BADGE",
                        Attribute: "Default",
                        T: "Yes",
                        F: "No"
                    },
                    {
                        Title: "Has Start time",
                        Sortable: false,
                        Type: "BADGE",
                        T: "Yes",
                        F: "No",
                        Attribute: "AllowTime"
                    },
                    {
                        Title: "Agreement needed",
                        Sortable: false,
                        Type: "BADGE",
                        Attribute: "SendBookingRequest",
                        T: "Yes",
                        F: "No"
                    },
                    {
                        Title: "Agreement by",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "RequestEmail"
                    },
                    {
                        Title: "Notify",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "NotifyEmail"
                    }
                ]
            }
        },
        created() {
            this.GetPage();
            this.emitter.on("reload-location-package-deals", this.GetPage);
        },
        methods: {
            AddItem() {
                this.emitter.emit("open-modal", {type: "EditPackageDealModal", data: {LocationId: this.$route.params.id}})
            },
            SelectItem(item) {
                this.emitter.emit("open-modal", {type: "EditPackageDealModal", data: item})
            }
        }
    }
</script>

<style scoped>

</style>
