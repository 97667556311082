<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Edit Location
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body row">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="name">
                            Name
                        </label>
                    </div>
                    <div class="col">
                        <input id="name" class="form-control" type="text" v-model="location.DisplayName">
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="published">
                            Published
                        </label>
                    </div>
                    <div class="col">
                        <input id="published" type="checkbox" v-model="location.Published">
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="gifts">
                            Allows Gifts
                        </label>
                    </div>
                    <div class="col">
                        <input id="gifts" type="checkbox" v-model="location.AllowGifts">
                    </div>
                </div>

                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="gifts">
                            Linked Location
                        </label>
                    </div>
                    <div class="col">
                        <select class="form-control" v-model="location.LinkedLocationId">
                            <option :value="null">
                                No linked Location
                            </option>
                            <option v-for="l in locations" :key="l.Id" :value="l.Id">
                                {{ l.DisplayName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import bookingApi from "../services/booking-service";
    import BookingService from "../services/booking-service";

    export default {
        name: "edit-location-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                locations: [],
                location: null
            }
        },
        async created() {
            this.location = JSON.parse(JSON.stringify(this.data));
            this.locations = await BookingService.GetAsync(`/api/v1/booking/locations/list`);
        },
        methods: {
            async Save() {
                await bookingApi.PutAsync(`/api/v1/booking/locations/${this.location.Id}`, this.location);
                this.emitter.emit("reload-location");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
