<template>
    <div>
        <section class="modal-backdrop" v-if="ShowBackdrop">
            &nbsp;
        </section>
        <section>
            <div class="modal fade show" v-if="Modals.EditOrderModal" role="dialog">
                <edit-order-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.BlockSlotModal" role="dialog">
                <block-slot-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.EditLocationModal" role="dialog">
                <edit-location-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.EditRoomModal" role="dialog">
                <edit-room-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.EditLocationPriceModal" role="dialog">
                <edit-location-price-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.CreateLocationPriceModal" role="dialog">
                <create-location-price-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.EditPackageDealModal" role="dialog">
                <edit-package-deal-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.EditCustomerModal" role="dialog">
                <edit-customer-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.EditCompanyModal" role="dialog">
                <edit-company-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.CreateVoucherModal" role="dialog">
                <create-voucher-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.SetVoucherStateModal" role="dialog">
                <set-voucher-state-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.EditRoomCombinationModal" role="dialog">
                <edit-room-combination-modal :data="ModalData"/>
            </div>
            <div class="modal fade show" v-if="Modals.CreateRoomCombinationModal" role="dialog">
                <create-room-combination-modal :data="ModalData"/>
            </div>
        </section>
    </div>

</template>

<script>
    import BlockSlotModal from "../../modals/BlockSlotModal";
    import EditOrderModal from "../../modals/EditOrderModal";
    import EditLocationModal from "@/modals/EditLocationModal";
    import EditRoomModal from "@/modals/EditRoomModal";
    import EditLocationPriceModal from "@/modals/EditLocationPriceModal";
    import EditPackageDealModal from "@/modals/EditPackageDealModal";
    import EditCustomerModal from "@/modals/EditCustomerModal";
    import EditCompanyModal from "@/modals/EditCompanyModal";
    import CreateVoucherModal from "@/modals/CreateVoucherModal";
    import SetVoucherStateModal from "@/modals/SetVoucherStateModal";
    import EditRoomCombinationModal from "@/modals/EditRoomCombinationModal";
    import CreateRoomCombinationModal from "@/modals/CreateRoomCombinationModal";
    import CreateLocationPriceModal from "@/modals/CreateLocationPriceModal";

    export default {
        name: "modal-component",
        components: {
            EditRoomCombinationModal,
            SetVoucherStateModal,
            CreateVoucherModal,
            EditCompanyModal,
            EditCustomerModal,
            EditPackageDealModal,
            EditLocationPriceModal,
            CreateLocationPriceModal,
            EditRoomModal,
            EditLocationModal,
            EditOrderModal,
            BlockSlotModal,
            CreateRoomCombinationModal,
        },
        created() {
            this.emitter.on("open-modal", this.OpenModal);
            this.emitter.on("close-modal", this.CloseModal);
        },
        data() {
            return {
                ModalData: null,
                ShowBackdrop: null,
                Modals: {
                    EditOrderModal: false,
                    BlockSlotModal: false,
                    EditLocationModal: false,
                    EditRoomModal: false,
                    EditLocationPriceModal: false,
                    CreateLocationPriceModal: false,
                    EditPackageDealModal: false,
                    EditCustomerModal: false,
                    EditCompanyModal: false,
                    CreateVoucherModal: false,
                    SetVoucherStateModal: false,
                    EditRoomCombinationModal: false,
                    CreateRoomCombinationModal: false,
                }
            }
        },
        methods: {
            CloseModal() {
                this.ShowBackdrop = false;
                for (const modal in this.Modals) {
                    this.Modals[modal] = false;
                }
                this.ModalData = null;
            },
            OpenModal(options) {
                this.ModalData = options.data;
                this.Modals[options.type] = true;
                this.ShowBackdrop = true;
            }
        }
    }
</script>

<style scoped>

    .modal-backdrop {
        z-index: 15;
        opacity: 65%;
    }

    .modal {
        display: block;
        z-index: 25;
    }
</style>
