import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt';
import {createAuth0} from '@auth0/auth0-vue';

import 'jquery'
import 'popper.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.use(store)
    .use(router)
    .use(
        createAuth0({
            domain: "escapeworld-1.eu.auth0.com",
            client_id: "2Zka2IQcfTPGUTWPKgoUQkWS4Dw3DT2z",
            audience: "https://booking-api.lenimal.nl",
            redirect_uri: window.location.origin
        })
    );

app.mount('#app')
