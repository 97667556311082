import axios from 'axios';
import Url from "@/helpers/Url";
import store from '@/store/index'

const BookingService = {
    async DoRequest(method: any, url: string, data?: object | null, queryParams?: object | null): Promise<any> {
        if (!store.state.authToken) {
            console.log("No auth found, suspending call with 1s")
            return new Promise(res => {
                setTimeout(async () => {
                    res(await this.DoRequest(method, url, data, queryParams))
                }, 1000)
            })
        }

        try {
            const response = await axios({
                method: method,
                url: url + Url.GetQueryString(queryParams),
                data: data,
                headers: {
                    "Authorization": `Bearer ${store.getters.getAuthToken}`
                }
            });
            return response.data;
        } catch (err: any) {
            console.log(err);
            throw new Error(err)
        }
    },

    async GetAsync(url: string, queryParams?: object): Promise<any> {
        return this.DoRequest("get", url, null, queryParams);
    },
    async PostAsync(url: string, data: object, queryParams?: object): Promise<any> {
        return this.DoRequest("post", url, data, queryParams);
    },
    async DeleteAsync(url: string, queryParams?: object): Promise<any> {
        return this.DoRequest("delete", url, null, queryParams);
    },
    async PutAsync(url: string, data: object, queryParams?: object): Promise<any> {
        return this.DoRequest("put", url, data, queryParams);
    }
};

export default BookingService;
