<template>
    <div>
        <company-navigation/>

        <div class="row">
            <div class="col-8">
                <router-view/>
            </div>

            <div class="col">
                <company-information :company="Company"/>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/services/booking-service'
    import CompanyNavigation from "@/components/Companies/Navigation";
    import CompanyInformation from "@/components/Companies/Information";

    export default {
        name: "company-details",
        components: {CompanyInformation, CompanyNavigation},
        data() {
            return {
                Company: null
            }
        },
        async created() {
            this.emitter.on("reload-company", this.LoadData);
            await this.LoadData()
        },
        methods: {
            async LoadData() {
                this.Company = await api.GetAsync(`/api/v1/booking/companies/${this.$route.params.id}`)
            }
        }
    }
</script>

<style scoped lang="scss">
    .tabs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;

        a {
            color: black;
        }
    }

</style>
