<template>

    <div>

        <order-navigation/>

        <div class="row">

            <div class="col-8">
                <router-view/>
            </div>

            <div class="col">
                <order-information :order="Order"/>
            </div>
        </div>

    </div>

</template>

<script>
    import api from '@/services/booking-service'
    import OrderInformation from "../components/Orders/Information";
    import OrderNavigation from "@/components/Orders/Navigation";

    export default {
        name: "order-details",
        components: {OrderNavigation, OrderInformation},
        data() {
            return {
                Order: null
            }
        },
        async created() {
            this.Order = await api.GetAsync(`/api/v1/booking/orders/${this.$route.params.id}`)
        }
    }
</script>

<style scoped lang="scss">
    .tabs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;

        a {
            color: black;
        }
    }

</style>
