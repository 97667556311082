<template>
    <div class="col-12 tabs">
        <router-link class="col" :to="'/locations/' + $route.params.id + '/rooms'">Rooms</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/room-combinations'">Room Combinations</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/orders'">Orders</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/reservations'">Reservations</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/prices'">Prices</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/package-deals'">Package Deals</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/feedback'">Feedback</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/forms'">Forms</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/settings'">Settings</router-link>
        <router-link class="col" :to="'/locations/' + $route.params.id + '/dashboard'">Dashboard</router-link>
    </div>
</template>

<script>
    export default {
        name: "location-navigation"
    }
</script>

<style scoped lang="scss">
    .tabs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;

        a {
            color: black;
        }
    }
</style>
