<template>
    <div>
        <h2>Orders</h2>

        <div class="mt-4" v-if="!Loading">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>

    </div>
</template>

<script>
    import Pagination from "../../components/Shared/Pagination";
    import TableView from "../../mixins/TableView";
    import AutoTable from "@/components/Shared/Table";

    export default {
        name: "location-orders",
        components: {Pagination, AutoTable},
        mixins: [TableView],
        data() {
            return {
                Pagination: null,
                Url: `/api/v1/booking/locations/${this.$route.params.id}/orders`,
                Columns: [
                    {
                        Title: "CreatedAt",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "CreatedAt"
                    },

                    {
                        Title: "Price",
                        Sortable: true,
                        Type: "VALUTA",
                        Attribute: "Amount"
                    },
                    {
                        Title: "Paid",
                        Sortable: true,
                        Type: "VALUTA",
                        Attribute: "AmountPaid"
                    },
                    {
                        Title: "Remaining",
                        Sortable: true,
                        Type: "VALUTA",
                        Attribute: "AmountDue"
                    },
                    {
                        Title: "# Rooms",
                        Sortable: false,
                        Type: "COUNT",
                        Attribute: "OrderRows"
                    },
                    {
                        Title: "Status",
                        Sortable: true,
                        Type: "COMBINED",
                        Class: "OrderStatus",
                        Attribute: "Name",
                        SortValue: "OrderStatusId"
                    },
                    {
                        Title: "Voucher",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "Voucher",
                        Attribute: "Code"
                    }
                ]
            }
        },
        async created() {
            this.GetPage();
        },
        methods: {
            SelectItem(order) {
                this.$router.push(`/orders/${order.Id}/reservations`)
            }
        }
    }
</script>

<style scoped>

</style>
