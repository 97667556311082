<template>
    <div>
        <h2>
            Reservations
        </h2>

        <div class="mt-4">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import Filters from "../../mixins/Filters";
    import Pagination from "../../components/Shared/Pagination";
    import TableView from "../../mixins/TableView";
    import AutoTable from "../../components/Shared/Table";

    export default {
        name: "location-orders",
        components: {AutoTable, Pagination},
        mixins: [Filters, TableView],
        data() {
            return {
                Loading: true,
                Url: `/api/v1/booking/orders/${this.$route.params.id}/order-rows`,
                Columns: [
                    {
                        Title: "Location",
                        Sortable: false,
                        Type: "FUNCTION",
                        Attribute: "Order",
                        Func: (order) => {
                            if (order.Location) {
                                return order.Location.DisplayName
                            }
                            return order.LocationId;
                        }
                    },
                    {
                        Title: "Order Date",
                        Sortable: true,
                        Type: "COMBINED_DATE",
                        Class: "Order",
                        Attribute: "CreatedAt"
                    },
                    {
                        Title: "Booking Date",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "BookingDate"
                    },
                    {
                        Title: "Room",
                        Sortable: true,
                        Type: "COMBINED",
                        Class: "Room",
                        Attribute: "Name"
                    },

                    {
                        Title: "Price",
                        Sortable: false,
                        Type: "COMBINED_VALUTA",
                        Class: "Price",
                        Attribute: "Amount"
                    },
                    {
                        Title: "# People",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "Price",
                        Attribute: "People"
                    },
                    {
                        Title: "Status",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "OrderRowStatus",
                        Attribute: "Name"
                    },
                ]
            }
        },
        async created() {
            this.GetPage();
        },
        methods: {
            OpenModal() {

            },
            SelectItem(item) {
                this.$router.push(`/reservations/${item.Id}`)
            }
        }
    }
</script>

<style scoped>

</style>
