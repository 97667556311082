<template>
    <div v-if="isRoomSelectionEnabled">
        <span class="text-danger float-right" v-if="IsBlockable()" title="Block slot">
            <input type="checkbox" :value="selected" @change="ToggleSlotBlock">
        </span>
        <span class="text-danger float-right" v-else title="Block slot">
            &nbsp;
        </span>
    </div>
    <div v-else>

        <!-- Clear -->
        <span class="material-icons float-right" v-if="IsClearable()" @click="RemoveState"
              title="Remove state">
            clear
        </span>

        <!-- un-publish -->
        <span class="material-icons float-right" v-if="IsUnPublish()" @click="PublishSlot"
              title="Publish slot">
                lock_open
        </span>

        <!-- Notes / Comments -->
        <span class="material-icons float-left" v-if="IsNote()"
              :title="item.Order.Notes + ' ' + item.Order.Description">
                    mode_comment
        </span>

        <!-- Automatic can be turned into bookable or blocked -->
        <span class="text-danger material-icons float-right" v-if="IsBlockable()" @click="BlockSlot"
              title="Block slot">
                    block
        </span>

        <span class="material-icons float-right" v-if="IsSlotBlocked()" @click="OpenSlot"
              title="Open slot">
                    add
        </span>

        <!-- End -->
        <span class="material-icons float-right" v-if="IsOrderCreate()" @click="EditOrder"
              title="Create order">
                    edit
        </span>

        <span class="material-icons float-right" v-if="item.IsBooked" @click="EditOrder"
              title="Edit order">
                    edit
        </span>

        <router-link class="material-icons float-left" target="_blank" v-if="IsCompany()" :to="'/companies/' + item.Order.CompanyId"
                     :title="'company: ' + item.Order.CompanyName">
            house
        </router-link>

        <span class="material-icons float-left text-danger" v-if="IsPendingCompletion()"
              title="Waiting on invoice completion">
                house
        </span>

        <router-link class="material-icons float-right" target="_blank" v-if="item.IsBooked" :to="'/orders/' + item.Order.Id"
                     title="Open order">
            adjust
        </router-link>

        <!-- Voucher -->
        <span class="text-success material-icons float-right" v-if="IsVoucher()"
              :title="'used voucher: ' + item.Order.VoucherCode">
                    check_circle
        </span>
    </div>
</template>

<script>
    export default {
        name: "action-row",
        props: ["item"],
        data: () => {
            return {
                selected: false
            }
        },
        computed: {
            isRoomSelectionEnabled() {
                return this.$store.state.isRoomSelectionEnabled
            },
        },
        methods: {
            ToggleSlotBlock() {
                this.selected = !this.selected
                const data = {
                    slotID: this.item.Id,
                    selected: this.selected
                }

                this.$store.dispatch("toggleBlockSlot", data);
            },
            BlockSlot() {
                this.$emit("block-slot", this.item);
            },
            RemoveState() {
                this.$emit("remove-state", this.item);
            },
            PublishSlot() {
                this.$emit("publish-slot", this.item);
            },
            OpenSlot() {
                this.$emit("open-slot", this.item);
            },
            IsUnPublish() {
                return !this.item.IsBooked && !this.item.IsPublished && !this.item.IsManuallyOpened;
            },
            IsAvailable() {
                return this.item.IsAvailable;
            },
            IsBlockable() {
                return !this.item.IsBooked && !this.item.IsManuallyOpened && !this.item.IsManuallyBlocked;
            },
            IsOrderCreate() {
                return this.item.IsAvailable || (!this.item.IsPublished && this.item.IsManuallyOpened);
            },
            IsBooked() {
                return this.item.IsBooked;
            },
            IsSlotBlocked() {
                return !this.item.IsBooked && !this.item.IsManuallyOpened;
            },
            IsNote() {
                return this.item.IsBooked && this.item.Order && (this.item.Order.Notes || this.item.Order.Description);
            },
            IsCompany() {
                return this.item.IsBooked && this.item.Order && this.item.Order.CompanyId;
            },
            IsVoucher() {
                return this.item.IsBooked && this.item.Order && this.item.Order.VoucherCode;
            },
            IsPendingCompletion() {
                return this.item.IsBooked && this.item.Order && (this.item.Order.PaymentType === 'Factuur' || this.item.Order.PaymentType === 'Invoice') && this.item.Order.IsPendingCompletion;
            },
            IsClearable() {
                return this.item.IsManuallyOpened || this.item.IsManuallyBlocked;
            },
            EditOrder() {
                this.$emit("edit-order");
            }
        }
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: none;
        color: black;

        &:hover {
            color: blue;
        }
    }

    span {
        text-decoration: none;
        color: black;

        &:hover {
            color: blue;
        }
    }
</style>
