<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Voucher State
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body" v-if="voucherBatch">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="state">
                            State
                        </label>
                    </div>
                    <div class="col">
                        <select class="form-control" v-model="voucherBatch.VoucherBatchStatusId" id="state">
                            <option v-for="voucherState in VoucherStateList" :key="voucherState.Id" :value="voucherState.Id">
                                {{voucherState.Name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";

    export default {
        name: "set-voucher-state-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                voucherBatch: null,
                VoucherStateList: [],
            }
        },
        async created() {
            this.VoucherStateList = await BookingService.GetAsync("/api/v1/booking/voucher-batch-status/list");
            this.voucherBatch = JSON.parse(JSON.stringify(this.data));
        },
        methods: {
            async Save() {
                await BookingService.PutAsync(`/api/v1/booking/voucher-batches/${this.voucherBatch.Id}`, this.voucherBatch);
                this.emitter.emit("reload-voucher");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
