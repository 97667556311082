<template>
    <section>
        <div class="row">
            <div class="col-10">
                <h2>
                    Vouchers
                </h2>
            </div>
            <div class="col-2">
                <button class="material-icons btn btn-primary" @click="CreateVoucherModal">
                    add
                </button>
            </div>

        </div>
        <div class="row p-4">
            <div class="col-2">
                <input class="form-control" type="text" v-model="Query" placeholder="VoucherCode" @keyup.enter="Search">
            </div>

            <div class="col-4">
                <select class="form-control" v-model="SelectedVoucherTypeId">
                    <option :value="null">
                        Voucher Types
                    </option>
                    <option v-for="voucherBatchType in VoucherBatchTypes" :key="voucherBatchType.Id" :value="voucherBatchType.Id">
                        {{voucherBatchType.Description}} ({{voucherBatchType.Name}})
                    </option>
                </select>
            </div>
            <div class="col-4">
                <select class="form-control" v-model="SelectedVoucherStatusId">
                    <option :value="null">
                        Voucher Status
                    </option>
                    <option v-for="voucherBatchStatus in VoucherBatchStatuses" :key="voucherBatchStatus.Id" :value="voucherBatchStatus.Id">
                        {{voucherBatchStatus.Name}}
                    </option>
                </select>
            </div>
            <div class="col-2">
                <button class="btn btn-primary" @click="Search">
                    Search
                </button>
            </div>
        </div>

        <div class="mt-4" v-if="Pagination">
            <auto-table @table-click="SelectItem" :pagination="Pagination" :columns="Columns" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </section>
</template>

<script>
    import AutoTable from "../components/Shared/Table";
    import Pagination from "../components/Shared/Pagination";
    import TableView from "../mixins/TableView";
    import BookingService from "@/services/booking-service";

    export default {
        name: "vouchers",
        mixins: [TableView],
        components: {Pagination, AutoTable},
        data() {
            return {
                Query: null,
                Pagination: null,
                VoucherBatchTypes: [],
                VoucherBatchStatuses: [],
                SelectedVoucherTypeId: null,
                SelectedVoucherStatusId: null,
                Url: `/api/v1/booking/voucher-batches`,
                Columns: [
                    {
                        Title: "Code",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Code"
                    },
                    {
                        Title: "Discount Amount",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Amount"
                    },
                    {
                        Title: "Percentage?",
                        Sortable: true,
                        Type: "BADGE",
                        Attribute: "IsPercentage",
                        T: "Yes",
                        F: "No",
                    },
                    {
                        Title: "From",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "From"
                    },
                    {
                        Title: "To",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "To"
                    },
                    {
                        Title: "Status",
                        Sortable: true,
                        Type: "COMBINED",
                        Class: "VoucherBatchStatus",
                        Attribute: "Name"
                    }
                ]
            }
        },
        async created() {
            await this.LoadData();
            this.GetPage();
        },
        methods: {
            async LoadData() {
                this.VoucherBatchTypes = await BookingService.GetAsync("/api/v1/booking/voucher-batch-type/list");
                this.VoucherBatchStatuses = await BookingService.GetAsync("/api/v1/booking/voucher-batch-status/list");
            },
            Search() {
                if (this.SelectedVoucherTypeId) {
                    this.Pagination["VoucherBatchTypeIds"] = [this.SelectedVoucherTypeId];
                } else {
                    delete this.Pagination["VoucherBatchTypeIds"]
                }

                if (this.SelectedVoucherStatusId) {
                    this.Pagination["VoucherBatchStatusIds"] = [this.SelectedVoucherStatusId];
                } else {
                    delete this.Pagination["VoucherBatchStatusIds"]
                }

                if (this.Query) {
                    this.Pagination["Query"] = this.Query;
                } else {
                    delete this.Pagination["Query"]
                }

                this.GetPage();
            },
            SelectItem(item) {
                this.$router.push(`/vouchers/${item.Id}`);
            },
            CreateVoucherModal() {
                this.emitter.emit("open-modal", {type: "CreateVoucherModal"})
            }
        }
    }
</script>

<style scoped>

</style>
