export default {
    HandleError(err: any, errMessage: string) {
        if (err) {
            console.log(err.stack); // eslint-disable-line no-console

        }
        console.log(errMessage); // eslint-disable-line no-console

    }
}
