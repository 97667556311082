<template>
    <div>
        <h2>Feedback</h2>

        <section v-if="Feedback">
            <h3>
                Score: {{ Feedback.Score }}
            </h3>

            <div class="feedback__item" v-if="PlayedRooms">
                <h4>
                    Gespeelde Kamers
                </h4>
                <p>
                    {{ PlayedRooms }}
                </p>
            </div>

            <div class="feedback__item">
                <h4>
                    Kamer
                </h4>
                <p>
                    {{ Feedback.RoomFeedback || 'no feedback' }}
                </p>
            </div>

            <div class="feedback__item">
                <h4>
                    Gamemaster
                </h4>
                <p>
                    {{ Feedback.GameMasterFeedback || 'no feedback' }}
                </p>
            </div>

            <div class="feedback__item">
                <h4>
                    Ontvangst
                </h4>
                <p>
                    {{ Feedback.WelcomeFeedback || 'no feedback' }}
                </p>
            </div>

            <div class="feedback__item">
                <h4>
                    Verwachtingen
                </h4>
                <p>
                    {{ Feedback.ExpectationFeedback || 'no feedback' }}
                </p>
            </div>

            <div class="feedback__item">
                <h4>
                    Verbeteringen
                </h4>
                <p>
                    {{ Feedback.ImprovementText || 'no feedback' }}
                </p>
            </div>
        </section>

        <section v-else>
            <div class="text-info">
                No feedback for this order
            </div>
        </section>

    </div>
</template>

<script>
import api from "../../services/booking-service";
import moment from "moment";

export default {
    name: "",
    components: {},
    mixins: [],
    data() {
        return {
            Feedback: null,
            PlayedRooms: null
        }
    },
    async created() {
        await this.getPlayedRooms()
        this.Feedback = (await api.GetAsync(`/api/v1/booking/orders/${this.$route.params.id}`)).Feedback;

    },
    methods: {
        async getPlayedRooms() {
            const orderRows = (await api.GetAsync(`/api/v1/booking/orders/${this.$route.params.id}/order-rows`));
            const roomNames = []
            orderRows.Results.forEach(row => {
                if (row.Room.Name) {
                    roomNames.push(`${row.Room.Name} (${this.formatDate(row.BookingDate)} ${row.Slot.StartHour}:${this.pad(row.Slot.StartMinute)})`)
                }
            })
            this.PlayedRooms = roomNames.join(", ")
        },
        formatDate(time) {
            if (!moment(time).isValid()) {
                return "-"
            }
            return moment(time).format("DD-MM-YYYY");
        },
        pad(num) {
            const date = new Date(0);
            date.setSeconds(num);
            return date.toISOString().substr(17, 2);
        },
    }
}
</script>

<style scoped>

</style>
