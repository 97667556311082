<template>
    <div v-if="!Loading">

        <div class="row">
            <div class="col-4 text-left">
                <label for="price-type-select">
                    Price Type
                </label>
            </div>
            <div class="col">
                <select id="price-type-select" class="form-control" v-model="PriceTypeId" @change="ResetOrderRows">
                    <option v-for="priceType in PriceTypeList" :key="priceType.Id" :value="priceType.Id">
                        {{priceType.Name}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row mt-4 border-bottom">
            &nbsp;
        </div>

        <div class="row mt-4" v-for="(orderRow, idx) in orderRows" :key="orderRow.Id">
            <div class="col-12 row mb-2">
                <h4 class="col-4 text-left">
                    Kamer {{idx + 1}}
                </h4>
                <button class="offset-7 col-1 btn btn-danger material-icons" @click="RemoveOrderRow(idx)">
                    delete
                </button>
            </div>

            <div class="col-12 row">
                <div class="col-4 text-left">
                    <label :for="'date' + idx">
                        Date
                    </label>
                </div>
                <div class="col">
                    <input :id="'date' + idx" type="date" class="form-control" v-model="orderRow.Date" @change="ResetOrderRowsForOrder(idx)">
                </div>
            </div>

            <div class="col-12 row">
                <div class="col-4 text-left">
                    <label :for="'room-select' + idx">
                        Room
                    </label>
                </div>
                <div class="col">
                    <select :id="'room-select' + idx" class="form-control" v-model="orderRow.RoomId" @change="ResetOrderRowsForOrder(idx)">
                        <option :value="null">
                            Select Room
                        </option>
                        <option v-for="room in RoomList" :key="room.Id" :value="room.Id">
                            {{room.Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12 row">
                <div class="col-4 text-left">
                    <label :for="'slot-select' + idx">
                        Slot
                    </label>
                </div>
                <div class="col" v-if="!orderRow.Slot">
                    <select :id="'slot-select' + idx" class="form-control" v-model="orderRow.SlotId">
                        <option :value="null">
                            Select slot
                        </option>
                        <option v-for="slot in orderRow.SlotList" :key="slot.Id" :value="slot.Id">
                            {{usePadding(slot.StartHour)}}:{{usePadding(slot.StartMinute)}}
                        </option>
                    </select>
                </div>

                <div class="col" v-else>
                    <p class="text-left" @click="orderRow.Slot = null">
                        {{usePadding(orderRow.Slot.StartHour)}}:{{usePadding(orderRow.Slot.StartMinute)}} (click to change)
                    </p>
                </div>
            </div>
            <div class="col-12 row" v-if="PriceTypeAllowsTime">
                <div class="col-4 text-left">
                    <label :for="'package-deal-start-' + idx">
                        Starttime Package Deal
                    </label>
                </div>
                <div class="col">
                    <input :id="'package-deal-start-' + idx" type="text" class="form-control" v-model="orderRow.PreferredCombinationTime">
                </div>
                <div class="text-info">
                    The package deal time must start <strong>1,5 hours BEFORE</strong> the slot time <i>or</i>
                    <strong>1,5 hours AFTER </strong> the slot time
                </div>
            </div>
            <div class="col-12 row">
                <div class="col-4 text-left">
                    <label :for="'deviating-time-select' + idx">
                        Deviating Starttime
                    </label>
                </div>
                <div class="col">
                    <input :id="'deviating-time-select' + idx" type="text" class="form-control" v-model="orderRow.DeviatingStartTime">
                </div>
            </div>
            <div class="col-12 row">
                <div class="col-4 text-left">
                    <label :for="'people-select' + idx">
                        # Participants
                    </label>
                </div>
                <div class="col">
                    <select :id="'people-select' + idx" class="form-control" v-model="orderRow.PriceId">
                        <option :value="null">
                            Select # Participants
                        </option>
                        <option v-for="locationPrice in orderRow.PriceList" :key="locationPrice.PriceId" :value="locationPrice.PriceId">
                            {{locationPrice.Price.People}} ({{locationPrice.Price.Amount}} &euro;)
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 row mb-2">
                <button class="offset-11 col-1 btn btn-primary material-icons" @click="AddOrderRow">
                    add
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import bookingApi from '../../services/booking-service'
    import moment from "moment";
    import _ from "lodash";
    import Filters from "@/mixins/Filters";

    export default {
        name: "edit-order-rows-component",
        props: ["orderRows", "locationId"],
        mixins: [Filters],
        data() {
            return {
                Loading: true,
                PriceTypeId: null,
                RoomList: null,
                PriceTypeList: []
            }
        },
        created() {
            this.LoadData();
        },
        computed: {
            PriceTypeAllowsTime() {
                let pt = null;
                this.PriceTypeList.forEach(priceType => {
                    if (priceType.Id === this.PriceTypeId) {
                        pt = priceType
                    }
                });
                if (pt != null) {
                    return pt.AllowTime
                }
                return false;
            }
        },
        methods: {
            async LoadData() {
                const rooms = await bookingApi.GetAsync(`/api/v1/booking/locations/${this.locationId}/rooms/list`);
                const priceTypeList = await bookingApi.GetAsync(`/api/v1/booking/locations/${this.locationId}/price-types/list`);

                if (rooms.length === 0) {
                    return
                }

                this.RoomList = rooms;

                this.PriceTypeList = _.sortBy(priceTypeList, "Order");
                this.PriceTypeId = this.GetDefaultPriceTypeId();

                await this.LoadOrderRows();
            },
            async ResetOrderRows() {
                for (const row of this.orderRows) {
                    row.PriceId = null;
                }
                await this.LoadOrderRows();
            },
            async ResetOrderRowsForOrder(idx) {
                this.orderRows[idx].PriceId = null;
                this.orderRows[idx].SlotId = null;
                this.orderRows[idx].Slot = null;
                await this.LoadOrderRows();
            },
            async LoadOrderRows() {
                this.Loading = true;
                for (const row of this.orderRows) {
                    const date = moment(row.Date).format("YYYY-MM-DD");

                    const slotList = await bookingApi.GetAsync(`/api/v1/booking/rooms/${row.RoomId}/slots/available/all?date=${date}`);
                    const priceList = await bookingApi.GetAsync(`/api/v1/booking/locations/${this.locationId}/prices/list`, {
                        Date: date,
                        RoomId: row.RoomId,
                        PriceTypeId: this.PriceTypeId
                    });


                    row.SlotList = _.sortBy(slotList, ["StartHour", "StartMinute"])
                    row.PriceList = _.sortBy(priceList, ["Price.People"])

                    this.Loading = false;
                }
            },

            AddOrderRow() {
                this.$emit("add-order-row")
            },
            RemoveOrderRow(idx) {
                this.$emit("remove-order-row", idx)
            },
            GetDefaultPriceTypeId() {
                if (this.orderRows[0] && this.orderRows[0].PriceTypeId) {
                    return this.orderRows[0].PriceTypeId;
                }

                let id = -1;
                this.PriceTypeList.forEach(pt => {
                    if (pt.Default) {
                        id = pt.Id;
                    }
                });

                return id;
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
