<template>
    <div v-if="!Loading">
        <h2>
            Companies
        </h2>

        <auto-table :pagination="Pagination" :columns="Columns" :loading="Loading" @table-click="SelectItem"/>

        <pagination :pagination="Pagination" @get-page="Paginate"/>
    </div>
</template>

<script>
    import AutoTable from "../components/Shared/Table";
    import Pagination from "../components/Shared/Pagination";
    import TableView from "../mixins/TableView";

    export default {
        name: "companies",
        mixins: [TableView],
        components: {Pagination, AutoTable},
        data() {
            return {
                Url: "/api/v1/booking/companies",
                Columns: [
                    {
                        Title: "Aangemaakt",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "CreatedAt"
                    },
                    {
                        Title: "Naam",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Name"
                    },
                    {
                        Title: "Email",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Email"
                    },
                    {
                        Title: "Tel",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "TelephoneNumber"
                    }
                ]
            }
        },
        created() {
            this.GetPage();
        },
        methods: {
            SelectItem(company) {
                this.$router.push(`/companies/${company.Id}`)
            }
        }
    }
</script>

<style scoped>

</style>
