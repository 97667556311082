<template>
    <div>
        <h2>Prices</h2>

        <div class="row mt-4">
            <div class="col-4">
                <select class="form-control" v-model="SelectedPriceTypeId" @change="ReloadPage">
                    <option :value="null">
                        All Price Types
                    </option>
                    <option v-for="priceType in PriceTypeList" :key="priceType.Id" :value="priceType.Id">
                        {{priceType.Name}}
                    </option>
                </select>
            </div>
            <div class="offset-6 col-2">
                <button class="btn btn-primary material-icons" @click="AddItem">
                    add
                </button>
            </div>
        </div>

        <div class="mt-4" v-if="!Loading">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import AutoTable from "../../components/Shared/Table";
    import TableView from "../../mixins/TableView";
    import Pagination from "../../components/Shared/Pagination";
    import BookingApi from "../../services/booking-service";

    export default {
        name: "",
        components: {Pagination, AutoTable},
        mixins: [TableView],
        data() {
            return {
                SelectedPriceTypeId: null,
                PriceTypeList: [],
                Url: `/api/v1/booking/locations/${this.$route.params.id}/prices`,
                Pagination: null,
                Columns: [
                    {
                        Title: "Type",
                        Sortable: true,
                        Type: "COMBINED",
                        Class: "PriceType",
                        Attribute: "Name",
                        SortValue: "PriceTypeId"
                    },
                    {
                        Title: "Dag",
                        Sortable: true,
                        Type: "COMBINED_DAY",
                        Class: "Day",
                        Attribute: "Name",
                        SortValue: "DayId"
                    },
                    {
                        Title: "Prijs",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "Price",
                        Attribute: "Amount",
                        SortValue: "PriceId"
                    },
                    {
                        Title: "# Deelnemers",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "Price",
                        Attribute: "People",
                        SortValue: "PriceId"
                    },
                    {
                        Title: "Online zichtbaar",
                        Sortable: true,
                        Type: "BADGE",
                        T: "Yes",
                        F: "No",
                        Attribute: "Published"
                    }
                ]
            }
        },
        async created() {
            this.emitter.on("reload-location-prices", this.GetPage);

            this.GetPage();
            await this.LoadData();
        },
        methods: {
            async LoadData() {
                this.PriceTypeList = await BookingApi.GetAsync(`/api/v1/booking/locations/${this.$route.params.id}/price-types/list`)
            },
            ReloadPage() {
                if (this.SelectedPriceTypeId) {
                    this.Url = `/api/v1/booking/locations/${this.$route.params.id}/prices/price-type/${this.SelectedPriceTypeId}`;
                } else {
                    this.Url = `/api/v1/booking/locations/${this.$route.params.id}/prices`;
                }
                this.GetPage();
            },
            AddItem() {
                this.emitter.emit("open-modal", {
                    type: "CreateLocationPriceModal",
                    data: {locationId: this.$route.params.id}
                })
            },
            SelectItem(item) {
                this.emitter.emit("open-modal", {type: "EditLocationPriceModal", data: item})
            }
        }
    }
</script>

<style scoped>

</style>
