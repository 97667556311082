<template>
    <div v-if="!Loading">
        <h2>
            Customers
        </h2>
        <div class="row p-4">
            <div class="col-3">
                <input class="form-control" type="text" placeholder="First / Last Name" v-model="Query">
            </div>
            <div class="offset-7 col">
                <button class="btn btn-primary" @click="Search">
                    Search
                </button>
            </div>
        </div>

        <div class="mt-4">
            <auto-table @table-click="SelectItem" :pagination="Pagination" :columns="Columns" :loading="Loading"/>
            <pagination :pagination="Pagination" @get-page="Paginate"/>
        </div>
    </div>
</template>

<script>
    import Pagination from "../components/Shared/Pagination";
    import TableView from "../mixins/TableView";
    import AutoTable from "../components/Shared/Table";

    export default {
        mixins: [TableView],
        name: "customers",
        components: {AutoTable, Pagination},
        data() {
            return {
                Pagination: null,
                Url: "/api/v1/booking/customers",
                Query: "",
                Columns: [
                    {
                        Title: "CreatedAt",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "CreatedAt"
                    },
                    {
                        Title: "FirstName",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "FirstName"
                    },
                    {
                        Title: "LastName",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "LastName"
                    },
                    {
                        Title: "Email",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Email"
                    },
                    {
                        Title: "Tel",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "TelephoneNumber"
                    },
                    {
                        Title: "CreatedBy",
                        Sortable: false,
                        Type: "TEXT",
                        Attribute: "CreatedBy",
                    }
                ]
            }
        },
        created() {
            this.GetPage();
        },
        methods: {
            Search() {
                if (this.Query) {
                    this.Pagination["Query"] = [this.Query];
                } else {
                    delete this.Pagination["Query"]
                }
                this.GetPage();
            },
            SelectItem(c) {
                this.$router.push(`/customers/${c.Id}/orders`);
            }
        }
    }
</script>

<style scoped>

</style>
