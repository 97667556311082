<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Location Price
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body row" v-if="locationPrice">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="participants">
                            # participants
                        </label>
                    </div>
                    <div class="col">
                        <input id="participants" class="form-control" type="text" v-model="locationPrice.Price.People">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="amount">
                            Price
                        </label>
                    </div>
                    <div class="col">
                        <input id="amount" class="form-control" type="text" v-model="locationPrice.Price.Amount">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="published">
                            Published
                        </label>
                    </div>
                    <div class="col">
                        <input id="published" type="checkbox" v-model="locationPrice.Published">
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";

    export default {
        name: "edit-location-price-modal",
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                locationPrice: null,
                PriceTypes: null,
                DayTypeList: null,
                createNew: false
            }
        },
        async created() {
            this.PriceTypes = await BookingService.GetAsync(`/api/v1/booking/locations/${this.data.LocationId}/price-types/list`);
            this.DayTypeList = await BookingService.GetAsync(`/api/v1/booking/day-type/list`);

            this.locationPrice = JSON.parse(JSON.stringify(this.data));
        },
        methods: {
            async Save() {
                await BookingService.PutAsync(`/api/v1/booking/locations/${this.locationPrice.LocationId}/prices/${this.locationPrice.Id}`, this.locationPrice);
                this.emitter.emit("reload-location-prices");
                this.CloseModal();
            },
        }
    }
</script>

<style scoped lang="scss">

</style>
