<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link class="navbar-brand" to="/">
            <img src="../../assets/logo.png" width="30" height="30" class="d-inline-block align-top" alt="">
            Booking
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="isAuthenticated">
            <ul class="navbar-nav mr-auto d-1-flex" >
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" @mouseover="showLocation=true" href="#" id="location-dropdown" role="button"
                       data-toggle="dropdown">
                        Locations
                    </a>
                    <div class="dropdown-menu" v-if="showLocation">
                        <router-link class="dropdown-item" to="/locations">
                            Locations
                        </router-link>
                        <router-link class="dropdown-item" to="/package-deals">
                            Package Deals
                        </router-link>
                    </div>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="order-dropdown" role="button"
                       data-toggle="dropdown">
                        Orders
                    </a>
                    <div class="dropdown-menu">
                        <router-link class="dropdown-item" to="/orders">
                            Orders
                        </router-link>
                        <router-link class="dropdown-item" to="/reservations">
                            Reservations
                        </router-link>
                        <router-link class="dropdown-item" to="/reservation-query">
                            Reservation Queries
                        </router-link>
                        <router-link class="dropdown-item" to="/financial">
                            Financial
                        </router-link>
                    </div>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="customer-dropdown" role="button"
                       data-toggle="dropdown">
                        Customers
                    </a>
                    <div class="dropdown-menu">
                        <router-link class="dropdown-item" to="/customers">
                            Customers
                        </router-link>
                        <router-link class="dropdown-item" to="/companies">
                            Companies
                        </router-link>
                    </div>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="forms-dropdown" role="button"
                       data-toggle="dropdown">
                        Forms
                    </a>
                    <div class="dropdown-menu">
                        <router-link class="dropdown-item" to="/forms">
                            Forms
                        </router-link>
                        <router-link class="dropdown-item" to="/feedback">
                            Feedback
                        </router-link>
                    </div>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="voucher-dropdown" role="button"
                       data-toggle="dropdown">
                        Vouchers
                    </a>
                    <div class="dropdown-menu">
                        <router-link class="dropdown-item" to="/vouchers">
                            Vouchers
                        </router-link>
                        <router-link class="dropdown-item" to="/gifts">
                            Gifts
                        </router-link>
                    </div>
                </li>
            </ul>

            <a href="javascript:;" @click="logout" class="navbar-text">
                Logout ({{userName}})
            </a>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" v-else>
            <a href="javascript:;" @click="login" class="navbar-text">
                Login
            </a>
        </div>
    </nav>
</template>

<script>

export default {
    props: [],
    computed: {
        isAuthenticated() {
            return this.$store.state.isAuthenticated;
        },
        userName() {
            try {
                return this.$store.state.user.name
            } catch (e) {
                return ''
            }
        }
    },
    data() {
        return {
            showLocation:true,
        }
    },
    methods: {
        login() {
            this.$auth0.loginWithRedirect()
        },
        logout() {
            this.$store.dispatch("logout")

            this.$auth0.logout({ returnTo: window.location.origin });
            this.$router.push("/logout")
        }
    }
}
</script>

<style scoped>
</style>
