<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Company
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="name">
                            Name
                        </label>
                    </div>
                    <div class="col">
                        <input id="name" class="form-control" type="text" v-model="company.Name">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Email">
                            Email
                        </label>
                    </div>
                    <div class="col">
                        <input id="Email" class="form-control" type="email" v-model="company.Email">
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";

    export default {
        name: "edit-company-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                company: null
            }
        },
        created() {
            this.company = JSON.parse(JSON.stringify(this.data))
        },
        methods: {
            async Save() {
                await BookingService.PutAsync(`/api/v1/booking/companies/${this.company.Id}`, this.company);
                this.emitter.emit("reload-company");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
