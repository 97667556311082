<template>
    <div>
        <customer-navigation/>

        <div class="row">
            <div class="col-8">
                <router-view/>
            </div>

            <div class="col">
                <customer-information :customer="Customer"/>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomerNavigation from "@/components/Customers/Navigation";
    import CustomerInformation from "@/components/Customers/Information";
    import api from "@/services/booking-service";

    export default {
        name: "customer-details",
        components: {CustomerInformation, CustomerNavigation},
        data() {
            return {
                Customer: null
            }
        },
        async created() {
            this.emitter.on("reload-customer", this.LoadData);
            await this.LoadData()
        },
        methods: {
            async LoadData() {
                this.Customer = await api.GetAsync(`/api/v1/booking/customers/${this.$route.params.id}`)
            }
        }
    }
</script>

<style scoped>

</style>
