<template>
    <div class="information" v-if="order">
        <h3>
            {{ order.Code }}
        </h3>

        <div class="information__list">
            <dl class="grid-data-list data-list">
                <template v-if="order.Voucher">
                    <dt>
                        Voucher
                    </dt>
                    <dd>
                        <router-link :to="'/vouchers/' + order.Voucher.VoucherBatch.Id">
                            {{ order.Voucher.Code }} ({{ order.Voucher.VoucherBatch.Amount }}
                            <span v-if="order.Voucher.VoucherBatch.IsPercentage">%</span>
                            <span v-else>&euro;</span>)
                        </router-link>
                    </dd>
                </template>

                <dt>
                    Klant
                </dt>
                <dd>
                    <router-link :to="'/customers/' + order.Customer.Id + '/orders'">
                        {{ order.Customer.FirstName || "&nbsp;" }} {{ order.Customer.LastName }}
                    </router-link>
                </dd>

                <template v-if="order.Company">
                    <dt>
                        Bedrijf
                    </dt>
                    <dd>
                        <a :href="'/booking/company-management/' + order.Company.Id">
                            {{ order.Company.Name || "&nbsp;" }}
                        </a>
                    </dd>
                </template>

                <dt>
                    Email
                </dt>
                <dd>
                    {{ order.Customer.Email || "&nbsp;" }}
                </dd>

                <dt>
                    Telefoonnummer
                </dt>
                <dd>
                    {{ order.Customer.TelephoneNumber || "&nbsp;" }}
                </dd>

                <template v-if="order.Voucher">
                    <dt>
                        Voucher
                    </dt>
                    <dd>
                        <router-link :to="'/vouchers/' + order.Voucher.VoucherBatch.Id">
                            {{ order.Voucher.Code }} ({{ order.Voucher.VoucherBatch.Amount }}
                            <span v-if="order.Voucher.VoucherBatch.IsPercentage">%</span>
                            <span v-else>&euro;</span>)
                        </router-link>
                    </dd>
                </template>

            </dl>

            <hr>

            <dl class="grid-data-list data-list">

                <dt>
                    Totaal
                </dt>
                <dd>
                    {{ useValuta(order.Amount) }}
                </dd>
                <dt>
                    Betaald
                </dt>
                <dd>
                    {{ useValuta(order.AmountPaid) }}
                </dd>
                <dt>
                    Nog te betalen
                </dt>
                <dd>
                    {{ useValuta(order.AmountDue) }}
                </dd>

                <dt>
                    Betaal type
                </dt>
                <dd>
        <span v-if="order.PaymentType">
            {{ order.PaymentType.Name || "&nbsp;" }}
        </span>
                </dd>

                <dt>
                    Type
                </dt>
                <dd>
                    {{ order.OrderType.Name || "&nbsp;" }}
                </dd>

                <dt>
                    Status
                </dt>
                <dd>
                    {{ order.OrderStatus.Name || "&nbsp;" }}
                </dd>

                <dt>
                    P.O. nummer
                </dt>
                <dd>
                    {{ order.PoNumber || "&nbsp;" }}
                </dd>

                <dt>
                    Factuur verstuurd
                </dt>
                <dd>
        <span class="badge" :class="{'badge-success' : order.InvoiceSent, 'badge-danger' : !order.InvoiceSent}">
            {{ order.InvoiceSent ? "Ja" : "Nee" }}
        </span>
                </dd>
                <dt>
                    Klant opmerking
                </dt>
                <dd>
                    {{ order.ClientDescription || "&nbsp;" }}
                </dd>

            </dl>
            <hr>

            <dl class="grid-data-list data-list">
                <dt>
                    Factuur gegevens
                </dt>
                <dd>
                    <span class="badge"
                          :class="{'badge-success' : !order.PendingCompletion, 'badge-danger' : order.PendingCompletion}">
                        {{ order.PendingCompletion ? "Niet Aangevuld" : "aangevuld" }}
                    </span>
                </dd>
            </dl>

            <dl class="grid-data-list data-list">

                <dt>
                    Omschrijving
                </dt>
                <dd>
                    {{ order.Description }}
                </dd>

                <template v-if="order.CombinationType">
                    <dt>
                        Combi
                    </dt>
                    <dd>
                        {{ order.CombinationType }}
                    </dd>
                </template>

                <template v-if="order.DiviatingStartTime ">
                    <dt>
                        Afwijkende Start
                    </dt>
                    <dd>
                        {{ order.DiviatingStartTime }}
                    </dd>
                </template>

                <template v-if="order.OptionExpireDate ">
                    <dt>
                        Offerte eind
                    </dt>
                    <dd>
                        {{ useFormatDate(order.OptionExpireDate) }}
                    </dd>
                </template>

                <template v-if="order.CompanyName ">
                    <dt>
                        Bedrijf
                    </dt>
                    <dd>
                        {{ order.CompanyName }}
                    </dd>
                </template>

                <dt>
                    Notities
                </dt>
                <dd>
                    {{ order.Notes }}
                </dd>

            </dl>

            <hr>

            <dl class="grid-data-list data-list">
                <dt>
                    Door
                </dt>
                <dd>
                    {{ order.CreatedBy }}
                </dd>

                <dt>
                    Datum
                </dt>
                <dd>
                    {{ useFormatTime(order.CreatedAt) }}
                </dd>

            </dl>

            <dl class="grid-data-list data-list">

                <div v-for="payment in order.Payments" :key="payment.Id">
                    <dt>
                        Bedrag
                    </dt>
                    <dd>
                        {{ payment.Amount }}
                    </dd>

                    <div :key="paymentAttempt.Id" v-for="paymentAttempt in payment.PaymentAttempts">
                        <dt>
                            Poging
                        </dt>
                        <dd>
                            {{ paymentAttempt.Status }}
                        </dd>

                    </div>

                </div>
            </dl>

        </div>
    </div>

</template>

<script>
import Filters from "../../mixins/Filters";

export default {
    name: "order-information",
    props: ["order"],
    mixins: [Filters],
    data() {
        return {
            ReminderSent: false
        }
    },
    methods: {}
}
</script>

<style scoped lang="scss">
.information {
    background-color: #c4c4c4;
    border-radius: 3px;
    padding: 20px;
}
</style>
