<template>

    <section v-if="customer">
        <div class="row">
            <div class="col-10">
                <h2 class="text-left">
                    {{customer.FirstName}} {{customer.LastName}}
                </h2>
            </div>
            <div class="col">
                <button @click="EditCustomerModal" class="btn btn-secondary material-icons">
                    edit
                </button>
            </div>
        </div>

        <div class="mt-4 information">
            <dl class="grid-data-list data-list">
                <dt>
                    Voonaam
                </dt>
                <dd>
                    {{customer.FirstName}}
                </dd>
                <dt>
                    Achternaam
                </dt>
                <dd>
                    {{customer.LastName}}
                </dd>
                <dt>
                    Email
                </dt>
                <dd>
                    {{customer.Email}}
                </dd>
                <dt>
                    Telefoonnummer
                </dt>
                <dd>
                    {{customer.TelephoneNumber}}
                </dd>
            </dl>

            <hr>

            <dl class="grid-data-list data-list">
                <dt>
                    Aangemaakt
                </dt>
                <dd>
                    {{useFormatDate(customer.CreatedAt)}}
                </dd>
                <template v-if="customer.CreatedBy">

                    <dt>
                        Aangemaakt door
                    </dt>
                    <dd>
                    <span v-if="customer.CreatedBy.Name">
                        {{customer.CreatedBy.Name}}
                    </span>
                        <span v-else>
                        {{customer.CreatedBy}}
                    </span>
                    </dd>
                </template>

                <template v-if="customer.UpdatedBy">
                    <dt>
                        Laast gewijzigd op
                    </dt>
                    <dd>
                        {{useFormatDate(customer.UpdatedAt)}}
                    </dd>
                    <dt>
                        Laast gewijzigd door
                    </dt>
                    <dd>
                    <span v-if="customer.UpdatedBy.Name">
                        {{customer.UpdatedBy.Name}}
                    </span>
                        <span v-else>
                        {{customer.UpdatedBy}}
                    </span>
                    </dd>
                </template>

            </dl>
        </div>
    </section>

</template>

<script>
    import Filters from "@/mixins/Filters";

    export default {
        name: "customer-information",
        props: ["customer"],
        mixins: [Filters],
        methods: {
            EditCustomerModal() {
                this.emitter.emit("open-modal", {type: "EditCustomerModal", data: this.customer})
            }
        }
    }
</script>

<style scoped lang="scss">
    .information {
        background-color: #c4c4c4;
        border-radius: 3px;
        padding: 20px;
    }
</style>
