<template>
    <div class="tabs">
        <router-link class="col" :to="'/vouchers/' + $route.params.id + '/codes'">codes</router-link>
    </div>
</template>

<script>
    export default {
        name: "voucher-navigation"
    }
</script>

<style scoped lang="scss">
    .tabs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;

        a {
            color: black;
        }
    }
</style>
