<template>
    <div class="tabs">
        <router-link class="col" :to="'/reservations/' + $route.params.id">
            Information
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "reservation-navigation"
    }
</script>

<style scoped lang="scss">
    .tabs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;

        a {
            color: black;
        }
    }
</style>
