<template>
    <table class="table table-hover table-selectable">
        <thead>
        <tr>
            <th v-for="col in columns" :key="col.Title">
            <span v-if="!col.Sortable">
                {{col.Title}}
            </span>

                <span class="table-01__sortable-label" v-if="col.Sortable && pagination" @click="OrderBy(col.SortValue ? col.SortValue : col.Attribute, !pagination.OrderByDesc)" title="Sorteren op">
                {{col.Title}}
            </span>

                <span class="table-01__sortable-arrows" v-if="col.Sortable && pagination">
                <span class="material-icons"
                      style="cursor: pointer; font-size: 10px; top: 2px; left: 2px;"
                      :class="{'is-active' : (col.Attribute == pagination.OrderBy || col.SortValue == pagination.OrderBy) && !pagination.OrderByDesc}"
                      @click="OrderBy(col.SortValue ? col.SortValue : col.Attribute, false)">
                    keyboard_arrow_up

                </span>

                <span class="material-icons"
                      style="cursor: pointer; font-size: 10px; top: 2px; left: 2px;"
                      :class="{'is-active' : (col.Attribute == pagination.OrderBy || col.SortValue == pagination.OrderBy) && pagination.OrderByDesc}"
                      @click="OrderBy(col.SortValue ? col.SortValue : col.Attribute, true)">
                    keyboard_arrow_down
                </span>
            </span>
            </th>
        </tr>
        </thead>
        <tbody v-if="loading">
        <tr>
            <td :colspan="columns.length" class="text-center">
                <loader></loader>
            </td>
        </tr>
        </tbody>
        <tbody v-else-if="pagination">
        <tr v-if="pagination.Results.length == 0">
            <td :colspan="columns.length" class="text-center">
                <em>Geen resultaten gevonden</em>
            </td>
        </tr>
        <tr class="table-row" role="row" v-for="item in pagination.Results"
            :key="item.Id"
            @click="TableClick(item)"
            :class="{
                'is-active': item.Id == CurrentId,
                'text-info': settings && settings.InfoValues && settings.InfoValues.indexOf(item[settings.Info]) > -1,
                'text-warning': settings && settings.WarningValues && settings.WarningValues.indexOf(item[settings.Warning]) > -1,
                'text-danger': settings && settings.DangerValues && settings.DangerValues.indexOf(item[settings.Danger]) > -1
                }">
            <td v-for="col in columns"  :key="col.Title">
            <span v-if="col.Type == 'TEXT'">
                {{item[col.Attribute]}}
            </span>
            <span v-if="col.Type == 'COMBINED'">
                <span v-if="item[col.Class]">{{item[col.Class][col.Attribute]}}</span>
            </span>

            <span v-if="col.Type == 'DAY'">
                {{useTranslateDay(item[col.Attribute])}}
            </span>

            <span v-if="col.Type == 'COMBINED_DAY'">
                <span v-if="item[col.Class]">{{useTranslateDay(item[col.Class][col.Attribute])}}</span>
            </span>

            <span v-if="col.Type == 'COUNT'">
                {{item[col.Attribute].length}}
            </span>

            <span v-if="col.Type == 'VALUTA'">
                {{useValuta(item[col.Attribute])}}
            </span>
            <span v-if="col.Type == 'COMBINED_VALUTA'">
                <span v-if="item[col.Class]">{{useValuta(item[col.Class][col.Attribute])}}</span>
            </span>
            <span v-if="col.Type == 'PERCENT'">
                {{usePercent(item[col.Attribute])}}
            </span>

            <span v-if="col.Type == 'TIME'">
                {{useFormatTime(item[col.Attribute])}}
            </span>

            <span v-if="col.Type == 'DATE'">
                {{useFormatDate(item[col.Attribute])}}
            </span>
            <span v-if="col.Type == 'COMBINED_DATE'">
            <span v-if="item[col.Class]">{{useFormatDate(item[col.Class][col.Attribute])}}</span>
            </span>

            <span v-if="col.Type == 'BADGE'" class="badge" :class="item[col.Attribute] ? 'badge-success' : 'badge-danger'">
                {{item[col.Attribute] ? col.T : col.F}}
            </span>

            <span v-if="col.Type == 'BADGE_CLEAN'" class="badge badge-info">
                {{item[col.Attribute] ? col.T : col.F}}
            </span>

            <span v-if="col.Type == 'BADGE_STATUS'" class="badge badge-info">
                {{item[col.Attribute]}}
            </span>

            <div v-if="col.Type == 'LIST' && item[col.Class]">
                <span v-for="l in item[col.Class]" :key="l[col.Attribute]" class="badge">
                    {{ l[col.Attribute] || "&nbsp;"}}
                </span>

                <span v-if="item[col.Class].length == 0">
                    &nbsp;
                </span>
            </div>

            <span v-if="col.Type == 'FUNCTION'">
                {{ col.Func(item[col.Attribute]) }}
            </span>

            <span v-if="col.Type == 'ITEM_FUNCTION'">
                {{ col.Func(item) }}
            </span>

            <span v-if="col.Type == 'MODAL'" @click="OpenModal(col.Attribute, item, $event)">
                <button class="btn btn-01 btn-icon">
                    <span :class="'fa ' + col.Icon ">

                    </span>
                </button>
            </span>

            </td>
        </tr>
        </tbody>
    </table>

</template>

<script>
    import Filters from "../../mixins/Filters";
    import Loader from "./Loading";

    export default {
        name: "auto-table",
        components: {Loader},
        mixins: [Filters],
        props: {
            'pagination': {
                type: Object
            },
            'columns': {
                type: Array
            },
            'loading': {
                type: Boolean
            },
            'settings': {
                type: Object
            }
        },
        data: function () {
            return {
                CurrentId: null
            }
        },
        methods: {
            TableClick(item) {
                this.CurrentId = item.Id;
                this.$emit("table-click", item)
            },
            OpenModal(type, item, event) {
                event.preventDefault();
                event.stopPropagation();
            },
            OrderBy(attribute, orderByDesc) {
                this.$emit("order-by", attribute, orderByDesc)
            }
        }
    }
</script>

<style scoped lang="scss">
.table-selectable {
    &:hover {
        cursor: pointer;
    }
}
    .table-row {
        td {
            padding: 5px;
        }
    }
</style>
