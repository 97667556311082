<template>
    <div v-if="!Loading">
        <h2>
            Reservations
        </h2>

        <div class="row pl-4">
            <div class="col-2">
                Date Selection
                <select class="form-control" v-model="Pagination.SelectedDateQuery">
                    <option value="BookingDate">
                        Booking Date
                    </option>
                    <option value="CreatedAt">
                        Created At
                    </option>
                </select>
            </div>
            <div class="col-3">
                From (included)
                <input class="form-control" type="date" v-model="Gte">
            </div>

            <div class="col-3">
                To (included)
                <input class="form-control" type="date" v-model="Lte">
            </div>
        </div>

        <div class="row pl-4 mt-2">
            <div class="col">
                <select class="form-control" v-model="SelectedLocation" @change="GetRooms(); GetPackageDeals()">
                    <option :value="null">
                        All Locations
                    </option>
                    <option v-for="loc in Locations" :key="loc.Id" :value="loc.Id">
                        {{ loc.DisplayName }}
                    </option>
                </select>
            </div>
            <div class="col">
                <select class="form-control" v-model="SelectedRoom">
                    <option :value="null">
                        All rooms
                    </option>
                    <option v-for="room in Rooms" :key="room.Id" :value="room.Id">
                        {{ room.Name }}
                    </option>
                </select>
            </div>
            <div class="col">
                <select class="form-control" v-model="SelectedStatus">
                    <option :value="null">
                        All status
                    </option>
                    <option v-for="os in OrderRowStatuses" :key="os.Id" :value="os.Id">
                        {{ os.Name }}
                    </option>
                </select>

            </div>
            <div class="col">
                <select class="form-control" v-model="SelectedOrderType">
                    <option :value="null">
                        All types
                    </option>
                    <option v-for="ot in OrderTypes" :key="ot.Id" :value="ot.Id">
                        {{ ot.Name }}
                    </option>
                </select>
            </div>

            <div class="col">
                <select class="form-control" v-model="SelectedPackageDeal">
                    <option :value="null">
                        Package Deal Type
                    </option>
                    <option v-for="packageDeal in PackageDeals" :key="packageDeal.Id" :value="packageDeal.Id">
                        {{ packageDeal.Name }}
                    </option>
                </select>
            </div>

            <div class="offset-2 col-2">
                <button class="btn btn-primary" @click="Search">
                    Search
                </button>
            </div>
        </div>

        <div class="row m-2">
            <div class="offset-10 col-2">
                <button class="btn btn-secondary" @click="Download">
                    Downloaden
                </button>
            </div>
        </div>

        <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination"
                    :loading="Loading"/>
        <div v-if="Pagination.Results">
            <pagination @get-page="Paginate" :allow500="true" :pagination="Pagination"/>
        </div>
    </div>

</template>

<script>
import bookingApi from "../services/booking-service"
import moment from "moment"
import Pagination from "../components/Shared/Pagination";
import AutoTable from "../components/Shared/Table";
import TableView from "../mixins/TableView";

export default {
    name: "reservations",
    mixins: [TableView],
    components: {AutoTable, Pagination},
    data() {
        return {
            Pagination: {
                Gte: moment().add(-14, "d").format("YYYY-MM-DD"),
                Lte: moment().format("YYYY-MM-DD"),
                SelectedDateQuery: 'BookingDate',
                Limit: 10,
                Offset: 0,
                OrderBy: "CreatedAt",
                OrderByDec: true
            },
            Url: `/api/v1/booking/orders/0/order-rows`,
            SelectedLocation: null,
            SelectedRoom: null,
            SelectedOrderType: null,
            SelectedStatus: null,
            SelectedPackageDeal: null,
            Gte: moment().add(-14, "d").format("YYYY-MM-DD"),
            Lte: moment().format("YYYY-MM-DD"),
            Locations: [],
            Rooms: [],
            OrderTypes: [],
            OrderRowStatuses: [],
            PackageDeals: [],

            Columns: [
                {
                    Title: "Location",
                    Sortable: false,
                    Type: "FUNCTION",
                    Attribute: "Order",
                    Func: (order) => {
                        if (order.Location) {
                            return order.Location.DisplayName
                        }
                        return order.LocationId;
                    }
                },
                {
                    Title: "Order Date",
                    Sortable: true,
                    Type: "COMBINED_DATE",
                    Class: "Order",
                    Attribute: "CreatedAt"
                },
                {
                    Title: "Booking Date",
                    Sortable: true,
                    Type: "DATE",
                    Attribute: "BookingDate"
                },
                {
                    Title: "Room",
                    Sortable: true,
                    Type: "COMBINED",
                    Class: "Room",
                    Attribute: "Name"
                },
                {
                    Title: "Status",
                    Sortable: true,
                    Type: "COMBINED",
                    Class: "OrderRowStatus",
                    Attribute: "Name"
                },

                {
                    Title: "Price",
                    Sortable: false,
                    Type: "COMBINED_VALUTA",
                    Class: "Price",
                    Attribute: "Amount"
                },
                {
                    Title: "# People",
                    Sortable: false,
                    Type: "COMBINED",
                    Class: "Price",
                    Attribute: "People"
                },
                {
                    Title: "Package Deal",
                    Sortable: false,
                    Type: "COMBINED",
                    Class: "PriceType",
                    Attribute: "Name"
                },
            ],

            Modal: {
                CreateOrderModal: false,
            },
        }
    },
    filters: {
        floor(value) {
            return Math.floor(value)
        }
    },
    created() {
        Promise.all([
            bookingApi.GetAsync("/api/v1/booking/locations/list"),
            bookingApi.GetAsync("/api/v1/booking/order-rows/status/list"),
            bookingApi.GetAsync("/api/v1/booking/order-types/list"),
        ])
            .then(res => {
                this.Locations = res[0];
                this.OrderRowStatuses = res[1];
                this.OrderTypes = res[2];
            });
        this.GetPage();

    },
    methods: {
        Search() {
            this.CreatePagination();
            this.GetPage();
        },
        async GetPackageDeals() {
            this.PackageDeals = await bookingApi.GetAsync(`/api/v1/booking/locations/${this.SelectedLocation}/price-types/list`)
        },
        GetRooms() {
            bookingApi.GetAsync(`/api/v1/booking/locations/${this.SelectedLocation}/rooms/list`)
                .then(res => {
                    this.Rooms = res;
                })
        },
        CreatePagination() {
            if (this.SelectedLocation) {
                this.Pagination["LocationIds"] = [this.SelectedLocation];
            } else {
                delete this.Pagination["LocationIds"]
            }

            if (this.SelectedRoom) {
                this.Pagination["RoomIds"] = [this.SelectedRoom];
            } else {
                delete this.Pagination["RoomIds"]
            }

            if (this.SelectedOrderType) {
                this.Pagination["OrderTypeIds"] = [this.SelectedOrderType];
            } else {
                delete this.Pagination["OrderTypeIds"]
            }

            if (this.SelectedStatus) {
                this.Pagination["OrderRowStatusIds"] = [this.SelectedStatus];
            } else {
                delete this.Pagination["OrderRowStatusIds"]
            }

            if (this.SelectedPackageDeal) {
                this.Pagination["LocationPriceTypeIds"] = [this.SelectedPackageDeal];
            } else {
                delete this.Pagination["LocationPriceTypeIds"]
            }

            if (this.Gte) {
                this.Pagination["Gte"] = moment(this.Gte).format("YYYY-MM-DD");
            } else {
                delete this.Pagination["Gte"]
            }

            if (this.Lte) {
                this.Pagination["Lte"] = moment(this.Lte).format("YYYY-MM-DD");
            } else {
                delete this.Pagination["Lte"]
            }
        },
        Download() {
            this.CreatePagination();
            const url = `/api/v1/booking/orders/0/order-rows/download`;
            bookingApi.GetAsync(url, this.Pagination)
                .then(res => {
                    const link = document.createElement('a');
                    link.download = 'data.csv';
                    const blob = new Blob([res], {type: 'text/csv'});
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                })
        },
        SelectItem(order) {
            this.$router.push(`/reservations/${order.Id}`);
        },
    }
}
</script>

<style scoped>

</style>
