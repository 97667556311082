<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content" v-if="LocationId && LocationId > 0">

            <div class="modal-header">
                <h4 class="modal-title">
                    Create/Edit Order
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body" v-if="!loadingData">
                <section class="border-bottom">
                    <edit-order-rows-component :order-rows="Order.OrderRows" :locationId="LocationId"
                                               @add-order-row="AddOrderRow" @remove-order-row="RemoveOrderRow"/>
                </section>

                <section class="border-bottom pt-3 pb-3">
                    <edit-order-payment-component :location-id="LocationId" :order="Order"/>
                </section>

                <section class="border-bottom pt-3 pb-3">
                    <edit-order-customer-component :create-new="createNew" :customer="Order.Customer"/>
                </section>

                <section class="border-bottom pt-3 pb-3">
                    <edit-order-component :order="Order"/>
                </section>

                <section class="border-bottom pt-3 pb-3" v-if="createNew">
                    <edit-order-form-component :form="Order.Form"/>
                </section>

                <section class="border-bottom pt-3 pb-3">
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-secondary" :class="{'btn-danger' : Order.SendEmail === true}"
                                    @click="Order.SendEmail = true">
                                Send customer email
                            </button>
                        </div>
                        <div class="col">
                            <button class="btn btn-secondary" :class="{'btn-danger' : Order.SendEmail === false}"
                                    @click="Order.SendEmail = false">
                                Do not send email
                            </button>
                        </div>

                    </div>
                </section>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal" :disabled="saving">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save" :disabled="saving">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Filters from "../mixins/Filters";
import BookingService from "../services/booking-service";
import Modal from "../mixins/Modal";
import EditOrderRowsComponent from "@/modals/EditOrderModal/EditOrderRowsComponent";
import moment from "moment";
import EditOrderPaymentComponent from "@/modals/EditOrderModal/EditOrderPaymentComponent";
import EditOrderCustomerComponent from "@/modals/EditOrderModal/EditOrderCustomerComponent";
import EditOrderComponent from "@/modals/EditOrderModal/EditOrderComponent";
import EditOrderFormComponent from "@/modals/EditOrderModal/EditOrderFormComponent";

export default {
    name: "edit-order-modal",
    components: {
        EditOrderFormComponent,
        EditOrderComponent,
        EditOrderCustomerComponent,
        EditOrderPaymentComponent,
        EditOrderRowsComponent
    },
    mixins: [Filters, Modal],
    props: ["data"],
    data() {
        return {
            loadingData: true,
            loadingLists: true,
            saving: false,
            createNew: false,
            RemovedOrderRows: [],
            Order: {
                OrderRows: [],
                SendEmail: null,
                PaymentTypeId: null,
                OrderTypeId: null,
                OrderStatusId: null,
                IsInvoiceRequested: false,
                InvoiceRequest: {
                    InvoiceContact: "",
                    CostLocation: "",
                    CompanyName: "",
                    CompanyEmail: "",
                    Address: "",
                    PostalCode: "",
                    City: "",
                    Country: "NL",
                },
                CustomPriceSet: false,
                Amount: null,
                AdministrationAmount: 0,
                OptionExpireDate: moment().add(2, "d").format("YYYY-MM-DD"),
                Customer: {
                    FirstName: "",
                    LastName: "",
                    Email: "",
                    TelephoneNumber: ""
                },
                Form: {
                    PostCode: "",
                    TypeExperience: 0,
                    Experience: 0,
                    Type: "",
                    OtherGroupType: "",
                    Reference: "",
                    OtherReference: "",
                },
                Voucher: null
            },
            LocationId: null
        }
    },
    async created() {
        this.LocationId = this.data.LocationId;
        if (!this.data.Order) {
            this.createNew = true;
            this.Order.OrderRows.push({
                RoomId: this.data.RoomId,
                SlotId: this.data.Id,
                DeviatingStartTime: "",
                PriceTypeId: null,
                PriceId: null,
                Date: this.data.Date,
            });
            this.loadingData = false;
            return;
        }
        const order = await BookingService.GetAsync(`/api/v1/booking/orders/${this.data.Order.Id}`);
        await this.CreateModel(order);
    },
    methods: {
        async CreateModel(order) {
            order.OrderRows.forEach(row => {
                row.Date = moment(row.BookingDate).format("YYYY-MM-DD")
            });
            order.SendEmail = null;
            this.Order = order;
            this.Order.CustomPriceSet = false
            if(order.Invoice != null) {
                this.Order.IsInvoiceRequested = true;
                this.Order.InvoiceRequest = order.Invoice;
            }
            this.loadingData = false;
        },
        AddOrderRow() {
            this.Order.OrderRows.push({
                RoomId: null,
                SlotId: null,
                DeviatingStartTime: "",
                PriceTypeId: null,
                PriceId: null,
                Date: this.data.Date,
            })
        },
        RemoveOrderRow(idx) {
            if (this.Order.OrderRows[idx].Id) {
                this.RemovedOrderRows.push(this.Order.OrderRows[idx].Id)
            }
            this.Order.OrderRows.splice(idx, 1);
        },


        async Save() {
            this.saving = true;
            if (!this.ValidateOrder) {
                this.saving = false;
                return;
            }

            if (this.createNew) {
                await this.CreateNewOrder();
                this.saving = false;
                return;
            }
            await this.UpdateOrder();
            this.saving = false;
        },
        async UpdateOrder() {

            if (!this.ValidateOrder()) {
                return;
            }

            // 1. Cancel Deleted OrderRows
            if (this.RemovedOrderRows.length > 0) {
                for (const orderRowId of this.RemovedOrderRows) {
                    await BookingService.DeleteAsync(`/api/v1/booking/orders/${this.Order.Id}/order-rows/${orderRowId}`);
                }
            }

            for (const row of this.Order.OrderRows) {
                const orderRowRequest = {
                    DeviatingStartTime: row.DeviatingStartTime,
                    PreferredCombinationTime: row.PreferredCombinationTime,
                    Date: row.Date,
                    PriceId: parseInt(row.PriceId),
                    RoomId: parseInt(row.RoomId),
                    SlotId: parseInt(row.SlotId),
                    CustomerId: parseInt(row.CustomerId),
                    PriceTypeId: parseInt(row.PriceTypeId),
                    OrderRowStatusId: parseInt(row.OrderRowStatusId),
                    SendEmail: false
                };

                if (row.Id) {
                    // 2.1 Update each OrderRow if changed
                    await BookingService.PutAsync(`/api/v1/booking/orders/${this.Order.Id}/order-rows/${row.Id}`, orderRowRequest);
                } else {
                    // 2.2 Create each OrderRow if no ID found yet
                    await BookingService.PostAsync(`/api/v1/booking/orders/${this.Order.Id}/order-rows`, orderRowRequest);
                }
            }

            // 2. Update Order
            const request = {
                LocationId: parseInt(this.LocationId),
                OrderTypeId: parseInt(this.Order.OrderTypeId),
                OrderStatusId: parseInt(this.Order.OrderStatusId),
                PaymentTypeId: parseInt(this.Order.PaymentTypeId),
                InvoiceSent: this.Order.InvoiceSent,
                Description: this.Order.Description,
                Notes: this.Order.Notes,
                AmountPaid: parseFloat(this.Order.AmountPaid),
                AdministrationAmount: parseFloat(this.Order.AdministrationAmount),

                // don't send out email because the cancellation will do that if there are no orderRows
                SendEmail: this.Order.OrderRows.length === 0 ? false : this.Order.SendEmail,
                IsPayedLater: this.Order.IsPayedLater,
                OrderRows: [],

                IsInvoiceRequested: this.Order.IsInvoiceRequested,
                InvoiceRequest: this.Order.InvoiceRequest,
            };

            if (this.Order.CustomPriceSet) {
                request.Amount = parseFloat(this.Order.Amount)
            }

            // only when it's an offer, this field should be set
            if (this.Order.OrderStatusId === 2) {
                request.OptionExpireDate = this.Order.OptionExpireDate
            }

            if (this.Order.VoucherCode) {
                request.VoucherCode = this.Order.VoucherCode;
            }

            await BookingService.PutAsync(`/api/v1/booking/orders/${this.Order.Id}`, request);

            // 3. Cancel order if all orderRows have been removed
            if (this.Order.OrderRows.length === 0) {
                await BookingService.DeleteAsync(`/api/v1/booking/orders/${this.Order.Id}?sendEmail=${this.Order.SendEmail}`);
            }

            this.emitter.emit("reload-overview");
            this.CloseModal();
        },
        async CreateNewOrder() {

            if (!this.ValidateCustomer()) {
                return;
            }

            if (!this.ValidateOrder()) {
                return;
            }

            if (!this.ValidateInvoice()) {
                return;
            }

            let isValid = true;
            this.Order.OrderRows.forEach(row => {
                if (!this.ValidateOrderRow(row)) {
                    isValid = false;
                }
            });
            if (!isValid) {
                return;
            }

            const orderRows = [];

            this.Order.OrderRows.forEach(row => {
                orderRows.push({
                    DeviatingStartTime: row.DeviatingStartTime,
                    PreferredCombinationTime: row.PreferredCombinationTime,
                    Date: row.Date,
                    PriceId: parseInt(row.PriceId),
                    RoomId: parseInt(row.RoomId),
                    SlotId: parseInt(row.SlotId),
                    SendEmail: false
                });
            })

            const request = {
                LocationId: parseInt(this.LocationId),

                OrderTypeId: parseInt(this.Order.OrderTypeId),
                OrderStatusId: parseInt(this.Order.OrderStatusId),
                PaymentTypeId: parseInt(this.Order.PaymentTypeId),
                InvoiceSent: this.Order.InvoiceSent,
                OptionExpireDate: this.Order.OptionExpireDate,
                Description: this.Order.Description,
                Notes: this.Order.Notes,
                AmountPaid: parseFloat(this.Order.AmountPaid),
                Amount: parseFloat(this.Order.Amount),
                AdministrationAmount: parseFloat(this.Order.AdministrationAmount),

                IsInvoiceRequested: this.Order.IsInvoiceRequested,
                InvoiceRequest: this.Order.InvoiceRequest,

                SendEmail: this.Order.SendEmail,
                IsPayedLater: this.Order.IsPayedLater,

                User: this.Order.Customer,
                Form: this.Order.Form,
                OrderRows: orderRows
            };

            if (!request.Amount) {
                request.Amount = 0;
            }

            if (!request.AmountPaid) {
                request.AmountPaid = 0;
            }

            if (this.Order.VoucherCode) {
                request.VoucherCode = this.Order.VoucherCode;
            }

            try {
                await BookingService.PostAsync("/api/v1/booking/orders", request);
                this.emitter.emit("notification", {
                    title: "success",
                    timestamp: "now",
                    body: "Order Created"
                });
                this.emitter.emit("reload-overview");
                this.CloseModal();
            } catch (ex) {
                let errorMessage = ""
                if (ex?.errors?.length) {
                    errorMessage = ex.errors.join(", ")
                }

                this.emitter.emit("notification", {
                    title: "error",
                    timestamp: "now",
                    body: errorMessage
                });
            }
            finally {
                this.saving = false;
            }
        },
        ValidateOrder() {
            if (this.Order.SendEmail === null) {
                this.emitter.emit("notification", {
                    title: "warning",
                    timestamp: "now",
                    body: "Please select if you want to send the customer an email"
                });
                return false;
            }
            return true;
        },
        ValidateInvoice() {
            if (this.Order.IsInvoiceRequested === true) {
                if (
                    !this.Order.InvoiceRequest.City
                    || !this.Order.InvoiceRequest.CompanyName
                    || !this.Order.InvoiceRequest.Address
                    || !this.Order.InvoiceRequest.PostalCode
                    || !this.Order.InvoiceRequest.Country
                ) {
                    this.emitter.emit("notification", {
                        title: "warning",
                        timestamp: "now",
                        body: "All invoice details should be filled in"
                    });
                    return false;
                }
            }
            return true;
        },
        ValidateOrderRow(orderRow) {
            if (orderRow.PriceId === null) {
                this.emitter.emit("notification", {
                    title: "warning",
                    timestamp: "now",
                    body: "Please select a price"
                });
                return false;
            }
            if (orderRow.SlotId === null) {
                this.emitter.emit("notification", {
                    title: "warning",
                    timestamp: "now",
                    body: "no slot selected"
                });
                return false;
            }
            // do not start on same time, or only on :30 or :00
            if (orderRow.PreferredCombinationTime
                && (orderRow.PreferredCombinationTime.indexOf(":00") === -1
                    && orderRow.PreferredCombinationTime.indexOf(":30") === -1
                )
            ) {
                this.emitter.emit("notification", {
                    title: "warning",
                    timestamp: "now",
                    body: "Package deal time should always start on full/half hour (:00 / :30) and can not start at same time as the room"
                });
                return false;

            }

            return true;
        },
        ValidateCustomer() {
            if (!this.Order.Customer.FirstName || !this.Order.Customer.LastName
                || !this.Order.Customer.Email || !this.Order.Customer.TelephoneNumber) {
                this.emitter.emit("notification", {
                    title: "warning",
                    timestamp: "now",
                    body: "Customer information not complete"
                });
                return false;
            }
            if (!this.ValidateEmail(this.Order.Customer.Email)) {
                this.emitter.emit("notification", {
                    title: "warning",
                    timestamp: "now",
                    body: "invalid email address"
                });
                return false;
            }
            return true;
        },
        ValidateEmail(mail) {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail); // eslint-disable-line no-useless-escape
        }
    }
}
</script>

<style scoped lang="scss">
.modal-body {
    overflow: scroll;
    height: 70vh;
}
</style>
