<template>
    <div>
        <h2>Codes</h2>

        <div class="mt-4" v-if="Pagination">
            <auto-table @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import Pagination from "../../components/Shared/Pagination";
    import TableView from "../../mixins/TableView";
    import AutoTable from "@/components/Shared/Table";

    export default {
        name: "voucher-codes",
        components: {Pagination, AutoTable},
        mixins: [TableView],
        data() {
            return {
                Url: `/api/v1/booking/voucher-batches/${this.$route.params.id}/vouchers`,
                Pagination: null,
                Columns: [
                    {
                        Title: "Id",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Id"
                    },
                    {
                        Title: "Code",
                        Sortable: true,
                        Type: "TEXT",
                        Attribute: "Code"
                    },
                    {
                        Title: "UsedDate",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "UsedDate"
                    },
                    {
                        Title: "VoucherStatus",
                        Sortable: true,
                        SortValue: "VoucherStatusId",
                        Type: "COMBINED",
                        Attribute: "Name",
                        Class: "VoucherStatus"
                    },
                ]
            }
        },
        async created() {
            this.GetPage();
        }
    }
</script>

<style scoped>

</style>
