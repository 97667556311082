<template>
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    Customer
                </h4>
                <button type="button" class="btn btn-primary material-icons" @click="CloseModal">
                    close
                </button>
            </div>

            <div class="modal-body">
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="FirstName">
                            First name
                        </label>
                    </div>
                    <div class="col">
                        <input id="FirstName" class="form-control" type="text" v-model="customer.FirstName">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="last-name">
                            Last name
                        </label>
                    </div>
                    <div class="col">
                        <input id="last-name" class="form-control" type="text" v-model="customer.LastName">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="TelephoneNumber">
                            Telephone
                        </label>
                    </div>
                    <div class="col">
                        <input id="TelephoneNumber" class="form-control" type="text" v-model="customer.TelephoneNumber">
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-4 text-left">
                        <label for="Email">
                            Email
                        </label>
                    </div>
                    <div class="col">
                        <input id="Email" class="form-control" type="email" v-model="customer.Email">
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="CloseModal">
                    Cancel
                </button>

                <button type="button" class="btn btn-danger" @click="Save">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Filters from "../mixins/Filters";
    import Modal from "../mixins/Modal";
    import BookingService from "@/services/booking-service";

    export default {
        name: "edit-customer-modal",
        components: {},
        mixins: [Filters, Modal],
        props: ["data"],
        data() {
            return {
                customer: null
            }
        },
        created() {
            this.customer = JSON.parse(JSON.stringify(this.data))
        },
        methods: {
            async Save() {
                await BookingService.PutAsync(`/api/v1/booking/customers/${this.customer.Id}`, this.customer);
                this.emitter.emit("reload-customer");
                this.CloseModal();
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
