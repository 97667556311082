<template>
    <div class="home">

        <div class="home--content" v-if="!Loading">

            <div class="row pl-4">
                <div class="col-3">
                    Van
                    <input class="form-control" type="date" v-model="Gte">
                </div>

                <div class="col-3">
                    Tot
                    <input class="form-control" type="date" v-model="Lte">
                </div>

            </div>

            <div class="row p-4">
                <div class="col-2" v-if="Locations">
                    <select class="form-control" v-model="SelectedLocation">
                        <option :value="null">
                            All Locations
                        </option>
                        <option v-for="loc in Locations" :key="loc.Id" :value="loc.Id">
                            {{loc.DisplayName}}
                        </option>
                    </select>
                </div>

                <div class="col-2">
                    <select class="form-control" v-model="IsBookedStatus">
                        <option :value="null">
                            Alles
                        </option>
                        <option :value="true">
                            Aanvaard
                        </option>
                        <option :value="false">
                            Niet aanvaard
                        </option>
                    </select>
                </div>

                <div class="col-2">
                    <select class="form-control" v-model="IsActionRequiredStatus">
                        <option :value="null">
                            Alles
                        </option>
                        <option :value="true">
                            Actie vereist
                        </option>
                        <option :value="false">
                            Geen active vereist
                        </option>
                    </select>
                </div>

                <div class="offset-1 col-2">
                    <button class="btn btn-primary" @click="Search">
                        Zoeken
                    </button>
                </div>

                <div class="col-2">
                    <button class="btn btn-primary" @click="Download">
                        Downloaden
                    </button>
                </div>
            </div>

            <auto-table :pagination="Pagination" :columns="Columns" :loading="Loading"/>
            <pagination :pagination="Pagination" @get-page="Paginate"/>

        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import bookingApi from '../services/booking-service';
    import TableView from "@/mixins/TableView";
    import AutoTable from "@/components/Shared/Table";
    import Pagination from "@/components/Shared/Pagination";

    export default {
        components: {AutoTable, Pagination},
        data() {
            return {
                Pagination: {},
                Url: "/api/v1/booking/package-deals",
                Loading: true,
                SelectedLocation: null,
                Gte: moment().format("YYYY-MM-DD"),
                Lte: moment().add(7, 'd').format("YYYY-MM-DD"),
                Locations: [],
                IsBookedStatus: true,
                IsActionRequiredStatus: null,
                Columns: [
                    {
                        Title: "Location",
                        Type: "FUNCTION",
                        Attribute: "Order",
                        Func: (order) => {
                            if (order.Location) {
                                return order.Location.DisplayName
                            }
                            return order.LocationId;
                        }
                    },
                    {
                        Title: "Type",
                        Type: "COMBINED",
                        Class: "PriceType",
                        Attribute: "Name",
                    },
                    {
                        Title: "Name",
                        Type: "FUNCTION",
                        Attribute: "Customer",
                        Func: (c) => {
                            return `${c.FirstName} ${c.LastName} (${c.Email})`;
                        }
                    },
                    {
                        Title: "Participants",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "Price",
                        Attribute: "People"
                    },
                    {
                        Title: "Date",
                        Sortable: true,
                        Type: "DATE",
                        Attribute: "BookingDate"
                    },
                    {
                        Title: "Escape Room",
                        Type: "FUNCTION",
                        Attribute: "Slot",
                        Func: (slot) => {
                            return `${slot.StartHour} ${slot.StartMinute}`;
                        }
                    },
                    {
                        Title: "CombinationTime",
                        Type: "TEXT",
                        Attribute: "PreferredCombinationTime",
                    },
                    {
                        Title: "Room",

                        Type: "COMBINED",
                        Class: "Room",
                        Attribute: "Name"
                    },
                    {
                        Title: "Price",
                        Type: "COMBINED_VALUTA",
                        Class: "Price",
                        Attribute: "Amount"
                    },
                    {
                        Title: "Status",
                        Type: "COMBINED",
                        Class: "OrderRowStatus",
                        Attribute: "Name",
                    },
                    {
                        Title: "Order Status",
                        Type: "COMBINED",
                        Class: "OrderStatus",
                        Attribute: "Name",
                    },
                ],
                Modal: {},
            }
        },
        mixins: [TableView],
        created() {
            bookingApi.GetAsync("/api/v1/booking/locations/list")
                .then(res => {
                    this.Locations = res;
                    this.Search();
                });

        },
        methods: {
            Search() {
                this.CreatePagination();
                this.GetPage();
            },

            CreatePagination() {
                if (this.SelectedLocation) {
                    this.Pagination["LocationIds"] = [this.SelectedLocation];
                } else {
                    delete this.Pagination["LocationIds"]
                }

                if (this.IsBookedStatus != null) {
                    this.Pagination["IsBookedStatus"] = [this.IsBookedStatus];
                } else {
                    delete this.Pagination["IsBookedStatus"]
                }

                if (this.IsActionRequiredStatus != null) {
                    this.Pagination["IsActionRequiredStatus"] = [this.IsActionRequiredStatus];
                } else {
                    delete this.Pagination["IsActionRequiredStatus"]
                }

                if (this.SelectedRoom) {
                    this.Pagination["RoomIds"] = [this.SelectedRoom];
                } else {
                    delete this.Pagination["RoomIds"]
                }

                if (this.Gte) {
                    this.Pagination["Gte"] = moment(this.Gte).format("YYYY-MM-DD");
                } else {
                    delete this.Pagination["Gte"]
                }

                if (this.Lte) {
                    this.Pagination["Lte"] = moment(this.Lte).format("YYYY-MM-DD");
                } else {
                    delete this.Pagination["Lte"]
                }
            },

            Download() {
                this.CreatePagination();
                bookingApi.GetAsync(`/api/v1/booking/package-deals/download`, this.Pagination)
                    .then(res => {
                        const link = document.createElement('a');
                        link.download = 'data.csv';
                        const blob = new Blob([res], {type: 'text/csv'});
                        link.href = window.URL.createObjectURL(blob);
                        link.click();
                    })
            },

            SelectItem(packageDeal) {
                window.location.href = `/orders/${packageDeal.OrderId}`
            },
        }
    }
</script>
