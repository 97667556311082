export default {
    data() {
        return {}
    },
    methods: {
        CloseModal() {
            // @ts-ignore
            this.emitter.emit("close-modal")
        }
    }
}
