<template>
    <div class="rooms">
        <h2>
            Room Combinations
        </h2>

        <div class="row mt-4">

            <div class="offset-10 col-2">
                <button class="btn btn-primary material-icons" @click="AddCombination">
                    add
                </button>
            </div>
        </div>

        <div class="mt-4" v-if="!Loading">
            <p class="text-center">
                Kamercombinaties die gelijktijdig bediend kunnen worden.<br>
                Let op; alle kamers van de vestiging in een combinatie laten voorkomen of geen van allen.<br>
                Gebruik combinaties altijd ook spiegelbeeldig. Dus “’kamer 1 en kamer 2’’ dan ook toevoegen ‘’kamer 2 en kamer 1’’
            </p>
        </div>
        <div class="mt-4" v-if="!Loading">
            <auto-table @table-click="SelectItem" @order-by="OrderBy" :columns="Columns" :pagination="Pagination" :loading="Loading"/>
            <pagination @get-page="Paginate" :pagination="Pagination"/>
        </div>
    </div>
</template>

<script>
    import AutoTable from "@/components/Shared/Table";
    import Pagination from "@/components/Shared/Pagination";
    import TableView from "@/mixins/TableView";

    export default {
        name: "rooms",
        mixins: [TableView],
        components: {Pagination, AutoTable},
        data() {
            return {
                Url: `/api/v1/booking/locations/${this.$route.params.id}/rooms/combinations`,
                Pagination: null,
                Columns: [
                    {
                        Title: "Room",
                        Sortable: true,
                        SortValue: "RoomId",
                        Type: "COMBINED",
                        Class: "Room",
                        Attribute: "Name"
                    },
                    {
                        Title: "Combined Room",
                        Sortable: true,
                        SortValue: "CombinedRoomId",
                        Type: "COMBINED",
                        Class: "CombinedRoom",
                        Attribute: "Name"
                    },
                    {
                        Title: "Combination Type",
                        Sortable: false,
                        Type: "COMBINED",
                        Class: "RoomCombinationType",
                        Attribute: "Name"
                    }
                ]
            }
        },
        created() {
            this.GetPage();
            this.emitter.on("reload-location-room-combinations", this.GetPage);
        },
        methods: {
            SelectItem(item) {
                item.LocationId = this.$route.params.id;
                this.emitter.emit("open-modal", {type: "EditRoomCombinationModal", data: item})
            },
            AddCombination() {
                const item = {
                    LocationId: this.$route.params.id
                };
                this.emitter.emit("open-modal", {type: "CreateRoomCombinationModal", data: item})
            }
        }
    }
</script>

<style scoped>

</style>
